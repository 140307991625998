import { apiClientFastify } from '@/services/axios'

/**
 * Fetch order list
 *
 * @typedef {"draft" | "new" | "waiting_payment" | "confirmed" | "on_process" | "ready_to_pickup" | "sent" | "completed" | "on_cancel_req" | "canceled" | "on_retour_req" | "retoured" | "delivered" | "payment_expired" | "payment_unfinish" | "payment_declined"} OrderState
 *
 * @typedef {{
    from?: string,
    to?: string,
    q?: string,
    order_state?: OrderState | OrderState[],
    page?: number | string,
    limit?: number | string,
    order?: string,
    by?: string
  }} OrderQueryInterface
 *
 * @param {import('vuex').ActionContext} context
 * @param {OrderQueryInterface} payload
 */
export const fetchOrders = async ({ commit, dispatch, state }, payload) => {
  try {
    const { data: response } = await apiClientFastify.get('/orders/private', {
      params: {
        ...payload,
        order_state: Array.isArray(payload.order_state) ? payload.order_state.join(',') : payload.order_state,
      },
    })

    return response
  } catch (err) {
    Promise.reject(err)
  }
}

/**
 * Fetch order
 *
 * @param {import('vuex').ActionContext} context
 * @param {String} orderNumber
 */
export const fetchOrder = async(_, orderNumber) => {
  try {
    const { data: response } = await apiClientFastify.get(`/orders/private/${orderNumber}`)

    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err)
  }
}
