import Cookies from 'js-cookie'
import * as MutationTypes from './mutation-types'

const mutations = {
  [MutationTypes.SET_LOADING]: (state, payload) => {
    state.loading = payload
  },
  [MutationTypes.SET_COMMISSION]: (state, payload) => {
    state.commission = payload
  },
  [MutationTypes.SET_LAST_SOLD_ITEMS]: (state, payload) => {
    state.lastSoldItems = payload
  },
  [MutationTypes.SET_ORDER_SUMMARY]: (state, payload) => {
    state.orderSummary = payload
  },
  [MutationTypes.SET_GRAPH_SUMMARY]: (state, payload) => {
    state.graphSummary = payload
  },
  SET_STATE: (state, payload) => {
    state.loading = false
    state.commission = {}
    state.lastSoldItems = []
    state.orderSummary = {}
    state.graphSummary = {}
    state.bestSeller = []
  },
  [MutationTypes.SET_BEST_SELLER_ITEMS]: (state, payload) => {
    state.bestSeller = payload
  },
  [MutationTypes.SET_EWALLET]: (state, payload) => {
    Cookies.set('ewallet', payload)
    state.ewallet = payload
  },
}

export default mutations
