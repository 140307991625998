import * as MutationTypes from './mutation-types'
// import Vue from 'vue'

export default {
  [MutationTypes.SET_PROFILE]: (state, payload) => {
    state.profile = payload
  },
  [MutationTypes.SET_INITIALVALUE_CITY]: (state, payload) => {
    state.initialValueCity = payload
  },
  [MutationTypes.SET_INITIALVALUE_AREA]: (state, payload) => {
    state.initialValueArea = payload
  },
  [MutationTypes.SET_LOADING]: (state, payload) => {
    state.loading = payload
  },
  [MutationTypes.SET_USER_BANK]: (state, payload) => {
    const defaultBank = payload.filter(item => item.is_default === 1)
    const notDefaultBank = payload.filter(item => item.is_default === 0)
    state.bank = [...defaultBank, ...notDefaultBank]
  },
  [MutationTypes.SET_DEFAULT]: (state, payload) => {
    state.bank = []
  },
  [MutationTypes.SET_COMMISSION_LIST]: (state, payload) => {
    state.listCommission = payload
  },
  [MutationTypes.SET_EWALLET_HISTORY]: (state, payload) => {
    state.ewalletHistory = payload
  },
  [MutationTypes.SET_PAGINATION]: (state, payload) => {
    state.pagination = payload
  },
  [MutationTypes.SET_TOTAL_COMMISSION]: (state, payload) => {
    state.totalCommission = payload
  },
  [MutationTypes.SET_LAST_WITHDRAW]: (state, payload) => {
    state.lastWithdraw = payload
  },
  [MutationTypes.SET_STATE_DETAIL_USER]: (state, payload) => {
    state.detail_user = payload
  },
  [MutationTypes.SET_STATE_DETAIL_CUSTOMER]: (state, payload) => {
    state.detailCustomer = payload
  },
  [MutationTypes.SET_LIST_SHIPPING_ADDRESS]: (state, payload) => {
    state.listShippingAddress = payload
  },
}
