export default {
  loading: false,
  profile: null,
  initialValueCity: null,
  initialValueArea: null,
  bank: [],
  listCommission: [],
  ewalletHistory: [],
  pagination: {},
  totalCommission: null,
  lastWithdraw: null,
  detail_user: null,
  listShippingAddress: [],
  detailCustomer: null,
}
