import axios from 'axios'
import store from '@/store'
// import config from '@/config'

const initApiClientFastify = axios.create({
  baseURL: process.env.VUE_APP_API_URL_FASTIFY,
  headers: { 'Content-Type': 'application/json' },
})

const initApiMeta = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { 'Content-Type': 'application/json' },
})

const initApiClientDistributorship = axios.create({
  baseURL: process.env.VUE_APP_API_URL_DISTRIBUTORSHIP,
  headers: { 'Content-Type': 'application/json' },
})

const initApiGuestDistributorship = axios.create({
  baseURL: process.env.VUE_APP_API_URL_DISTRIBUTORSHIP,
  headers: { 'Content-Type': 'application/json' },
})

initApiClientFastify.interceptors.request.use(request => {
  if (store.state.user.accessToken) {
    request.headers.Authorization = `Bearer ${store.state.user.accessToken}`
  }
  request.headers['Business-Id'] = store.state.app.business_id
  request.headers['Buyer-Id'] = store.state.user.user_data.buyer_id

  return request
})

initApiMeta.interceptors.request.use(request => {
  if (store.state.user.accessToken) {
    request.headers.Authorization = `Bearer ${store.state.user.accessToken}`
  }
  request.headers['Business-Id'] = store.state.app.business_id
  request.headers['Buyer-Id'] = store.state.user.user_data.buyer_id
  return request
})

initApiMeta.interceptors.response.use(
  (response) => {
    return response
  }, (error) => {
    if (error.response.status === 401) {
      store.commit('user/SET_STATE', {
        accessToken: null,
      })
      store.commit('user/LOGOUT')
    } else {
      return Promise.reject(error)
    }
  },
)

initApiClientDistributorship.interceptors.request.use(request => {
  if (store.state.user.accessToken) {
    request.headers.Authorization = `Bearer ${store.state.user.accessToken}`
  }
  request.headers['Business-Id'] = store.state.app.business_id
  request.headers['Buyer-Id'] = store.state.user.user_data.buyer_id
  return request
})

initApiClientDistributorship.interceptors.response.use(
  (response) => {
    return response
  }, (error) => {
    if (error.response.status === 401) {
      store.commit('user/SET_STATE', {
        accessToken: null,
      })
      store.commit('user/LOGOUT')
      return Promise.reject(error)
    } else {
      return Promise.reject(error)
    }
  },
)

initApiGuestDistributorship.interceptors.request.use(request => {
  if (store.state.user.accessToken) {
    request.headers.Authorization = `Bearer ${store.state.user.accessToken}`
  }
  request.headers['Business-Id'] = store.state.app.business_id
  return request
})
initApiGuestDistributorship.interceptors.response.use(
  (response) => {
    return response
  }, (error) => {
    if (error.response.status === 401) {
      store.commit('user/SET_STATE', {
        accessToken: null,
      })
      store.commit('user/LOGOUT')
    } else {
      return Promise.reject(error)
    }
  },
)
export const apiClientFastify = initApiClientFastify
export const apiMeta = initApiMeta
export const apiClientDistributorship = initApiClientDistributorship
export const apiGuestDistributorship = initApiGuestDistributorship
