const formatedCourierList = (items = []) => {
  const courier = []
  items.forEach((item, index) => {
    if (item.courier_name === 'SAP' || item.courier_name === 'OWN') {
      const findServices = item.services.find(value => value.code === 'UDRREG') // * hardcode to reguler
      const prepData = {
        courier_code: item.courier_code,
        courier_key: item.courier_key,
        courier_name: item.courier_name,
        courier_services_code: findServices ? findServices.code : item.services[0].code,
        courier_services_name: findServices ? findServices.name : item.services[0].name,
      }
      courier.push(prepData)
    }
  })
  return courier
}

export const types = {
  SET_LOADING: 'SET_LOADING',
  SET_PURCHASE_ORDER: 'SET_PURCHASE_ORDER',
  SET_PAGINATION: 'SET_PAGINATION',
  SET_PRODUCT_LIST_PURCHASE: 'SET_PRODUCT_LIST_PURCHASE',
  SET_COURIER: 'SET_COURIER',
  SET_PAYMENT_METHOD: 'SET_PAYMENT_METHOD',
  SET_LAST_BILLING_ADDRESS: 'SET_LAST_BILLING_ADDRESS',
  SET_ALL_BILLING_ADDRESS: 'SET_ALL_BILLING_ADDRESS',
  SET_CITY_LIST_BILLING: 'SET_CITY_LIST_BILLING',
  SET_AREA_LIST_BILLING: 'SET_AREA_LIST_BILLING',
  SET_LAST_SHIPPING_ADDRESS: 'SET_LAST_SHIPPING_ADDRESS',
  SET_ALL_SHIPPING_ADDRESS: 'SET_ALL_SHIPPING_ADDRESS',
  SET_CITY_LIST_SHIPPING: 'SET_CITY_LIST_SHIPPING',
  SET_AREA_LIST_SHIPPING: 'SET_AREA_LIST_SHIPPING',
  SET_CREATE_SUCCESS: 'SET_CREATE_SUCCESS',
  SET_SUM_TOTAL_PURCHASE: 'SET_SUM_TOTAL_PURCHASE',
  SET_TOTAL_CART: 'SET_TOTAL_CART',
  SET_TOTAL_CART_LOYALTY: 'SET_TOTAL_CART_LOYALTY',
}

export default {
  [types.SET_LOADING]: (state, payload) => {
    state.loading = payload
  },
  [types.SET_PURCHASE_ORDER]: (state, payload) => {
    state.purchaseOrder = payload
  },
  [types.SET_PRODUCT_LIST_PURCHASE]: (state, payload) => {
    state.productListPurhcase = payload
  },
  [types.SET_PAGINATION]: (state, payload) => {
    Object.assign(state.pagination, payload)
  },
  [types.SET_COURIER]: (state, payload) => {
    state.courier = formatedCourierList(payload)
  },
  [types.SET_PAYMENT_METHOD]: (state, payload) => {
    state.paymentMethod = payload
  },
  [types.SET_LAST_BILLING_ADDRESS]: (state, payload) => {
    state.lastBillingAddress = payload
  },
  [types.SET_ALL_BILLING_ADDRESS]: (state, payload) => {
    state.allBillingAddress = payload
  },
  [types.SET_CITY_LIST_BILLING]: (state, payload) => {
    state.cityListBilling = payload
  },
  [types.SET_AREA_LIST_BILLING]: (state, payload) => {
    state.areaListBilling = payload
  },
  [types.SET_LAST_SHIPPING_ADDRESS]: (state, payload) => {
    state.lastShippingAddress = payload
  },
  [types.SET_ALL_SHIPPING_ADDRESS]: (state, payload) => {
    state.allShippingAddress = payload
  },
  [types.SET_CITY_LIST_SHIPPING]: (state, payload) => {
    state.cityListShipping = payload
  },
  [types.SET_AREA_LIST_SHIPPING]: (state, payload) => {
    state.areaListShipping = payload
  },
  [types.SET_CREATE_SUCCESS]: (state, payload) => {
    state.createSuccess = payload
  },
  [types.SET_SUM_TOTAL_PURCHASE]: (state, payload) => {
    state.sumTotalPurchase = payload
  },
  [types.SET_TOTAL_CART]: (state, payload) => {
    state.totalCart = payload
  },
  [types.SET_TOTAL_CART_LOYALTY]: (state, payload) => {
    state.totalCartLoyalty = payload
  },
}
