export const getOptions = (catalogues, param) => {
  const result = []

  catalogues.forEach((catalogue, index) => {
    if (Array.isArray(catalogue.option)) {
      catalogue.option.forEach((opt, index) => {
        if (Object.keys(opt).find(item => item === param)) {
          result.push(opt[param])
        }
      })
    }
  })

  return result

  // return catalogues.reduce((acc, item) => {
  //   const key = item.option[index]
  //   // eslint-disable-next-line
  //   if (!!key) acc[key] = key

  //   return acc
  // }, {})
}
