import * as actions from './actions'
import mutations from './mutations'

import Cookies from 'js-cookie'

const getCartId = Cookies.get('cartId') ? JSON.parse(Cookies.get('cartId')) : undefined
const getCartVersion = Cookies.get('cartVersion') ? JSON.parse(Cookies.get('cartVersion')) : undefined

const state = {
  loading: false,
  purchaseOrder: [],
  pagination: {
    page: 1,
    limit: 20,
    totalRows: 0,
  },
  productListPurhcase: [],
  courier: [],
  paymentMethod: [],
  lastBillingAddress: null,
  allBillingAddress: [],
  cityListBilling: [],
  areaListBilling: [],
  lastShippingAddress: null,
  allShippingAddress: [],
  cityListShipping: [],
  areaListShipping: [],
  createSuccess: {},
  sumTotalPurchase: null,
  cartId: getCartId,
  cartVersion: getCartVersion,
  totalCart: 0,
  totalCartLoyalty: 0,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
