export const types = {
  SET_LOYALTY_STORE: 'SET_LOYALTY_STORE',
}

const mutations = {
  [types.SET_LOYALTY_STORE]: (state, payload) => {
    Object.assign(state, {
      ...payload,
    })
  },
}

export default mutations
