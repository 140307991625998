import 'ant-design-vue/lib/style/index.less' // antd core styles
import './components/kit/vendors/antd/themes/default.less' // default theme antd components
import './global.scss' // app & third-party component styles
import './resources/fonts/font-icomoon/style.css'
import './resources/fonts/font-awesome/css/font-awesome.min.css'
import './resources/fonts/font-linearicons/style.css'
import './resources/fonts/font-feathericons/dist/feather.css'

// Slider slick carousel
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import Vue from 'vue'
import VuePageTitle from 'vue-page-title'
import NProgress from 'vue-nprogress'
import BootstrapVue from 'bootstrap-vue'
import VueCurrencyFilter from 'vue-currency-filter'
import VueClipboard from 'vue-clipboard2'
import OtpInput from '@bachdgvn/vue-otp-input'

import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './localization'
import Antd from './antd'

import VueMeta from 'vue-meta'

import MomentPlugin from '@/plugins/moment'

import * as VueGoogleMaps from 'vue2-google-maps'
import vueDebounce from 'vue-debounce'

import './registerServiceWorker'
import IdleVue from 'idle-vue'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCxamaGl5Aqnu4aHxCTbNZZQtCJywD5Gqk',
    libraries: 'places',
  },
  installComponents: true,
})
Vue.use(vueDebounce)

Vue.use(VueClipboard)
Vue.use(VueCurrencyFilter, [
  {
    symbol: 'Rp',
    thousandsSeparator: '.',
    fractionCount: 0,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true,
  },
  {
    // symbol: 'Rp',
    name: 'currency_2',
    thousandsSeparator: '.',
    fractionCount: 0,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true,
  },
])
Vue.use(MomentPlugin)
Vue.use(Antd)
Vue.use(BootstrapVue)
Vue.use(VueMeta)
Vue.component('v-otp-input', OtpInput)

Vue.use(NProgress)
Vue.use(VuePageTitle, {
  router,
})

Vue.use(IdleVue, {
  store,
  idleTime: 300000999,
  // idleTime: 5000,
  startAtIdle: false,
})

Vue.config.productionTip = false
const nprogress = new NProgress({ parent: 'body' })

new Vue({
  router,
  store,
  nprogress,
  i18n,
  render: h => h(App),
}).$mount('#app')
