import { apiClientFastify } from '@/services/axios'
import * as MutationTypes from './mutation-types'

export const GETBANKLIST = async ({ state, commit, dispatch }) => {
  try {
    const { data: response } = await apiClientFastify.get('/data/bank')
    const { list: bankList } = response
    commit(MutationTypes.SET_BANK_LIST, bankList)
  } catch (error) {
    console.log(error)
  }
}

export const GETREGIONCOUNTRY = async ({ commit, dispatch }, payload) => {
  try {
    const { data: response } = await apiClientFastify.get(`/data/region/${payload}`)
    const { list: regionCountry } = response
    commit(MutationTypes.SET_REGION_COUNTRY, regionCountry[0])
    commit(MutationTypes.SET_REGION_LIST, regionCountry)
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const GETCITYLIST = async ({ commit, dispatch, getters }, payload) => {
  try {
    const { value, type, location } = payload
    const { data } = await apiClientFastify.get(`/data/areaparent?parentId=${value}`)
    if (type === 'city') commit(MutationTypes.SET_CITY, data.list)
    else if (type === 'area') commit(MutationTypes.SET_AREA, data.list)

    if (location === 'billing' && type === 'city') {
      commit('purchase/SET_CITY_LIST_BILLING', data.list, { root: true })
    } else if (location === 'billing' && type === 'area') {
      commit('purchase/SET_AREA_LIST_BILLING', data.list, { root: true })
    } else if (location === 'shipping' && type === 'city') {
      commit('purchase/SET_CITY_LIST_SHIPPING', data.list, { root: true })
    } else if (location === 'shipping' && type === 'area') {
      commit('purchase/SET_AREA_LIST_SHIPPING', data.list, { root: true })
    }

    return data.list
  } catch (e) {
    console.log(e)
  }
}

export const GETAREALLIST = async ({ commit, dispatch, rootState }, { parentId }) => {
  try {
    if (parentId) {
      const { data } = await apiClientFastify.get(`/data/areaparent?parentId=${parentId}`)
      return Promise.resolve(data)
    }
  } catch (error) {
    return Promise.reject(error)
  }
}
