import * as MutationTypes from './mutation-types'

export default {
  [MutationTypes.SET_LOADING]: (state, payload) => {
    state.loading = payload
  },
  [MutationTypes.SET_PROFILE]: (state, payload) => {
    state.storeProfile = payload
  },
  [MutationTypes.SET_BANNER_LIST]: (state, payload) => {
    state.bannerList = payload
  },
}
