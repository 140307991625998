import * as MutationTypes from './mutation-types'

export default {
  [MutationTypes.SET_BANK_LIST]: (state, payload) => {
    state.bankList = payload
  },
  [MutationTypes.SET_REGION_COUNTRY]: (state, payload) => {
    state.regionCountry = payload
  },
  [MutationTypes.SET_CITY]: (state, payload) => {
    state.cityList = payload
  },
  [MutationTypes.SET_AREA]: (state, payload) => {
    state.areaList = payload
  },
  [MutationTypes.SET_REGION_LIST]: (state, payload) => {
    state.regionList = payload
  },
}
