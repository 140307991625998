import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    loading: false,
    data: [],
  },
  getters: {
    data: (state) => {
      return state.data.reduce(
        (value, item) => {
          value[item.meta_name] = item.meta_value

          return value
        },
        {},
      )
    },
    styleVariable: (_, getters) => {
      return JSON.parse(getters.data.styles_variable || '{}') || {}
    },
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions,
}
