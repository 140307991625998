import { apiClientDistributorship } from '@/services/axios'
import { types } from './mutations'

export const GETBILLINGSUMMARY = async (
  { commit, dispatch, rootState },
  { channel_id, seller_id, customer_id },
) => {
  // console.log('AllBillings', channel_id, seller_id, customer_id)
  try {
    commit(types.SET_BILLING_STORE, {
      loading: true,
    })
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/payment/query/bills/summary/${channel_id}/${seller_id}`,
      headers: {
        'Customer-ID': customer_id,
        'Seller-ID': seller_id,
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_BILLING_STORE, {
      loading: false,
    })
  }
}

export const GETBILLSCONTENTS = async (
  { commit, dispatch, rootState },
  { channel_id, customer_id },
) => {
  try {
    commit(types.SET_BILLING_STORE, {
      loading: true,
    })
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/payment/query/bills/${channel_id}/all`,
      headers: {
        'Customer-ID': customer_id,
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_BILLING_STORE, {
      loading: false,
    })
  }
}

export const GETUNPAIDCREDIT = async (
  { commit, dispatch, rootState },
  { channel_id, seller_id, params },
) => {
  try {
    commit(types.SET_BILLING_STORE, {
      loading: true,
    })
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/payment/query/creditlimit/transaction/${channel_id}/${seller_id}/unpaid`,
      params: {
        ...params,
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_BILLING_STORE, {
      loading: false,
    })
  }
}

export const REQUESTPAYMENTSETTLEMENT = async (
  { commit, dispatch, rootState },
  { payload, seller_id },
) => {
  try {
    commit(types.SET_BILLING_STORE, {
      loading: true,
    })
    const { data: response } = await apiClientDistributorship({
      method: 'post',
      url: '/payment/cmd/request-payment-settlement',
      data: {
        ...payload,
      },
      headers: {
        'Seller-Id': seller_id,
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_BILLING_STORE, {
      loading: false,
    })
  }
}

export const GETDETAILSETTLEMENT = async (
  { commit, dispatch, rootState },
  { channel_id, payment_id },
) => {
  try {
    commit(types.SET_BILLING_STORE, {
      loading: true,
    })
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/payment_provider/query/payment/${channel_id}/${payment_id}`,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_BILLING_STORE, {
      loading: false,
    })
  }
}

export const GETLISTSETTLEMENTPAYMENTHISTORY = async (
  { commit, dispatch, rootState },
  { channel_id, status = 'all', customer_id },
) => {
  try {
    commit(types.SET_BILLING_STORE, {
      loading: true,
    })
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/payment/query/creditlimit/payment/${channel_id}/${status}`,
      headers: {
        'Customer-ID': customer_id,
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_BILLING_STORE, {
      loading: false,
    })
  }
}

export const GETDETAILSETTLEMENTPAYMENTHISTORY = async (
  { commit, dispatch, rootState },
  { channel_id, payment_id },
) => {
  try {
    commit(types.SET_BILLING_STORE, {
      loading: true,
    })
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/payment/query/detail/payment/${channel_id}/${payment_id}`,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_BILLING_STORE, {
      loading: false,
    })
  }
}

export const GETDETAILSETTLEMENTPAYMENTHISTORYNOAUTH = async (
  { commit, dispatch, rootState },
  { channel_id, payment_id, token },
) => {
  try {
    commit(types.SET_BILLING_STORE, {
      loading: true,
    })
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/payment/query/detail/payment/${channel_id}/${payment_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_BILLING_STORE, {
      loading: false,
    })
  }
}
