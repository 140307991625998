<template>
  <a-layout>
    <a-layout-content>
      <BottomNavigation />
      <div :class="{
        [$style.container]: true,
        cui__layout__squaredBorders: settings.isSquaredBorders,
        cui__layout__cardsShadow: settings.isCardShadow,
        cui__layout__borderless: settings.isBorderless,
        [$style.white]: settings.authPagesColor === 'white',
        [$style.gray]: settings.authPagesColor === 'gray',
      }"
        :style="{ backgroundImage: settings.authPagesColor === 'image' ? `url(resources/images/content/photos/7.jpg)` : 'none' }">
        <div :class="$style.containerInner">
          <div class="d-flex justify-content-center mt-5" @click.prevent="handleClickLogo">
            <img :src="urlImage" :class="$style.logo" />
          </div>
          <transition :name="settings.routerAnimation" mode="out-in">
            <router-view class="d-flex justify-content-center" />
          </transition>
        </div>
        <!-- <img style="width: 100%; margin-top:-50px" src="https://ik.imagekit.io/fepca/background-bottom_JvZy3l3ro.png?ik-sdk-version=javascript-1.4.3&updatedAt=1650334510049" alt="Footer Image" /> -->
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { mapState } from 'vuex'
import BottomNavigation from '@/components/cleanui/layout/BottomNavigation/BottomNavigation.vue'

export default {
  name: 'AuthFooterImageLayout',
  components: { BottomNavigation },
  computed: {
    ...mapState(['settings']),

    isVendorKino() {
      return this.$store.state.app.vendor_name && this.$store.state.app.vendor_name.toLowerCase() === 'kino'
    },

    urlImage() {
      return this.isVendorKino ? 'https://ik.imagekit.io/powerbiz/img/kino/master_copy.png?updatedAt=1640933165040' : this.$store.state.app.logo
    },
  },
  methods: {
    handleClickLogo() {
      const url = '#'
      window.open(url, '_self')
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
