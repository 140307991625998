export default {
  loading: false,
  products: [],
  totalProductOutStore: 0,
  totalProducts: 0,
  productsInStore: [],
  totalMyProducts: 0,
  productDetail: {},
  colorOptions: null,
  sizeOptions: null,
  flavorOptions: null,
  defaultOptions: null,
  categories: [],
}
