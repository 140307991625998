import { SET_NAME_CLIENT } from './mutation-types'

const actions = {
  async getClientName({ commit, rootState }) {
    if (
      window.location.origin === 'http://localhost:3075' ||
      window.location.origin === 'http://nayz.sandbox.web-c.powerbiz.asia'
    ) {
      commit(SET_NAME_CLIENT, 'nayz')
    }
  },
}

export default actions
