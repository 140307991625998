var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout',[_c('a-layout-content',[_c('BottomNavigation'),_c('div',{class:{
      [_vm.$style.container]: true,
      cui__layout__squaredBorders: _vm.settings.isSquaredBorders,
      cui__layout__cardsShadow: _vm.settings.isCardShadow,
      cui__layout__borderless: _vm.settings.isBorderless,
      [_vm.$style.white]: _vm.settings.authPagesColor === 'white',
      [_vm.$style.gray]: _vm.settings.authPagesColor === 'gray',
    },style:({ backgroundImage: _vm.settings.authPagesColor === 'image' ? `url(resources/images/content/photos/7.jpg)` : 'none' })},[_c('div',{class:_vm.$style.containerInner},[_c('div',{staticClass:"d-flex justify-content-center mt-5",on:{"click":function($event){$event.preventDefault();return _vm.handleClickLogo.apply(null, arguments)}}},[_c('img',{class:_vm.$style.logo,attrs:{"src":_vm.urlImage}})]),_c('transition',{attrs:{"name":_vm.settings.routerAnimation,"mode":"out-in"}},[_c('router-view',{staticClass:"d-flex justify-content-center"})],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }