var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{}},[_c('cui-menu',{attrs:{"id":"header"}}),_c('BottomNavigation'),_c('a-layout',{class:{
      'cui__layout__contentMaxWidth': _vm.settings.isContentMaxWidth,
      'cui__layout__appMaxWidth': _vm.settings.isAppMaxWidth,
      'cui__layout__squaredBorders': _vm.settings.isSquaredBorders,
      'cui__layout__cardsShadow': _vm.settings.isCardShadow,
      'cui__layout__borderless': _vm.settings.isBorderless,
    }},[_c('a-layout-content',{},[_c('div',{staticClass:"cui__utils__content"},[_c('div',{class:_vm.$style.container},[_c('transition',{attrs:{"name":_vm.settings.routerAnimation,"mode":"out-in"}},[_c('router-view')],1)],1)])]),_c('a-layout-footer',[_c('cui-footer')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }