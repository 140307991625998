import actions from './actions'
import mutations from './mutations'

const state = {
  nameClient: '',
  listClientReseller: ['nayz'],
  listMenuReseller: ['store'], /* Name from "key" getMenuData */
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
