import { apiClientDistributorship } from '@/services/axios'
import { types } from './mutations'

export const GETRETUR = async (
  { commit, dispatch, rootState },
  { status, channel_id, buyer_id, page, limit },
) => {
  try {
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/return/query/list/${channel_id}?buyer_id=${buyer_id}&status=${status}&page=${page}&limit=${limit}`,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_RETURN, {
      loading: false,
    })
  }
}

export const GETRETURHISTORY = async (
  { commit, dispatch, rootState },
  { seller_id, buyer_id, start_date, end_date, page, limit },
) => {
  try {
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/payment/query/deposit/return/history?start_date=${start_date}&end_date=${end_date}&page=${page}&limit=${limit}`,
      headers: {
        'Buyer-Id': buyer_id,
        'Seller-Id': seller_id,
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_RETURN, {
      loading: false,
    })
  }
}

export const GETRETURINFO = async (
  { commit, dispatch, rootState },
  { channel_id, order_number },
) => {
  try {
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/return/query/info/${channel_id}/${order_number}`,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_RETURN, {
      loading: false,
    })
  }
}

export const GETBALANCERETURN = async (
  { commit, dispatch, rootState },
  { seller_id },
) => {
  try {
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/payment/query/deposit/return/balance${seller_id ? `?seller_id=${seller_id}` : ''}`,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_RETURN, {
      loading: false,
    })
  }
}

export const GETELIGIBLEORDER = async (
  { commit, dispatch, rootState },
  { channel_id, seller_id, catalog_id, base_qty, uom },
) => {
  try {
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/return/query/${channel_id}/eligible-order`,
      params: {
        seller_id,
        catalog_id,
        base_qty,
        month_duration: 6,
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_RETURN, {
      loading: false,
    })
  }
}

export const ONSUBMITCREATERETUR = async (
  { commit, dispatch, rootState },
  { channel_id, payload, price_area_id, customer_category_id },
) => {
  try {
    const { data: response } = await apiClientDistributorship({
      method: 'post',
      url: `/return/cmd/items/create/${channel_id}`,
      params: {
        price_area_id,
        customer_category_id,
      },
      data: payload,
      headers: {
        'Workspace-Id': 'KINO001',
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_RETURN, {
      loading: false,
    })
  }
}

export const GETRETURDETAIL = async (
  { commit, dispatch, rootState },
  { channel_id, retur_number },
) => {
  try {
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/return/query/detail/${channel_id}/${retur_number}`,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_RETURN, {
      loading: false,
    })
  }
}

export const GETMAXORDERQTY = async (
  { commit, dispatch, rootState },
  { order_id, catalog_id },
) => {
  try {
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/return/query/orders/${order_id}/catalogs/${catalog_id}/order-returns`,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_RETURN, {
      loading: false,
    })
  }
}

export const ONUPDATEQTY = async (
  { commit, dispatch, rootState },
  { channel_id, retur_id, payload },
) => {
  try {
    const { data: response } = await apiClientDistributorship({
      method: 'put',
      url: `/return/cmd/update/qty/${channel_id}/${retur_id}`,
      data: payload,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_RETURN, {
      loading: false,
    })
  }
}

export const ONDELETERETURITEM = async (
  { commit, dispatch, rootState },
  { channel_id, return_item_id },
) => {
  try {
    const { data: response } = await apiClientDistributorship({
      method: 'delete',
      url: `/return/cmd/items/delete/${channel_id}/${return_item_id}`,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_RETURN, {
      loading: false,
    })
  }
}

export const ONDELETERETUR = async (
  { commit, dispatch, rootState },
  { channel_id, return_id },
) => {
  try {
    const { data: response } = await apiClientDistributorship({
      method: 'delete',
      url: `/return/cmd/delete/${channel_id}/${return_id}`,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_RETURN, {
      loading: false,
    })
  }
}

export const ONSUBMITREQUESTRETUR = async (
  { commit, dispatch, rootState },
  { channel_id, retur_id, price_area_id, customer_category_id },
) => {
  try {
    const { data: response } = await apiClientDistributorship({
      method: 'put',
      url: `/return/cmd/update/${channel_id}/${retur_id}/REQUESTED`,
      params: {
        price_area_id,
        customer_category_id,
      },
      headers: {
        'Workspace-Id': 'KINO001',
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_RETURN, {
      loading: false,
    })
  }
}
