export const types = {
  SET_BILLING_STORE: 'SET_BILLING_STORE',
}

const mutations = {
  [types.SET_BILLING_STORE]: (state, payload) => {
    Object.assign(state, {
      ...payload,
    })
  },
}

export default mutations
