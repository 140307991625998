import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions'
import mutations from './mutations'

import Cookies from 'js-cookie'

import ChartistTooltip from 'chartist-plugin-tooltips-updated'

Vue.use(Vuex)
const options = {
  chartPadding: {
    right: 50,
    left: 50,
    top: 10,
    bottom: 5,
  },
  fullWidth: true,
  showPoint: true,
  lineSmooth: true,
  axisY: {
    showGrid: true,
    showLabel: true,
    offset: 0,
  },
  axisX: {
    showGrid: true,
    showLabel: true,
    offset: 20,
  },
  showArea: false,
  plugins: [
    ChartistTooltip({
      anchorToPoint: false,
      appendToBody: true,
      seriesName: false,
    }),
  ],
}

const ewallet = Cookies.get('ewallet')

const state = {
  loading: false,
  commission: {},
  lastSoldItems: [],
  orderSummary: {},
  graphSummary: {},
  // dataCart: {},
  optionsCart: options,
  // totalWeeklySales: 0,
  bestSeller: [],
  ewallet: ewallet,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
