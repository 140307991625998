<template>
  <div id="app">
    <localization></localization>
    <b-modal :no-close-on-backdrop="true" hide-footer hide-header centered v-model="modalSession" size="md">
      <div class="d-flex flex-column align-items-center text-center">
        <div class="mb-1">
          <Warning />
        </div>
        <span style="color: #015289" class="font-weight-bold mb-2">Waktu sesi habis</span>
        <span class="mb-2">Silahkan masuk kembali untuk memperbarui sesi Anda.</span>
        <a-button
          type="primary"
          size="large"
          class="text-center w-fit mt-2"
          @click="onRedirect"
        >
          OKE
        </a-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapGetters } from 'vuex'
import Localization from '@/localization'
import {
  watchEffect,
  reactive,
  toRefs,
  getCurrentInstance,
} from 'vue'
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'app',
  components: { Localization },
  data: function () {
    return {
      modalSession: false,
    }
  },
  computed: {
    ...mapState(['settings']),
    ...mapState(['app']),
    ...mapState('user', ['authorized']),
    ...mapState({
      gaId: state => state.app.ga_id,
    }),
    ...mapGetters('meta', ['styleVariable']),
    nextRoute() {
      return this.$route.query.redirect || '/'
    },
    currentRoute() {
      return this.$route.path
    },
  },
  // async beforeCreated() {
  //   await this.$store.dispatch('GETDATAADMIN')
  //     .then(({ data }) => {
  //       // console.log(data, 'ini data get data admin')
  //       this.$store.commit('settings/SET_PRIMARY_COLOR', { color: this.settings.primaryColor })
  //       this.$store.commit('settings/SET_THEME', { theme: this.settings.theme })
  //     })
  // },
  mounted() {
    this.$store.dispatch('global/getClientName')
    const sessionId = window.localStorage.getItem('session_id')
    if (!sessionId) {
      window.localStorage.setItem('session_id', uuidv4())
    }
  },
  watch: {
    '$store.state.app.business_id': {
      immediate: true,
      handler() {
        this.$store.dispatch('meta/CONTACT')
      }
    },
    '$store.state.settings.theme'(theme) {
      this.$store.commit('settings/SET_THEME', { theme })
    },
    authorized(authorized) {
      if (authorized && this.currentRoute === '/auth/login') {
        this.$router.replace(this.nextRoute)
      }
    },
    '$route'(to, from) {
      const query = Object.assign({}, to.query)
      this.$store.commit('settings/SETUP_URL_SETTINGS', query)
    },
    'styleVariable'(vars) {
      for (const key in vars) {
        document.documentElement.style.setProperty(`--biz-${key}`, vars[key])
      }
    },
    // '$store.state.user': {
    //   deep: true,
    //   immediate: true,
    //   handler(value) {
    //     if (value && value.accessToken === null){
    //       this.modalSession = true
    //     }
    //   }
    // },
    '$store.state.idleVue': {
      deep: true,
      immediate: true,
      handler(value) {
        const path = this.currentRoute
        if (value && value.isIdle === true && path.includes('auth') === false) {
          this.modalSession = true
          this.$store.commit('user/SET_STATE', {
            accessToken: null,
          })
          this.$store.dispatch('user/LOGOUT')
        }
      }
    },
    gaId: {
      immediate: true,
      handler(value) {
        if (value) {
          this.initializeGTag(window, document, 'script', 'dataLayer', this.$store.state.app.ga_id)
        }
      },
    }
  },
  methods: {
    initializeGTag(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    },
    onRedirect() {
      this.modalSession = false
      window.location.reload()
    }
  },
  metaInfo() {
    return {
      link: [
        { type: 'image/png', rel: 'icon', href: ` ${this.$store.state.app.favicon_url} ` },
      ],
    }
  },
}
</script>
