/* eslint-disable */
import { apiClientDistributorship } from '@/services/axios'
import { getOptions } from '@/services/product'
import * as MutationTypes from './mutation-types'

export const GETPRODUCTLIST = async ({ commit, rootState }, { search_query, page = 1, limit = 35, sort, order, category, channel_id, warehouse_query, is_distinct = true }) => {
  try {
    commit(MutationTypes.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/product/query/v2/${channel_id}?${warehouse_query}`,
      params: {
        page,
        size: limit,
        sort,
        order,
        category,
        search_query,
        is_distinct,
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const GETPRODUCTDETAIL = async ({ commit }, { channel_id, product_id, warehouse_id }) => {
  try {
    commit(MutationTypes.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/product/query/${channel_id}/${product_id}`,
      params: {
        warehouse_id,
      }
    })

    const colorOptions = getOptions(response.data?.catalogs || [], 'color')
    const sizeOptions = getOptions(response.data?.catalogs || [], 'size')
    const flavorOptions = getOptions(response.data?.catalogs || [], 'flavor')

    const defaultOptions = {
      color: colorOptions.length ? colorOptions[0] : null,
      size: sizeOptions.length ? sizeOptions[0] : null,
      flavor: flavorOptions.length ? flavorOptions[0] : null,
    }
    commit(MutationTypes.SET_COLOR_OPTIONS, colorOptions)
    commit(MutationTypes.SET_SIZE_OPTIONS, sizeOptions)
    commit(MutationTypes.SET_FLAVOR_OPTIONS, flavorOptions)
    commit(MutationTypes.SET_DEFAULT_OPTIONS, defaultOptions)
    commit(MutationTypes.SET_PRODUCT_DETAIL, response.data)

    return Promise.resolve(response.data)
  } catch (err) {
    return Promise.reject(err)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const GETDISCOUNT = async ({ commit, dispacth, rootState }, { channel_id, business_id, catalog_ids, customer_category_id }) => {
  try {
    commit(MutationTypes.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/promotion/query/internal/${channel_id}/product-promo`,
      params: {
        business_id,
        catalog_ids,
        customer_category_id,
      }
    })

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const GETPRODUCTSHIPPING = async ({ commit, dispacth, rootState }, { channel_id = 1273, business_id, product_id }) => {
  try {
    commit(MutationTypes.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/shipping/query/internal/${channel_id}/catalog-shipping-unit`,
      params: {
        business_id,
        product_id,
      }
    })

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const GETPRODUCTCATEGORIES = async ({ commit, dispatch, rootState, state }, { channel_id = 154 }) => {
  const categories = state.categories

  if (categories.length) return categories

  try {
    commit(MutationTypes.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: '/product/query/categories',
    })

    commit('set', { key: 'categories', value: response })

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const GETBASEQTYUOM = async ({ commit, dispatch, rootState }, { channel_id = 154, catalog_id, warehouse_id }) => {
  try {
    commit(MutationTypes.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/inventory/query/${channel_id}`,
      params: {
        warehouse_id: warehouse_id,
        catalog_id: catalog_id,
      },
    })

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const GETPRODUCTBYTYPE = async ({ commit, rootState }, { page = 1, limit = 35, channel_id, type, warehouse_query }) => {
  try {
    commit(MutationTypes.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/product/query/${channel_id}/selected/${type}?${warehouse_query}`,
      params: {
        page,
        limit,
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const GETPRODUCTDETAILBYCATALOG = async ({ commit }, { channel_id, catalog_id }) => {
  try {
    commit(MutationTypes.SET_LOADING, true)
    let catalog_ids = ''
    catalog_id.forEach((id, index) => {
      catalog_ids = `${catalog_ids}catalog_ids=${id}${(index+1) < catalog_id.length ? '&' : ''}`
    })
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/product/query/internal/${channel_id}/catalogs?${catalog_ids}`,
    })
    return Promise.resolve(response.data)
  } catch (err) {
    return Promise.reject(err)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

// export const GETPRODUCTLIST = async ({ commit, rootState }, payload) => {
//   try {
//     commit(MutationTypes.SET_LOADING, true)
//     const { page = 1, limit = 35, q = '', sort = '', order = '', instore = '0' } = payload
//     const { data: response } = await axios({
//       method: 'get',
//       url: '/products/private',
//       params: {
//         page,
//         limit,
//         q,
//         sort,
//         order,
//         instore,
//       },
//     })
//     const { data } = response

//     // count for product not in user store
//     let count = 0
//     data.map(item => item.in_store === 0 && count++)

//     if (page !== 1) {
//       const { products, totalProductOutStore } = rootState.product
//       const newData = products.concat(data)
//       const newCount = totalProductOutStore + count
//       commit(MutationTypes.SET_PRODUCT_LIST, newData)
//       commit(MutationTypes.SET_TOTAL_PRODUCT_OUT_STORE, newCount)
//     } else {
//       commit(MutationTypes.SET_PRODUCT_LIST, data)
//       commit(MutationTypes.SET_TOTAL_PRODUCT_OUT_STORE, count)
//     }

//     commit(MutationTypes.SET_TOTAL_PRODUCTS, response.total_row)
//     return Promise.resolve()
//   } catch (e) {
//     console.log(e)
//   } finally {
//     commit(MutationTypes.SET_LOADING, false)
//   }
// }

// export const ADDPRODUCTTOSTORE = async ({ commit }, payload) => {
//   try {
//     commit(MutationTypes.SET_LOADING, true)
//     const { data: response } = await axios({
//       method: 'post',
//       url: '/products/private/seller',
//       data: {
//         ids: payload,
//       },
//     })
//     const { data } = response
//     return Promise.resolve(data)
//   } catch (err) {
//     return Promise.reject(err)
//   }
// }

// export const DELETEFROMSTORE = async ({ commit }, payload) => {
//   try {
//     commit(MutationTypes.SET_LOADING, true)
//     const { data: response } = await axios({
//       method: 'delete',
//       url: '/products/private/seller',
//       data: {
//         ids: payload,
//       },
//     })
//     const { data } = response
//     return Promise.resolve(data)
//   } catch (err) {
//     return Promise.reject(err)
//   }
// }

// export const GETPRODUCTINSTORE = async ({ commit, rootState }, payload) => {
//   try {
//     commit(MutationTypes.SET_LOADING, true)
//     const { page = 1, limit = 25, q = '', sort = '', order = '' } = payload
//     const { data: response } = await axios({
//       method: 'get',
//       url: '/products/private/seller',
//       params: {
//         page,
//         limit,
//         q,
//         sort,
//         order,
//         channel_id: rootState.user.store_channelid,
//       },
//     })
//     const { data } = response
//     if (page !== 1) {
//       const { productsInStore } = rootState.product
//       const newData = productsInStore.concat(data)
//       commit(MutationTypes.SET_PRODUCTS_IN_STORE, newData)
//     } else {
//       commit(MutationTypes.SET_PRODUCTS_IN_STORE, data)
//     }
//     commit(MutationTypes.SET_TOTAL_MY_PRODUCTS, response.total_row)
//   } catch (err) {
//     console.log(err)
//   } finally {
//     commit(MutationTypes.SET_LOADING, false)
//   }
// }
// const getColorOptions = (catalogues) => Object.keys(getOptions(catalogues, 'color'))
// const getSizeOptions = (catalogues) => Object.keys(getOptions(catalogues, 'size'))
// const getFlavorOptions = (catalogues) => Object.keys(getOptions(catalogues, 'flavor'))
