import Vue from 'vue'
import Vuex from 'vuex'
import { decode } from 'jsonwebtoken'
import Cookies from 'js-cookie'

import actions from './actions'

const token = Cookies.get('auth_token')
const user_data = Cookies.get('user_data') ? JSON.parse(Cookies.get('user_data')) : {}

const getExpireIn = token => {
  if (!token) return null

  const jwt = decode(token)

  return jwt ? jwt.exp : 0
}

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    accessToken: token,
    expireIn: getExpireIn(token),
    loading: false,
    user_data: {
      buyer_id: user_data && user_data.buyer_id,
      first_name: user_data && user_data.first_name,
      last_name: user_data && user_data.last_name,
      store_type: user_data && user_data.store_type,
      area_id: user_data && user_data.area_id,
      buyer_user_id: user_data && user_data.buyer_user_id,
      level_model_id: user_data && user_data.level_model_id,
      store_name: user_data && user_data.store_name,
      phone: user_data && user_data.phone,
      email: user_data && user_data.email,
      channel_id: user_data && user_data.channel_id,
      // hardcode loyalty channel
      loyalty_channel_id: user_data && user_data.loyalty_channel_id,
      seller: user_data && user_data.seller,
      customer_category_id: user_data && user_data.customer_category_id,
      price_area_id: user_data && user_data.price_area_id,
      user_type: user_data && user_data.user_type,
      change_login_req: user_data && user_data.change_login_req,
    },
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    LOGOUT(state) {
      Cookies.remove('auth_token')
      Cookies.remove('user_data')
      state.user_data = {}
      state.accessToken = null
    },
    CLEAR_LOGIN(state) {
      Object.assign(state, {
        loginResponse: {
          code: 0,
          message: '',
        },
      })
    },
    SET_DATA_RESET_PASSWORD (state, payload) {
      const { link, token } = payload
      state.confirmpass.link = link
      state.confirmpass.token = token
    },
  },
  actions,
  getters: {
    user: state => state,
    loginStatusCode: state => state.loginResponse.code,
    expireIn: state => getExpireIn(state.accessToken),
    seller_type: state => state.seller_type,
    user_email: state => state.email,
    change_login_req: state => state.user_data.change_login_req,
    sellerType: state => state?.seller_type,
    isDropship: (_, getter) => getter.sellerType === 'dropship',
    isDistributor: (_, getter) => getter.sellerType === 'distributor',

    authorized: (state) => {
      return !!state.accessToken
    },
    isGuest: (state) => {
      return !state.user_data.user_type || state.user_data.user_type === 'GUEST'
    },
    isExpired: (state) => {
      return Number(state.expireIn) * 1000 <= Date.now()
    },
  },
}
