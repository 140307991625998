import { apiClientDistributorship } from '@/services/axios'
import { types } from './mutations'

export const GETPRICEPRODUCT = async (
  { commit, dispatch, rootState },
  { channel_id, id, price_area_id, customer_category_id, limit },
) => {
  try {
    commit(types.SET_PIRCE_STORE, {
      loading: true,
    })
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/price/query/v2/${channel_id}`,
      params: {
        price_area_id: price_area_id,
        customer_category_id: customer_category_id,
        product_id: id.join(','),
        limit,
      },
      headers: {
        'Workspace-Id': 'PCA001',
      },
    })

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_PIRCE_STORE, {
      loading: false,
    })
  }
}

export const GETPRICEREDEEMPRODUCT = async (
  { commit, dispatch, rootState },
  { channel_id, id, effective_date },
) => {
  try {
    commit(types.SET_PIRCE_STORE, {
      loading: true,
    })
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/price/query/v2/powerbiz/${channel_id}`,
      params: {
        product_id: id.join(','),
        effective_date: effective_date,
      },
      headers: {
        'workspace-Id': 'KINO001',
      },
    })

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_PIRCE_STORE, {
      loading: false,
    })
  }
}

export const GETCREDITLIMIT = async ({ commit }, { channel_id, seller_id }) => {
  try {
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/payment/query/creditlimit/validity/${channel_id}/${seller_id}`,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_PIRCE_STORE, {
      loading: false,
    })
  }
}

export const GETSUMMARYLIST = async (
  { commit },
  { channel_id, page = 1, limit = 20, customerId, seller_id },
) => {
  try {
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/payment/query/bills/summary/list/${channel_id}/CBD?page=${page}&limit=${limit}`,
      headers: {
        'Customer-Id': customerId,
        'Seller-Id': seller_id,
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_PIRCE_STORE, {
      loading: false,
    })
  }
}
