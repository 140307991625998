import Vue from 'vue'
import Vuex from 'vuex'

import user from './user'
import global from './global'
import settings from './settings'
import dashboard from './dashboard'
import channel from './channel'
import app from './app'
import data from './data'
import sales from './sales'
import account from './account'
import product from './product'
import loyalty from './loyalty'
import storeSetting from './storeSetting'
import purchase from './purchase'
import warehouse from './warehouse'
import price from './price'
import meta from './meta'
import billing from './billing'
import retur from './retur'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    app,
    user,
    settings,
    dashboard,
    channel,
    data,
    sales,
    account,
    product,
    loyalty,
    storeSetting,
    purchase,
    warehouse,
    price,
    global,
    meta,
    billing,
    retur,
  },
  state: {},
  mutations: {},
})

export default store

export const useStore = () => store
