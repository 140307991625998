export const types = {
  SET_RETURN: 'SET_RETURN',
}

const mutations = {
  [types.SET_RETURN]: (state, payload) => {
    Object.assign(state, {
      ...payload,
    })
  },
}

export default mutations
