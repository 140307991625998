import { apiClientFastify } from '@/services/axios'
import * as MutationTypes from './mutation-types'
import { requestToken } from '@/services/image/token'
import { upload } from '@/services/image/imagekit'

export const GETPROFILE = async ({ commit }) => {
  try {
    const { data: response } = await apiClientFastify.get('/users/private/seller/store')
    const { data } = response
    commit(MutationTypes.SET_PROFILE, data)
  } catch (err) {
    console.log(err)
  }
}

export const EDITSTOREPROFILE = async ({ commit, state }, payload) => {
  try {
    commit(MutationTypes.SET_LOADING, true)
    const { image, storename: name } = payload
    let image_data = null
    if (image) {
      const tokens = await requestToken({ qty: 1 })
      const uploadImage = await upload({
        file: image.file,
        expire: tokens[0].expire,
        publicKey: tokens[0].public_key,
        signature: tokens[0].signature,
        token: tokens[0].token,
        folder: tokens[0].image_path,
      })
      image_data = {
        name: uploadImage.name,
        size: uploadImage.size,
        type: uploadImage.fileType,
        path: tokens[0].image_path,
        ref_id: uploadImage.fileId,
        user_type: 2,
        content_id: 13,
      }
    } else {
      image_data = state.storeProfile.image_data
    }
    const { data: response } = await apiClientFastify({
      method: 'put',
      url: '/users/private/seller/store',
      data: {
        store_data: {
          name,
        },
        image_data,
      },
    })
    return Promise.resolve(response)
  } catch (err) {
    console.log(err)
    return Promise.reject(err)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const GETBANNER = async ({ commit, state }) => {
  try {
    const { data: response } = await apiClientFastify.get('/data/private/resellerbannerlist')
    commit(MutationTypes.SET_BANNER_LIST, response.data.image_data)
  } catch (err) {
    console.log(err)
  }
}

export const CHANGEBANNER = async ({ commit, state }, { image_data }) => {
  try {
    commit(MutationTypes.SET_LOADING, true)
    const { data: response } = await apiClientFastify({
      method: 'post',
      url: '/data/private/resellerbannerlist',
      data: {
        image_data,
      },
    })
    commit(MutationTypes.SET_BANNER_LIST, response.data.image_data)
    Promise.resolve()
  } catch (error) {
    Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}
