<template>
  <div :class="{ [$style.navbar]: true, 'searchbar-open': openMobileSearchBar }"
    @mouseleave="setChildrenCategory(null)">
    <div :class="`${$style.headerMenu}`">
      <div class=" biz-container w-100 d-flex justify-content-between align-items-center">
        <div :class="`${$style.subContact} d-flex`">
          <div @click.prevent="() => sentMail()" style="cursor: pointer;">
            <i class="fe fe-mail font-size-14" />
            {{ contactEmail }}
          </div>
          <div>
            <i class="fe fe-clock font-size-14" />
            {{ contactClock }}
          </div>
          <div @click.prevent="() => sentWA()" style="cursor: pointer;">
            <i class="fe fe-phone font-size-14" />
            {{ contactPhone }}
          </div>
        </div>
        <div class="d-flex items-center">
          <div v-if="!authorized || isGuest" class="guest-menu">
            <div>
              <i class="fe fe-user font-size-18" />
            </div>
            <router-link to="/auth/login">
              Login
            </router-link>
            <span>/</span>
            <router-link to="/auth/register">
              Sign up
            </router-link>
          </div>
          <cui-user-menu v-else />
        </div>
      </div>
    </div>

    <div :class="`${$style.headerMenu2} w-100`">
      <div class="biz-container">
        <div :class="`${$style.menu} w-100`">
          <div :class="$style.logoContainer">
            <router-link to="/">
              <div :class="$style.logo">
                <img :src="$store.state.app?.logo" class="mr-0" alt="Clean UI" />
              </div>
            </router-link>
          </div>

          <div class="searchbar-wrapper">
            <div class="searchbar-input" :class="$style.searchBar">
              <a-input :value="$route.query.search_query" :placeholder="$t('form.search')" @change="handleSearch($event.target.value)" />
              <button @click="handleSubmitSearch">
                <i class="fe fe-search font-size-18" />
              </button>
            </div>
            <button class="btn-close" @click="openMobileSearchBar = false">
              <i class="fe fe-x" />
            </button>
          </div>

          <div :class="$style.navigation">
            <div class="mx-2 md:biz-hidden biz-flex align-items-center">
              <button type="button" class="mb-1" @click="openMobileSearchBar = true">
                <i class="fe fe-search" />
              </button>
            </div>
            <cui-cart-menu />
            <!-- <cui-purchase-menu />
          <cui-loyalty-menu />
          <cui-language-switcher />
            -->
          </div>
          <!-- <div
          v-if="settings.isMobileView"
          :class="$style.navigation"
        >
          <cui-cart-menu />
          <cui-drawer-menu />
        </div> -->
        </div>
        <div :class="`${$style.categoryMenu}`">
          <div :class="`${$style.titleMenu} d-flex w-100`">
            <a-cascader popupClassName="category-cascader" placeholder="Please select" :options="allCategory"
              expandTrigger="hover" :changeOnSelect="true" :open="true" @change="onChangeCategory">
              <div class="category__list__navbar">
                Semua Kategori
              </div>
            </a-cascader>

            <div v-for="(category, index) in categoryList" :key="index" class="category__list__navbar"
              @click="onClickCategories(category)">
              {{ category.name }}
            </div>
          </div>
          <!-- <div @click.prevent="$router.push({ path:'/purchase/catalogue' })" style="cursor: pointer;">
          Produk Lainnya
        </div> -->
        </div>
      </div>
    </div>

    <div class="mobile-search-bar">

    </div>

    <!-- <div :class="$style.pointSection">
      <span>{{ $t('dashboard.totalPoint') }}</span>
      <img src="https://ik.imagekit.io/fepca/220309_Desain_Icon_Kino_Siap_-_Loyalty_Point_1_d0yHZ4-ch.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1653948298528" />
      <span
        :class="$style.point"
        @change="update"
      >{{numberWithCommas}}</span>
    </div> -->
    <div class="children__category__wrapper" v-if="childrenCategory">
      <div class="children__category__container biz-container">
        <div class="children__category">
          <div v-for="(item, index) in childrenCategoryList" :key="index">
            <div class="children__category_content" style="position: relative;">
              <div :class="`children__title ${!item.children.length ? 'active' : ''}`"
                @click="redirectToProductCategory(item)">{{ item.name }}</div>
              <div v-if="item.children.length" class="last__children__category" :style="{
    '--max-item': item.children.length > 17 ? 17 : item.children.length
  }">
                <div v-for="(el, index) in item.children" :key="`last__children_${index}`">
                  <span @click="onClickCategories(el, true)">
                    {{ el.name }}
                    <i class="fe fe-chevron-right" style="vertical-align: middle" v-if="el.children.length"></i>
                  </span>

                  <div class="list-category-children-absolute"
                    v-if="categoryLastChildrenList.length && keyParent === el.id" :key="el.id">
                    <div v-for="(val, idx) in categoryLastChildrenList" :key="`last__children__${idx}`">
                      <span @click="$router.push({ path: `/purchase/catalogue/${val.id}` })">
                        {{ val.name }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import { getMenuData } from '@/services/menu'
import CuiUserMenu from './Components/UserMenu'
import CuiCartMenu from './Components/CartMenu'
import debounce from 'lodash/debounce'

// import CuiLoyaltyMenu from './Components/LoyaltyMenu'
// import CuiPurchaseMenu from './Components/PurchaseMenu'
// import CuiLanguageSwitcher from './Components/LanguageSwitcher'
// import CuiDrawerMenu from './Components/DrawerMenu'

export default {
  name: 'menu',
  components: {
    CuiUserMenu,
    // CuiLanguageSwitcher,
    CuiCartMenu,
    // CuiLoyaltyMenu,
    // CuiPurchaseMenu,
    // CuiDrawerMenu,
  },
  data() {
    return {
      search: '',
      totalCart: 0,
      currentPoint: 0,
      contactPhone: '',
      contactEmail: '',
      contactClock: '',
      categoryList: [],
      childrenCategoryList: [],
      categoryLastChildrenList: [],
      childrenCategory: false,
      openMobileSearchBar: false,
      keyParent: null,
      allCategory: [],
    }
  },
  computed: {
    ...mapState(['settings', 'dashboard']),
    ...mapGetters('user', ['user', 'isGuest', 'authorized']),
    numberWithCommas() {
      // return this.currentPoint
      //   .toString()
      //   .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
      return `${Intl.NumberFormat('en-US').format(this.currentPoint || 0)}`
    },
  },
  watch: {
    '$route.query'() {
      // issue error console
      // this.fetchLoyalty()
    },
    '$store.state.meta.data': {
      deep: true,
      immediate: true,
      handler(value) {
        /* eslint-disable */
        value?.map((item) => {
          if (item.meta_name === 'contacts') {
            const tempMetaAddress = JSON.parse(String(item.meta_value)) ?? null
            this.contactPhone = tempMetaAddress?.phone
            this.contactEmail = tempMetaAddress?.email
            this.contactClock = tempMetaAddress?.operational
          }
        })
      }
    },
  },
  mounted() {
    this.loading = true
    // This is for Getting Cart
    this.getCategory()
    this.$store
      .dispatch(this.$store.getters['user/isGuest'] ? 'purchase/GETCARTGUEST' : 'purchase/GETCART', {
        channel_id: this.$store.state.user.user_data.channel_id,
      })
      .then(({ data }) => {
        if (
          data &&
          data.id &&
          Array.isArray(data.cart_list) &&
          data.cart_list.length
        ) {
          this.totalCart = data.cart_list.length
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        this.loading = false
      })
    this.$store
      .dispatch('loyalty/GETLOYALTY', {})
      .then((response) => {
        if (!response) return

        const { data } = response
        this.currentPoint = data.available_point
      })
      .catch((err) => console.error(err))
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    onClickCategories(category, isShow) {
      if (category?.children?.length) {
        if (isShow) {
          this.showChildrenCategory(category)
        } else {
          this.setChildrenCategory(category.id)
        }
      } else {
        this.childrenCategory = false
        this.$router.push({ path: `/purchase/catalogue/${category.id}` }).catch(() => { })
      }
    },
    showChildrenCategory(val) {
      this.keyParent = val.id
      this.categoryLastChildrenList = val.children
    },
    redirectToProductCategory(item) {
      if (!item.children.length) {
        this.$router.push({ path: `/purchase/catalogue/${item.id}` })
      }
    },
    setChildrenCategory(id) {
      if (!id) {
        this.childrenCategoryList = []
        this.childrenCategory = false
      } else {
        const filter = this.categoryList.filter(el => el.id === id)
        this.childrenCategoryList = filter[0].children.reverse().sort((a, b) => {
          return a.sortOrder - b.sortOrder
        })
        this.childrenCategory = true
      }
    },
    getCategory() {
      this.$store
        .dispatch('product/GETPRODUCTCATEGORIES', {
          channel_id: this.$store.state.user.channel_id,
        })
        .then((response) => {
          this.categoryList = response.sort((a, b) => {
            return a.sortOrder - b.sortOrder
          })
          const normalizeCategoryList = JSON.parse(JSON.stringify(this.categoryList).replaceAll("\"id\":", "\"value\":").replaceAll("\"name\":", "\"label\":"));
          this.allCategory = normalizeCategoryList
        })
    },
    extractChildrenCategory(children) {

    },
    update() {
      this.$mount()
    },

    handleSearch(value) {
      this.search = value
      if (value.length >= 3 || !value) {
        this.handleDebounceSearch()
      }
    },
    handleDebounceSearch: debounce(function (value) {
      this.$router.push({
        path: '/purchase/catalogue',
        query: { ...this.$route.query, search_query: this.search || undefined, page: 1, },
      })
    }, 1000),
    handleSubmitSearch() {
      this.$router.push({
        path: '/purchase/catalogue',
        query: { ...this.$route.query, search_query: this.search || undefined },
      })
    },
    sentMail() {
      window.open(`mailto:${this.contactEmail}`, '_blank')
    },
    getPhoneNumberOnly(phoneNumber) {
      var numb = phoneNumber.match(/\d/g)
      return numb.join('')
    },
    sentWA() {
      window.open(`https://api.whatsapp.com/send?phone=${this.getPhoneNumberOnly(this.contactPhone)}`, '_blank')
    },
    onChangeCategory(e) {
      this.$router.push({ path: `/purchase/catalogue/${e.slice(-1)}` })
    },
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
<style lang="scss">
.category-cascader {
  top: 173px !important;
  left: 0 !important;
  width: 100% !important;
  background-color: #fafafa !important;

  .ant-cascader-menus-content {
    padding-left: 18px !important;
    padding-right: 18px !important;
    margin: auto !important;
    position: relative !important;
    max-width: 1280px !important;
    width: 100% !important;
    height: 100% !important;

    .ant-cascader-menu {
      height: max-content !important;
      max-height: 400px !important;
      border: 0px !important;
    }

    @media (max-width:576px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .ant-cascader-menu-item-expand {
      padding-right: 50px !important;
    }

    .ant-cascader-menu-item-active {
      color: var(--biz-secondary-100) !important;
    }
  }
}

.ant-dropdown {
  position: fixed !important;
  margin-top: 150px !important;
  margin-left: -15px !important;
}

.guest-menu {
  display: flex;
  align-items: center;
  color: #7D7D7D;
  font-size: 14px;

  >* {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  >a {
    color: #7D7D7D;
  }
}

.category__list__navbar {
  cursor: pointer;
}

.category__list__navbar:hover {
  color: #EC1C23;
}

.children__category__wrapper {
  position: absolute;
  left: 0;
  right: 0;
  background: #FAFAFA;
}

.children__category {
  // position: absolute;
  bottom: 0;
  z-index: 100;
  min-height: 200px;
  padding-top: 15px;
  padding-bottom: 30px;
  gap: 2rem;
  display: flex;

  &__container {}
}

.children__category_content {
  font-family: 'Open Sans';

  .children__title {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #525252;
  }

  .children__title.active:hover {
    cursor: pointer;
    color: #EC1C23;
  }

  .last__children__category {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #7C7C7C;
    gap: 1rem 2rem;
    margin-top: 20px;
    display: grid !important;
    grid-template-rows: repeat(var(--max-item), minmax(0, 1fr));
    grid-auto-flow: column;

    span {
      cursor: pointer;
    }

    span:hover {
      color: #EC1C23;
    }
  }
}
</style>

<style lang="scss" scoped>
.searchbar {
  &-wrapper {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 1;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    transform: translateY(-100%);
    opacity: 0;
    transition: all ease-in .3s;

    @include media-breakpoint-up(md) {
      position: relative;
      transform: none;
      width: 50%;
      opacity: 1;

      .btn-close {
        display: none;
      }
    }

    .btn-close {
      padding: 1rem 0 1rem 1rem;
      border: 0;
      background: transparent;
      font-size: 20px;
    }
  }

  &-input {
    display: flex;
    align-items: center;
    margin-right: 0;
    width: 100%;

    button {}
  }

  &-open {
    .searchbar-wrapper {
      transform: translateY(0%);
      opacity: 1;
    }
  }
}

.list-category-children-absolute {
  width: max-content;
  position: absolute;
  left: 200px;
  top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #7C7C7C;
  gap: 1rem 2rem;
  margin-top: 20px;
  display: grid !important;
  grid-template-rows: repeat(var(--max-item), minmax(0, 1fr));
  grid-auto-flow: column;
}
</style>
