import * as types from './types'

import { apiClientDistributorship } from '@/services/axios'

const actions = {
  async GETDATAADMIN({ rootState, rootGetters, state, dispatch, commit }) {
    if (state.filled) return state

    try {
      commit(types.SET, {
        fetched: true,
      })
      const { data } = await apiClientDistributorship({
        method: 'get',
        url: '/user/v2/query/data/admin',
      })
      const app_data = {
        ...data,
        business_id: data.business_id,
        id: data.business_id,
        ga_id: data.ga_id || null,
        seller_type: data.seller_type,
        vendor_hashid: data.vendor_hashid,
        vendor_name: data.vendor_name,
        website_title: data.website_title,
        channel_id: +data.channel_id,
        store_url: data.store_url,
        logo: data.logo_url,
        favicon_url: data.favicon_url,
        filled: true,
      }
      commit(types.SET, {
        ...app_data,
      })
      const user = rootState.user
      const isExpired = rootGetters['user/isExpired']
      const { pathname } = window.location
      if ((!user.accessToken || isExpired) && !pathname.includes('/auth/login')) {
        await dispatch('user/GUEST_LOGIN')
      }

      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit(types.SET, {
        fetched: false,
      })
    }
  },
}

const app_data = {}

const state = {
  fetched: true,
  filled: false,
  business_id: app_data.business_id,
  id: app_data.business_id,
  ga_id: app_data.ga_id,
  seller_type: app_data.seller_type,
  vendor_hashid: app_data.vendor_hashid,
  vendor_name: app_data.vendor_name,
  website_title: app_data.website_title,
  channel_id: app_data.channel_id,
  store_url: app_data.store_url,
  logo: app_data.logo,
  favicon_url: app_data.favicon_url,
}

const mutations = {
  [types.FETCHED]: (state) => {
    state.fetched = false
  },
  [types.SET]: (state, payload) => {
    Object.assign(state, {
      ...payload,
    })
  },
}

export default {
  namespace: true,
  state,
  mutations,
  actions,
}
