import { apiClientFastify } from '@/services/axios'
import * as MutationTypes from './mutation-types'

export const getChannels = async ({ state, commit, dispatch }, force = false) => {
  if (!state.list || !state.list.length || force) {
    try {
      dispatch('getChannelPriceLevelDefaultValue', force)
      const { data: response } = await apiClientFastify.get('users/private/channels')
      commit(MutationTypes.SET_CHANNELS, response.message)
      return Promise.resolve(response.message)
    } catch (err) {
      return Promise.reject(err)
    }
  } else {
    return Promise.resolve(state.list)
  }
}

export const getChannelPriceLevelDefaultValue = async ({ state, commit }, force = false) => {
  const priceLevelDefaultValue = state.priceLevels

  if (priceLevelDefaultValue || priceLevelDefaultValue.length || force) {
    try {
      const { data: response } = await apiClientFastify.get('level_models/private')
      const list = response.data.sort((a, b) => Number(a.level_id) - Number(b.level_id))
      commit(MutationTypes.SET_PRICE_LEVEL_DEFAULT_VALUE, list)
      return Promise.resolve(list)
    } catch (err) {
      return Promise.reject(err)
    }
  } else {
    return Promise.resolve(priceLevelDefaultValue)
  }
}
