export const SET_PROFILE = 'SET_PROFILE'
export const SET_INITIALVALUE_CITY = 'SET_INITIALVALUE_CITY'
export const SET_INITIALVALUE_AREA = 'SET_INITIALVALUE_AREA'
export const SET_LOADING = 'SET_LOADING'
export const SET_USER_BANK = 'SET_USER_BANK'
export const SET_DEFAULT = 'SET_DEFAULT'
export const SET_COMMISSION_LIST = 'SET_COMMISSION_LIST'
export const SET_EWALLET_HISTORY = 'SET_EWALLET_HISTORY'
export const SET_PAGINATION = 'SET_PAGINATION'
export const SET_TOTAL_COMMISSION = 'SET_TOTAL_COMMISSION'
export const SET_LAST_WITHDRAW = 'SET_LAST_WITHDRAW'
export const SET_STATE_DETAIL_USER = 'SET_STATE_DETAIL_USER'
export const SET_STATE_DETAIL_CUSTOMER = 'SET_STATE_DETAIL_CUSTOMER'
export const SET_LIST_SHIPPING_ADDRESS = 'SET_LIST_SHIPPING_ADDRESS'
