import * as MutationTypes from './mutation-types'

export default {
  [MutationTypes.SET_LOADING]: (state, payload) => {
    state.loading = payload
  },
  [MutationTypes.SET_PRODUCT_LIST]: (state, payload) => {
    state.products = payload
  },
  [MutationTypes.SET_TOTAL_PRODUCT_OUT_STORE]: (state, payload) => {
    state.totalProductOutStore = payload
  },
  [MutationTypes.SPLICE_PRODUCTS]: (state, payload) => {
    state.products.splice(payload)
  },
  [MutationTypes.SET_TOTAL_PRODUCTS]: (state, payload) => {
    state.totalProducts = payload
  },
  [MutationTypes.SET_PRODUCTS_IN_STORE]: (state, payload) => {
    state.productsInStore = payload
  },
  [MutationTypes.SET_TOTAL_MY_PRODUCTS]: (state, payload) => {
    state.totalMyProducts = payload
  },
  [MutationTypes.SET_PRODUCT_DETAIL]: (state, payload) => {
    state.productDetail = payload
  },
  [MutationTypes.SET_COLOR_OPTIONS]: (state, payload) => {
    state.colorOptions = payload
  },
  [MutationTypes.SET_SIZE_OPTIONS]: (state, payload) => {
    state.sizeOptions = payload
  },
  [MutationTypes.SET_FLAVOR_OPTIONS]: (state, payload) => {
    state.flavorOptions = payload
  },
  [MutationTypes.SET_DEFAULT_OPTIONS]: (state, payload) => {
    state.defaultOptions = payload
  },
  set: (state, { key, value }) => {
    state[key] = value
  },
}
