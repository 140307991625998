import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import * as actions from './actions'

Vue.use(Vuex)

const state = {
  loading: false,
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
