<script setup>
// import RouterLink from 'vue-router'
import { ref, computed, watch, getCurrentInstance } from 'vue'
import { useStore } from '@/store/index'
import { useRouter, useRoute } from 'vue-router/composables'
import CategoryMenuItem from './CategoryMenuItem.vue'

const router = useRouter()
const route = useRoute()

const store = useStore()

const categories = computed(() => store.state.product.categories || [])
const toggle = computed(() => store.state.settings.toggleCategory)

const toggleCategory = ref(false)
const lastBodyOverflowState = ref(null)
watch(toggleCategory, (state) => {
  const body = document.querySelector('body')

  if (state) {
    lastBodyOverflowState.value = body.style.overflow

    body.style.overflow = 'hidden'
  } else {
    body.style.overflow = lastBodyOverflowState.value
  }
})
watch(() => route.path, () => {
  toggleCategory.value = false
})

watch(() => toggle.value, (value) => {
  toggleCategory.value = value
})

const changeToggle = () => store.dispatch('settings/changeToggle')
</script>

<template>
  <div>
    <div class="bottom-navigation">
      <div class="bottom-navigation__wrapper">
        <RouterLink to="/" exact-active-class="active">
          <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M9 22V12H15V22" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div>
          <div>Beranda</div>
        </RouterLink>
        <a href="#" @click.prevent="changeToggle()">
          <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 6H21" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M8 12H21" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M8 18H21" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M3 6H3.01" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M3 12H3.01" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M3 18H3.01" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>

          </div>
          <div>Kategori</div>
        </a>
        <RouterLink to="/account/profile">
          <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <div>Akun</div>
        </RouterLink>
        <RouterLink to="/purchase">
          <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6 2L3 6V20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H19C19.5304 22 20.0391 21.7893 20.4142 21.4142C20.7893 21.0391 21 20.5304 21 20V6L18 2H6Z"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M3 6H21" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M16 10C16 11.0609 15.5786 12.0783 14.8284 12.8284C14.0783 13.5786 13.0609 14 12 14C10.9391 14 9.92172 13.5786 9.17157 12.8284C8.42143 12.0783 8 11.0609 8 10"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <div>Transaksi</div>
        </RouterLink>
      </div>
    </div>
    <Transition name="fade">
      <div v-if="toggleCategory" class="category-backdrop" @click="changeToggle()"></div>
    </Transition>
    <Transition name="category-transition">
      <div v-if="toggleCategory" class="category-menu">
        <div class="category-menu__body">
          <CategoryMenuItem
            v-for="category in categories"
            :key="category.id"
            :category="category"
          />
        </div>
      </div>
    </Transition>
  </div>
</template>

<style scoped lang="scss">
.bottom-navigation {
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.04));
  width: 100vw;

  @include media-breakpoint-up(md) {
    display: none;
  }

  &__wrapper {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: center;

    >* {
      padding: .5rem 1rem;
      text-align: center;
      width: 100%;
      font-size: 12px;
      line-height: 1;
      color: #B6B6B6;
      font-weight: 500;

      svg {
        display: inline-block;
        margin-bottom: .25rem;
      }

      &.active {
        color: #333;
      }

      &:hover {
        color: #333;
      }
    }
  }
}

.category-transition {
  &-enter-active {}

  &-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }

  &-enter,
  &-leave-to {
    transform: translateY(100%);
  }
}

.category-backdrop {
  transition: all .2s ease-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.5);
}

.category-menu {
  transition: all .3s ease-out;
  position: fixed;
  height: 80vh;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  background-color: #fff;
  border-radius: 1rem 1rem 0 0;

  &__header {
    display: flex;
    justify-content: space-between;
    font-size: 1.25rem;
    padding: .75rem 1rem;
    border-bottom: 1px solid #efefef;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;

    .btn-close {
      border: 0;
      font-size: 1.5rem;
      padding: 0 .25rem;
      line-height: 1;
    }
  }

  &__body {
    height: 100%;
    overflow-y: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &__item {
    padding: .5rem 1rem;
    align-items: center;
    font-weight: 500;

    &:not(:last-child) {
      border-bottom: 1px solid #efefef;
    }
  }
}
</style>
