export const SET_LOADING = 'SET_LOADING'
export const SET_PRODUCT_LIST = 'SET_PRODUCT_LIST'
export const SET_TOTAL_PRODUCT_OUT_STORE = 'SET_TOTAL_PRODUCT_OUT_STORE'
export const SPLICE_PRODUCTS = 'SPLICE_PRODUCTS'
export const SET_TOTAL_PRODUCTS = 'SET_TOTAL_PRODUCTS'
export const SET_PRODUCTS_IN_STORE = 'SET_PRODUCTS_IN_STORE'
export const SET_TOTAL_MY_PRODUCTS = 'SET_TOTAL_MY_PRODUCTS'
export const SET_PRODUCT_DETAIL = 'SET_PRODUCT_DETAIL'
export const SET_COLOR_OPTIONS = 'SET_COLOR_OPTIONS'
export const SET_SIZE_OPTIONS = 'SET_SIZE_OPTIONS'
export const SET_FLAVOR_OPTIONS = 'SET_FLAVOR_OPTIONS'
export const SET_DEFAULT_OPTIONS = 'SET_DEFAULT_OPTIONS'
