function toCurrencyIDR(value) {
  const toIDR = Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(value)
  return toIDR
}

function getSessionId() {
  return window.localStorage.getItem('session_id') ?? null
}
export {
  toCurrencyIDR,
  getSessionId,
}
