import localeAntd from 'ant-design-vue/lib/locale-provider/en_US'

const messages = {
  form: {
    add: 'Add',
    placeholder_search_product: 'Search by product name',
    placeholder_order_number: 'Order Number',
    placeholder_email_phone: 'Enter your email or phone number',
    placeholder_password: 'Enter your password',
    first_name: 'First Name',
    last_name: 'Last Name',
    phone: 'Phone Number',
    email: 'Email',
    OldEmail: 'Old Email',
    OldPhone: 'Old Phone',
    emailPhone: 'Email or Phone Number',
    address: 'Address',
    country: 'Country',
    owner: 'Owner',
    city: 'City',
    placeholderCity: 'Please select your city',
    area: 'Area',
    placeholderArea: 'Please select your area',
    postal_code: 'Postal Code',
    login_caption: 'Sign in to your account',
    password: 'Password',
    current_password: 'Current Password',
    new_password: 'New Password',
    confirm_new_password: 'Confirm Password',
    successConfirm: 'Your password successfully changed',
    passwordRequired: 'Password is required',
    newPasswordRequired: 'New password is required',
    confirmPasswordRequired: 'Confirm password is required',
    confirmPasswordNotMatch: 'Confirm password does not match',
    passwordNotMatch: "Your old password doesn't match",
    passwordValidate: 'Password must contain at least 8 characters, one uppercase letter, one lowercase letter and one number',
    password8char: 'Password must be at least 8 characters',
    storeName: 'Store Name',
    placeholderStoreName: 'Input your store name',
    birthPlace: 'Birth of Place',
    placeholderBirthPlace: 'Input your birth of place',
    birthDate: 'Birth of Date',
    addressPlace: 'Address',
    streetName: 'Street Name',
    placeholderStreetName: 'Input street name',
    pickPhoto: 'Pick photo',
    onUpload: 'Uploading Photo',
    addressStore: 'Address Store',
    placeholderRTRW: 'Input RT/RW',
    placeholderNoKTP: 'Input KTP number',
    clickOrDrag: 'Click or drag file to this area to upload',
    infoUpload:
      'Upload your photo store with format JPG, JPEG, PNG. Max size 4 MB. Dengan dimensi foto disarankan minimal 500x317px.',
    infoUploadKTP:
      'Upload your photo of Identity Card (KTP) with format JPG, JPEG, PNG. Max size 4 MB. Dengan dimensi foto disarankan minimal 500x317px.',
    infoUploadNPWP:
      'Upload your photo of Tax Identification Number (NPWP) with format JPG, JPEG, PNG. Max size 4 MB. Dengan dimensi foto disarankan minimal 500x317px.',
    registerAccount: 'REGISTER ACCOUNT',
    placeholderUrlStore: 'Input your url online store',
    yes: 'Yes',
    no: 'No',
    changeImage: 'Change Image',
    NPWP: 'NPWP Number',
    placeholderNPWP: 'Input your NPWP number',
    search: 'Find the product...',
    pinmap: 'Pin Address',
    setLocation: 'Flag Location',
    cancel: 'Cancel',
    selectLocation: 'Select Location',
    selectedLocation: 'Selected Location',
    searchLocation: 'Search Location',
  },
  dashboard: {
    weeklySales: 'Weekly Sales',
    onProcess: 'On Process Orders',
    completedOrders: 'Completed Orders',
    refundRequest: 'Refund Request',
    ewallet: 'E-wallet',
    totalCommission: 'Total Commission',
    onComingCommission: 'Commisions  On Coming Transaction',
    bestSeller: 'Best Seller Items',
    productNotFound: 'Product Not Found',
    lastSoldItems: 'Last Sold Items',
    totalPoint: 'Your total points',
  },
  profile: {
    billing: {
      billingTitle: 'Billing',
      paymentsNeedCompletion: 'Payments to be completed',
      viewDetails: 'View Details',
    },
  },
  purchase: {
    catalogue: {
      title: 'Catalogue Product',
      new: 'New Product',
      related: 'Related Products',
      all_product: 'All Product',
      caption1: 'Easier to find products in category',
      cart: 'Cart',
      addCart: 'Add to Cart',
      select_variant: 'Select Variant',
      select_qty_unit: 'Set Quantity and Unit',
      qty_req: 'Quantity required',
      product_not_found: 'Product not found',
      product_detail: 'Product Detail',
      product_description: 'Product Description',
      select_unit: 'Select Unit',
      outOfStock: 'Out of Stock',
      inStock: 'In Stock',
      login_disclaimer: 'Please login first, before adding items to cart',
      disclaimer_customer_not_active: 'Sorry<br>You cannot make a transaction because your account is no longer active.<br><br>Contact our customer service at 021-5524999 for more information.',
      go_to_login_page: 'Go to Login Page',
      view_only: 'This product is view only, please contact',
    },
    cart: {
      title: 'Cart',
      detail_product: 'Detail Product',
      quantity: 'Quantity',
      total: 'Total',
      unit: 'Unit',
      price: 'Price',
      delete: 'Delete',
      not_found_cart: 'Cart empty',
      caption_delete: 'Are you sure delete this product from cart?',
      yes: 'Yes',
      successDeleteCart: 'Success delete Cart',
      failedDeleteCart: 'Failed delete Cart',
      cancel: 'Cancel',
      checkout: 'Checkout',
      notif_select_unit: 'Select unit first',
      failed: 'Failed add to Cart!',
      success: 'Added to Cart!',
      minimum_price1: 'Your transaction has not reached',
      minimum_price2: 'Please add product to shopping cart',
    },
    checkout: {
      title: 'Checkout',
      shipping_detail: 'Shipping Address',
      billing_detail: 'Billing Address',
      please_fill_shipping_address: 'Please fill in the shipping address first',
      no_available_courier: 'There are no couriers available for your delivery address',
      save_address: 'Save Address',
      cancel: 'Cancel',
      select_another_address: 'Select Another Address',
      add_new_address: 'Add New Address',
      item: 'Item',
      select_payment_method: 'Select Payment Method',
      use_point: 'Use Coin',
      use_point_have: 'Use The Coins You Have',
      order_summary: 'Order Summary',
      total_product: 'Total Product',
      return_balance: 'Deposit Return',
      point_used: 'Coins Used',
      grand_total: 'Grand Total',
      confirm_order: 'Confirm Order',
      see_detail: 'See Detail',
      cart_empty: 'Cart Empty',
      here: 'here',
      select_product: 'Select product',
      create_order: 'Create Order',
      confirmShipping: 'Please confirm shipping address',
      confirmBilling: 'Please confirm billing address',
      confirmPaymentMethods: 'Please select payment method',
      successCheckout: 'Checkout Success !',
      failCheckout: 'Checkout Failed !',
      yourPotensialPoints: 'Bonus you will get',
      potensialModalTitle: 'Your potensial bonus',
      kinoBonusTitle: 'Coin Bonus',
      extraGoodsTitle: 'Extra Goods Promo',
      discountPercentTitle: 'Discount Promo',
      kinoPotensiBonusDesc: 'Potential points you will get ',
      kinoBonusDesc: 'Points you will get ',
      kinoExtraGoodsDesc: 'Potential extra goods you will get',
      discountPercentDesc: 'You get a discount bonus',
      discountCutDesc: 'You get a discount bonus',
      discountVoucher: 'Voucher Discount ',
      discountVoucherDesc: 'You get voucher discount ',
      discountProductDesc: 'You get bonus products ',
      discountVoucherFixed: 'Discount bonus ',
      use_shipping_address: 'Use Shipping Address',
      yourRemainingPoint: 'Your Remaining Coin',
    },
    list: {
      all_order: 'All Orders',
      price: 'Price',
      total: 'Total',
      po_number: 'PO Number',
      order_number: 'Order Number',
      created_at: 'Order Created',
      note: 'Note',
    },
    detail: {
      title: 'Order Detail',
      title_detail: 'Detail Transaction',
      order_information: 'Order Information',
      return_information: 'Return Information',
      total_payment: 'Total Payment',
      payment_status: 'Payment Status',
      payment_number: 'Transaction Number',
      payment_date: 'Transaction Date',
      payment_method: 'Payment Method',
      payment_term: 'Payment Term',
      payment_type: 'Payment Type',
      account_number: 'Account Number',
      bank: 'Bank',
      wallet: 'Wallet',
      shipping_information: 'Shipping Information',
      billing_information: 'Billing Information',
      recipient_name: 'Recipient Name',
      billed_name: 'Billed Name',
      address: 'Address',
      phone_number: 'Phone',
      courier: 'Courier',
      pick_up_admin: 'Pick Up Admin',
      product_detail: 'Products Detail',
      product: 'Product',
      grand_total: 'Grand Total',
      detail_transaction_qr:
        'Pindai menggunakan aplikasi pembayaran QR pilihan Anda.',
      pay_via_web_link: 'Pay via web link',
      bill_credit_limit: 'Credit Limit Bill',
      unpaid: 'Unpaid',
      paid: 'Paid',
      bill_status: 'Bill Status',
      bill_total: 'Bill Total',
      remaining_payment: 'Remaining Payment',
      remaining_payment_status: 'Remaining Payment Status',
      waiting_payment: 'Waiting Payment',
      main_payment_method: 'Main Payment Method',
      credit: 'Credit',
      credit_limit_used: 'Credit Limit Used',
      remaining_payment_method: 'Remaining Payment Method',
    },
  },
  payment: {
    unpaid: 'Unpaid',
    expired: 'Expired',
    paid: 'Paid',
    title: 'Payment',
  },
  sideBar: {
    dashboard: 'Dashboard',
    sales: 'Sales',
    orderList: 'Order List',
    commissionList: 'Commission List',
    orderListDistributor: 'Sale List',
    store: 'Store',
    availableProducts: 'Available Products',
    myProducts: 'My Products',
    setting: 'Setting',
    viewStore: 'View Store',
    account: 'Account',
    profile: 'Profile',
    editProfile: 'Edit Profile',
    ewalletHistory: 'E-wallet History',
    withdrawRequest: 'Withdraw Request',
    purchase: 'Purchase Order',
    purchaseList: 'Purchase List',
    createPurchase: 'Create Purchase',
    purchaseCatalogue: 'Catalog',
    purchaseCart: 'Cart',
    redeem_point: 'Redeem Coin',
    catalogue_redeem: 'Catalog Redeem',
    cart_redeem: 'Cart',
    purchase_redeem: 'Purchase',
    transaction_redeem: 'Transaction',
  },
  topBar: {
    hello: 'Hello',
    email: 'Email',
    phone: 'Phone',
    change_password: 'Change Password',
    editProfile: 'Edit Profile',
    logout: 'Logout',
    issuesHistory: 'Issues History',
    projectManagement: 'Project Management',
    typeToSearch: 'Search...',
    findPages: 'Find pages...',
    actions: 'Actions',
    status: 'Status',
    profileMenu: {
      hello: 'Hello',
      billingPlan: 'Billing Plan',
      role: 'Role',
      email: 'Email',
      phone: 'Phone',
      // editProfile: 'Edit Profile',
      logout: 'Logout',
    },
    myStore: 'My Store',
  },
  authForm: {
    loginFailed: 'Login Failed',
    registerFailed: 'Register Failed',
    invalidPassword: 'Invalid Password',
    emailNotFound: 'User Email Not Found',
    systemBusy:
      'System seems to be busy, please try again later or contact administrator!',
    thankYou: 'Thank You',
    forgotpassSucces: 'Please check your email for completing reset password',
    forgotpassFailed: 'Forgot Password Failed',
    passwrdNotmatch: 'Password is different!',
    resetpassFailed: 'Reset Password Failed',
    resetpassSuccess: 'Reset Password Success',
    signInTitle: 'Welcome',
    signInDesc: 'Please sign in to your account',
    requiredEmailPhone: 'Please input your Email or Phone number!',
    requiredEmail: 'Please input your Email address!',
    formatEmail: 'Your Email address is invalid',
    requiredPassword: 'Please input your Password!',
    minRequiredPassword: 'Password must contain at least 8 characters, one uppercase, one number and one special character.',
    signIn: 'Sign In',
    // eslint-disable-next-line
    dontHaveAccount: `You don't have an account?`,
    register: 'Register',
    registerNow: 'Register Now',
    forgotPassword: 'Forgot Password?',
    resetPass: 'Reset Password',
    resetMyPass: 'Reset my password',
    emailAddress: 'Email Address',
    placeholderEmailAddress: 'Input youe email address',
    goToSignIn: 'Go to sign in',
    newPass: 'New Password',
    confirmNewPass: 'Confirm New Password',
    createAccount: 'Create your account',
    firstName: 'First Name',
    requiredFirstName: 'Please input your first name',
    placeholderFirstName: 'Input your first name',
    lastName: 'Last Name',
    requiredLastName: 'Please input your last name',
    placeholderLastName: 'Input your last name',
    mobileNumberOrEmail: 'Please fill in one (mobile number or email) for account verification',
    disclaimer: 'Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our privacy policy',
    phone_number: 'Phone Number',
    email: 'Email',
    placeholderEmail: 'Input your email',
    password: 'Password',
    placeholderPassword: 'Input your password',
    confirmPassword: 'Confirm Password',
    placeholderConfirmPassword: 'Input Confirm Password',
    toc: 'By signing up, you agree to the',
    and: 'and',
    alreadyHaveAccount: 'Already have an account?',
    registerSuccess:
      'Please check your {type}: {payload} for completing your registration.',
    regConfirmation: 'Register Confirmation',
    storeName: 'Store Name',
    requiredStoreName: 'Please input your store name',
    storeUrl: 'Store Url',
    helpStoreUrl:
      'Please make sure your url store. you cant change store url after register',
    requiredStoreUrl: 'Please input your store URL',
    phoneNumber: 'Phone Number (WhatsApp)',
    requiredPhoneNumber:
      'Please input your phone number (WhatsApp). Use number instead',
    placeholderPhoneNumber: 'Input your phone number (WhatsApp)',
    address: 'Address',
    requiredAddress: 'Please input your address',
    address2: 'Address 2',
    requiredAddress2: 'Please input your address (optional)',
    country: 'Country',
    city: 'City',
    placeCity: 'Select a City',
    area: 'Area',
    placeArea: 'Select a Area',
    zip: 'Zip',
    requiredZip: 'Zip code value must be a number',
    bankInformation: 'Bank Information',
    accountNo: 'Account No.',
    requiredAccountNo: 'Account number value must be a number',
    accountHolder: 'Account Holder Name',
    placeAccountHolder: 'Please input your bank account name',
    bankAccount: 'Bank Account',
    placeBankAcc: 'Select a bank',
    idCard: 'ID Card (KTP/SIM)',
    popUpRemoveImage: 'Are you sure want to remove this image ?',
    removeImage: 'Remove Image',
    toc2: 'I agree with term and condition.',
    submitConRegister: 'Submit',
    registerConfirm: 'Register Completed',
    registerCompleted: 'Please login to continue the process',
    requiredCity: 'Please select a city',
    requiredArea: 'Please select area',
    requiredBankAcc: 'Please select bank account',
    requiredBankAccName: 'Please input your bank account name',
    requiredToc2: 'Please check Term and Condition',
    successUploadImage: 'Success Upload Image',
    failUploadImage: 'Upload image failed',
    insertYourUrlStore: 'Please insert your URL store',
    extraNpwp:
      'If you have a NPWP, the commission you get will be deducted by 5% tax, otherwise 6% will be deducted',
    titleSuccessRegister: 'Congrats, you`r already registered!',
    subTitleSuccessRegister:
      'Please login to your account and shoping',
    addressStoreTitle: 'Address place same as address store',
    haveStore: 'Did you have offline store ?',
    haveOnlineStore: 'Did you have online store ?',
    personalData: 'Personal Data',
    titleConfRegis: 'Complete Data',
    subTitleConfRegis: 'Please complete your data before shopping',
    resetPasswordRequestSuccess:
      'Please check your {type}: {payload}, to complete reset password',
    customerService: 'Customer Service',
    registerForm:
      'Please choose phone number or email for verification account',
  },
  upload: {
    uploadProofPayment: 'Upload Proof Payment',
    changeImage: 'Change Image',
  },
  account: {
    account: 'Profile Account',
    edit: 'Update',
    add: 'Add',
    view: 'View',
    verifier: 'Verifier',
    addPhone: 'Add Phone Number',
    addEmail: 'Add Email',
    editPhone: 'Update Phone Number',
    editEmail: 'Update Email',
    editAccount: 'Edit Profile',
    successEditProfileModalTitle: 'Successfully changed your profile',
    successEditProfileModalBody: 'Please log back in to update your profile',
    successEditProfileModalButton: 'Login Back',
    setAsVerifier: 'Set as default verifier',
    updateBio: 'Update Bio',
    profile: 'Profile',
    address: 'Address',
    storeName: 'Store Name',
    telephone: 'Telephone Number',
    billing: 'Billing',
    retur: 'Retur',
    owner: 'Owner',
    firstName: 'First Name',
    lastName: 'Last Name',
    ktp: 'ID Card (Identity Card Number)',
    npwp: 'NPWP (Taxpayer Registration Number)',
    streetName: 'Street Name',
    rtrw: 'Detail Location',
    requiredStoreName: 'Input name of your store',
    requiredFirstName: 'Input your first name',
    requiredEmail: 'Input your Email address',
    requiredPhone: 'Input your phone or mobile-phone',
    howToChangeEmail: 'Contact us if you want to change Email address',
    howToChangePhone: 'Please contact us if you want to change Phone Number',
    requiredAddress: 'Input your address',
    requiredKTP: 'Input your ID Card number',
    requiredNPWP: 'Input your Taxpayer Registration Number',
    submit: 'Save',
    unverified: 'Unverified',
    verified: 'Verified',
    successUpdateData: 'Success ! Data updated',
    failedUpdateData: 'Sorry! Data failed to update',
    shippingAddress: 'Shipping Address List',
  },
  store: {
    allProduct: 'All Product',
    selectAll: 'Select All',
    addToStore: 'Add To Store',
    removeFromStore: 'Remove from Store',
    searchProduct: 'Search by product title',
    productInStore: 'Product in Store',
    productOutStore: 'Product not in Store',
    productNotFound: 'Product Not Found',
    loadMore: 'Load More',
    alreadyInStore: 'In store',
    price: 'Price for {value} variant',
    commission: 'Commission',
    fotoProfile: 'Store Foto Profile',
    changeImage: ' Change Image',
    addImage: 'Add Image',
    storeName: 'Store Name',
    requiredStoreName: 'Please input your store name',
    notifStoreUrl: 'You cant change url store',
    save: 'Save',
    selectedBanner: 'Selected Banner:',
    availableBanner: 'Available Banner:',
    profileSetting: 'Store Profile Settings',
    bannerSetting: 'Banner Settings',
    storeUrl: 'Store Url',
    successAddProduct: 'Add product to store success',
    failedAddProduct: 'Remove product from store success',
    successRemoveProduct: 'Remove product from store success',
    failedRemoveProduct: 'Remove product from store failed',
    successEditStore: 'Edit store profile success',
    failedEditStore: 'Edit store profile failed',
    requiredStoreFoto: 'Please input your store foto',
    outOfStock: 'Out of Stock',
    shareThisProduct: 'Share this Product',
    shareProduct: 'Share Product {value}',
    extraDefaultMessage:
      'if you dont create a message, we will create a default message.',
    placeholderDefaultMessage: 'Set your message (optional)',
    shareWith: 'Share with',
    changeDefaultMessage:
      '*You can change the default message. create your own message below',
    stock: 'Stock',
    updateStock: 'Update stock',
    color: 'Color',
    size: 'Size',
    flavour: 'Flavour',
    saveChanges: 'Save changes',
    changeStockSuccess: 'Stock successfully updated',
  },
  verify: {
    titleVerify: 'Silahkan pilih metode verifiksi',
    withEmail: 'Using Email',
    withPhone: 'Using Handphone (WhatsApp)',
    emailSentTitle: 'Thank You',
    emailSentSubTitle:
      'Please check your email to verify and complete your registration process',
    emailSentButton: 'Back to home',
    otpTitle: 'Please verify your details',
    otpSubTitle: 'Insert the OTP that we sent to your WhatsApp',
    otpButton: 'Verify',
    otpWaitingText: 'Please waiting before resend the OTP',
    otpResend: 'Resend',
  },
  comingSoon: {
    comingSoon: 'COMING SOON !',
    featureIsNotYet: 'Currently the Coin exchange feature is not yet available',
    backToHome: 'Back To Home',
  },
  footer: {
    contact: 'Kontak',
    about: 'Tentang',
    tentang_kino: 'Tentang',
    product_category: 'Kategori Produk',
    social_media: 'Sosial Media',
    download_app: 'Download App',
  },
  customerService: {
    copyEmailMessage: 'Email copied!',
    copyPhoneMessage: 'Phone Number copied!',
    copyEmail: 'Copy Email Address',
    copyPhone: 'Copy Phone Number',
    customerServiceInfo:
      'If you need assistance, please contact CS at the mobile number or email address listed.',
    back: 'Back',
  },
  expandButtonCaption: 'Show More',
  lessButtonCaption: 'Show Less',
  menu: {
    loyalty: {
      title: 'Coin',
      catalog: 'Catalogue Coin',
      cart: 'Cart Coin',
      purchase: 'Purchase Coin',
      transaction: 'Transaction Coin',
    },
    purchase: {
      title: 'Purchase',
      catalog: 'Catalogue Product',
      transaction: 'Transaction',
      noTransactionsYet: 'You have no transactions yet. Come on, start shopping at!',
      shopNow: 'Shop Now',
      tabs: {
        all: 'All',
        waiting_confirmation: 'Waiting for Confirmation',
        waiting_confirmation_canceled: 'Waiting for Cancel Confirmation',
        on_process: 'In Process',
        shipping: 'Delivery',
        completed: 'Completed',
        canceled: 'Canceled',
      },
      cart: {
        delete_product: 'Delete Product',
        cannot_be_process: 'Cannot Be Process',
        update_stock: 'Update Stock',
        stock_empty: 'Stock Empty',
        product_left: 'Product Left',
        total_price: 'Total Price',
        total_coin: 'Total Coin',
      },
      cash_delivery: {
        shopping_now: 'Belanja Sekarang',
        empty_transaction: "You Don't Have Transaction",
        order_number: 'Order Number',
        pay_before: 'Pay Before',
        total_payment: 'Total Payment',
        how_to_pay: 'How To Pay',
        back: 'Kembali',
      },
      checkout: {
        order_failed: 'Order Failed',
        back_to_cart: 'Back to cart',
        auto_delete: '*Product will automaticly deleted from cart',
      },
      detail: {
        make_sure_you_received_your_order: 'Make sure you received your order',
        already_received: 'Already Received',
        not_received_yet: 'Not Received Yet',
        cancellation_reason: 'Cancellation Reason',
        confirmation: 'Confirmation',
        bill_with_credit: 'The bill with payment due on this order is',
        pay_before_date: 'Please pay off before the date',
        see_invoice: 'See Invoice',
        see_detail: 'See Details',
        cancelled: 'Cancelled',
        max_file_size: 'Maximum file size 2MB with jpg, png . format',
        order_received: 'Order Received',
        cancel_order: 'Cancel Order',
        back: 'Back',
      },
      how_to_pay: {
        transaction_list: 'Transaction List',
        status_delivery: 'View Order Status',
      },
      payment: {
        payment_method: 'Payment Method',
        end_of_payment: 'Your payment will end on',
        va_no: 'Virtual Account Number',
        copy: 'copy',
        total_payment: 'Total Payment',
        view_payment_detail: 'View Payment Detail',
        payment_instruction: 'Payment Instruction',
      },
      waiting_payment: {
        title: 'Waiting Payment',
        dont_have_transaction: 'You don"t have transaction yet',
        shopping_now: 'Shopping Now',
        detail_transaction: 'Detail Transaction',
      },
    },
    profile: {
      retur: {
        title: 'Retur',
        deposit: {
          title: 'Deposit Return',
          history: 'History',
          date_range: 'Time Peroid',
          history_empty_info: 'Data empty, please select the date',
          deposit_return: 'Deposit Return',
          deposit_bill: 'Bill Return',
          close: 'Close',
        },
      },
    },
    about: {
      title: 'About',
      paragraphs: {
        one: 'is a web-based and mobile-based e-commerce, owned by Indonesia, which provides various conveniences for customers to make a transaction anytime and anywhere.',
        two: 'Along with Indonesia"s growth in global e-commerce retail, presents a safe, easy and practical shopping experience. Through, you will be able to easily order all products without having to wait for a Salesman to visit and get various information about ongoing promotional programs.',
        three: 'Enjoy the benefits we offer through, such as:',
        four: 'Loyalty Point',
        five: 'Loyalty points are special rewards that you can get for each of your total transactions, which calculation is transparent.',
        six: 'Redeem Point',
        seven: 'Redeem your points and enjoy the prizes we have provided.',
        eight: 'Returns',
        nine: 'If you have complaints about the products you ordered, you may easily submit a request to return your products and track the progress through this application (terms and conditions apply).',
        ten: 'Shipment',
        eleven: 'provides premium service by prioritizing a faster delivery, which is a maximum of 3 days after the order is submitted. You can also track your orders through',
        twelve: 'Your shopping convenience is our top priority. By registering and transacting through, you will experience a way of shopping as you were with our Salesman, earn a fairly high profit, and get priority in the order and delivery process.',
        thirteen: 'does not charge any fees in every transaction process, including loyalty programs or promotional programs. Beware of fraud in the name of.',
        fourteen: 'For more information on using this application, you may contact your Salesman or your Distributor, or you may also reach Customer Service',
        fifteen: 'Let"s shop with, enjoy the convenience, and get ready to get a lot of benefits!',
      },
    },
    privacy_policy: {
      title: 'Privacy Policy',
      paragraphs: {
        one: 'PT Indonesia Tbk builds and maintains your trust, and we are cautious about your personal data. We ensure and are very careful with any information about you that we obtain, including policies regarding what information we collect, how we use and protect that information and how you can determine what we can do with your information. We want to help you understand how we use your information to improve our content, products and services. Therefore, we have compiled this Customer Privacy Policy to address these concerns.',
        two: 'By using or providing your information to the Group (company), you consent to the collection, use and sharing of your information based on this policy.',
        three: 'Please take a moment to read this Privacy Policy carefully and contact us if you have any questions. When you read our Privacy Policy, please note that this applies to all companies under Group. We may update this policy occasionally, so always check our Privacy Policy first.',
        four: 'Personal information is information or a combination of information that can be used to identify you individually.',
        five: 'Personal Information Collected',
        six: 'Information you provide to us.',
        seven: 'Information we receive when you contact us, visit our sites and products or view our  advertisements.',
        eight: 'Information we receive from other companies with your consent.',
        nine: 'Information we receive from other companies when you visit their websites.',
        ten: 'We may collect and manage each information you provide, including your personal data at any time. We may combine some or all of this information to create better products and services, improve the overall accuracy of your information so we can have better interaction with you and manage your use of our website through technology. We may also collect other related information such as our site accessed pages, the number of people accessing our site, the type of web browser used, the name of your internet service provider, and so on. By storing this information, we can learn how to customize our site for visitors.',
        eleven: 'You are required to ensure the authenticity and accuracy of the information listed as a term for accessing our website pages. Therefore, you agreed not to provide false information, and we reserve the right to verify the validity of all the information you provide.',
        twelve: 'Use/Sharing of Personal Information',
        thirteen: 'We do not sell or disclose your personal information to marketers outside Group. We share the information you provided on the website with selected companies that help us run our business, including those providing our website hosting services. They are responsible for protecting your information in the same way we do and will not share or use it for any other purpose for any reason',
        fourteen: 'We are able to retain, use and manage your personal data, although the user"s permit has been withdrawn or expired. The selected companies are bounded by contract to use or disclose information unless necessary to perform services on our behalf or to comply with legal requirements;',
        fifteen: '(i) To comply with a legal obligation;',
        sixteen: '(ii) If we are required by law or by legal process;',
        seventeen: '(iii) At the request of law enforcers or government authorities investigating an alleged or illegal activity;',
        eighteen: '(iv) To identify, verify, perform due diligence or know the purpose of your customers;',
        nineteen: '(v) To evaluate and make decisions regarding your credit;',
        twenty: '(vi) To respond to emergencies;',
        twenty_one: '(vii) To protect the rights, property, safety or security of third parties, visitors to the website or the public.',
        twenty_two: 'Children"s Safety',
        twenty_three: 'This website is not intended for individuals under 18 years old (or the applicable legal age for consuming/using the product in question). If you are under 18 years old, we urge you to involve your parent or guardian in sending your personal data to us. By providing your personal data to us, you confirm that you are at least 18 years old or over and agree to the established Privacy Policy.',
        twenty_four: 'Changes to our Privacy Policy',
        twenty_five: 'We may update this policy from time to time, so always check our Privacy Policy beforehand as it is very binding.',
        twenty_six: 'Security of Information',
        twenty_seven: 'We apply regulated steps in order to ensure the security of information that we have collected and managed, and your personal data can only be accessed by a small group of employees with importance. We will save your data accordingly under data privacy protection law, and your information will be put anonymously if considered unneeded. However, we are not liable to guarantee infallible security.',
        twenty_eight: 'Third Parties Providing Support Services',
        twenty_nine: 'During the course of our business activities, we may engage other companies and individuals to perform various tasks on our behalf. For example, we engage representative agents to help manage customer promotions, analyze data and provide customer service. These representatives have access to personal information if needed to perform their duties for Group. However, they are prohibited from using the information for any other purpose, and they are asked to keep the information confidential.',
        thirty: 'Links with Other Sites',
        thirty_one: 'Group makes every effort to ensure that every link on our website will send you to a useful place on the internet. However, websites and addresses change rapidly, and Group cannot guarantee the content of each site to which the links we provide. Apart from that, other websites have their own privacy policies. You should always look at the privacy policies of the specific sites you visit to find out your rights.',
        thirty_two: 'List of Entries and Surveys',
        thirty_three: 'At any time, we may ask visitors to complete surveys and polls about their attitudes and preference. Surveys help us to understand your needs. If sample answers to the survey are entered on the website, no information for personal access will be entered. If you take part in one of our surveys, we can assure you that we do not store any information for personal access about you from that survey in our database.',
        thirty_four: 'Our Contact',
        thirty_five: 'Please contact us if you have any questions or concerns regarding your security.',
      },
    },
    terms_and_conditions: {
      title: 'Terms & Conditions',
      paragraphs: {
        one: 'Terms of Use',
        two: 'You may use this site if you agree to all of the Terms and Conditions along with all changes and/or additions that may be made later. By using and accessing this site, you are deemed to have read, understood and agreed to all of the Terms and Conditions. We may change and/or add to these Terms and Conditions at any time without any obligation to notify you of the changes we have made. Therefore, you are always expected to read the Terms and Conditions every time you access this site.',
        three: 'Copyright and Intellectual Property',
        four: 'Copyright © PT Indonesia Tbk. All copyright and/or intellectual property in this site, including but not limited to texts, images, sounds, logos, designs, graphics, illustrations, videos, symbols, software, and other materials in this website are property of PT Indonesia Tbk and/or its affiliation or including those licensed from the respective owners. All copyright and intellectual property on this site are protected according to the applicable laws and regulations.',
        five: 'Restrictions on Use of Websites and Materials',
        six: 'You are allowed to access, print, download, copy content, and/or distribute it to other people for non-commercial purposes only, limited to providing information and for personal purposes only. Any other use of material on this website, including but not limited to modification, reproduction, distribution, republication, display or transmission of the contents of this website, without any written permission of PT Indonesia Tbk is expressly prohibited.',
        seven: 'We are not responsible for any loss, damage or expense arising from access or use of this website or any links contained therein, not limited to any loss of profit, indirect, incidental or consequential damages.',
        eight: 'Content and Links',
        nine: 'The information provided on this website is for general information purposes only. We do not recommend that it be used as a reference for any particular purpose. No representation or warranty is given as to the accuracy or completeness of the content. You consent to accessing and using the website and any content at your own risk.',
        ten: 'This website may contain links to other sites provided by third parties (not affiliated with PT Indonesia, Tbk). Although PT Indonesia, Tbk refers you to visit third party sites, PT Indonesia, Tbk has no responsibility or relationship with the information or policies contained on these third-party sites or their owners. These linked sites are for your convenience only and you will therefore be able to access them at your own risk.',
        eleven: 'Personal Data',
        twelve: 'We may collect your personal data information, if you: (I) put your email address in the fields provided; and/or (II) contact us via Call Center services.',
        thirteen: 'For more information regarding the collection and management of your personal data, you can read it here.',
        fourteen: 'Product Delivery',
        fifteen: 'For online purchases through, orders and deliveries will be process after user to do payment process, either via Bank Transfer or Virtual Account. The delivery time is adjusted to the service area of ​​the destination area.',
        sixteen: 'The User will receive an in-app notification regarding the delivery status at any time after the User"s ordered product is shipped',
        seventeen: 'Shipping rates are fully borne by the Distributor',
        eighteen: 'If the items you ordered are damaged or wrong when you receive the the items, then do the next step in the "Returns and Refunds" section',
        nineteen: 'Returns and Refunds',
        twenty: 'Users can make returns, with the following conditions:',
        twenty_one: 'Product is almost expired',
        twenty_two: 'The product received incorrect with your order;',
        twenty_three: 'There is damage product received, including but not limited to:',
        twenty_four: 'The number of products received less than the number of products ordered through the application;',
        twenty_five: 'Product is broken or damaged upon receipt;',
        twenty_six: 'The content of the product volume does not match with your order;',
        twenty_seven: 'Users can process returns by submitting a complaint via the email. The information that must be provided by the relevant User is that the User must (i) request a product photo; (address number, including photo of expiration date, (iii) order and account name, including information for sending new goods; (iv) and other related matters.',
        twenty_eight: 'The Distributor will submit a return request, and if approved, the Distributor will take the goods according to the Distributor"s delivery schedule',
        twenty_nine: 'Refunds can be made in an estimated 5-7 working days.',
        thirty: 'We reserve the right not to continue the process of customizing the goods if the User does not meet these Terms and Conditions.',
        thirty_one: 'Applicable Law',
        thirty_two: 'This Terms & Conditions are made and therefore subject to the laws in force in the territory of the Republic of Indonesia.',
        thirty_three: 'Questions and Feedback',
        thirty_four: 'We welcome all opinions, questions, comments, criticisms, and suggestions from you regarding the information on this site. Please feel free to send us your feedback and/or questions.',
        thirty_five: 'Reference',
        thirty_six: 'References to affiliates or affiliated or affiliated companies include all companies.',
        thirty_seven: '',
        thirty_eight: 'PT Indonesia, Tbk',
      },
    },
    point: {
      cart: {
        title: 'CART',
      },
      purchase: {
        title: 'PURCHASE',
      },
      purchase_transaction: {
        title: 'PURCHASE TRANSACTION',
      },
    },
    order: {
      detail: {
        change_status: 'Change Status',
      },
      send: 'Send',
      print_label: 'Print Label',
    },
    retur: {
      title: 'Return',
      list: {
        return_balance: 'Return Balance',
        close: 'Close',
        return_request: 'Make a Return Request',
        return_empty: 'You don"t have a return request list.',
        return_empty_with_filter: 'No transactions match the filter',
        estimated_refund: 'Estimated Refund',
        order_receive: 'Order Receive',
        waiting_verification: 'Waiting Verification',
        return_request_detail: 'Return Request Details',
        detail_request_retur: 'Detail Return Request',
        status_retur: 'Status Return',
        number_retur: 'Retur Number',
        request_date: 'Date Requested',
        delete_success_info: 'Draft request return has been deleted.',
      },
    },
  },
  common: {
    copy: 'Copy',
    day: 'Day',
    month: 'Month',
    year: 'Year',
    'Month(s)': 'Month',
    'Year(s)': 'Year',
    'Day(s)': 'Day',
  },
  components: {
    countdown: {
      time_remaining: 'Time remaining',
      hour: 'Hour',
      minutes: 'Minutes',
      seconds: 'Seconds',
    },
  },
  error: {
    DOR: {
      CCT: {
        OR_ITEM_IS_NOT_FOUND_ON_CART: 'There is no items found on your cart',
        OR_PO_NUMBER_ALREADY_EXISTS:
          'PO Number already exists, please try again or choose another PO number',
      },
      ASO: {
        OR_CANNOT_REJECT_CANCELLATION_REQUEST:
          'Cannot reject cancellation request',
        OR_SUCCESSFULLY_REJECT_CANCEL_REQUESTED:
          'Successfully reject cancel request',
        OR_ORDER_CANNOT_BE_CANCELED: 'Order cannot be canceled',
        OR_SUCCESSFULLY_CANCEL_ORDER: 'Successfully cancel order',
        OR_PLEASE_FILL_ORDER_ID_OR_ORDER_NUMBER:
          'Please fill order ID or order number',
        OR_UNREGISTERED_STATE: 'Unregistered state',
        OR_SUCCESSFULLY_UPDATED_STATE: 'Successfully updated state',
        OR_MISSING_REQUIRED_PARAMETER: 'Missing required parameter',
        OR_INVALID_ORDER_STATE: 'Invalid order state',
        OR_ORDER_ID_NOT_FOUND: 'Order ID not found',
        OR_ALREADY_CANCELLED: 'Order already cancelled',
        OR_INVALID_CHANNEL_ID: 'Invalid channel ID',
      },
    },
    DPC: {},
    DPY: {},
    DLY: {},
    DIV: {
      BIV: {
        IV_ITEM_IS_OUT_OF_STOCK: 'This Item is out of stock',
      },
    },
  },
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
