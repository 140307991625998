import { apiClientDistributorship } from '@/services/axios'
import { types } from './mutations'

export const GETLOYALTY = async (
  { commit, dispatch, rootState },
  { channel_id, id, level_modal_id },
) => {
  let data
  try {
    commit(types.SET_LOYALTY_STORE, {
      loading: true,
    })
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: '/loyalty/query/me',
    })
    data = response
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOYALTY_STORE, {
      loading: false,
      totalKinoCoin: data?.data,
    })
  }
}

export const GETLOYALTYACTIVE = async ({ commit }) => {
  let resData = null
  try {
    commit(types.SET_LOYALTY_STORE, {
      loading: true,
      statusLoyalty: null,
    })
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: '/loyalty/query/active',
    })
    // console.log('Potensial', response)
    resData = response
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOYALTY_STORE, {
      loading: false,
      statusLoyalty: resData?.data || null,
    })
  }
}
