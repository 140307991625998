<template>
  <a-dropdown :arrow="true" :trigger="settings.isMobileView ? ['click'] : ['hover']" placement="bottomRight"
    @visibleChange="addCount" :destroyPopupOnHide="true">
    <div :class="`${$style.dropdownUser} p-0`">
      <div :class="$style.iconMenu">
        {{ account.detail_user?.user &&
    `${account.detail_user.user?.first_name?.charAt(0).toUpperCase()}${account.detail_user.user?.last_name?.charAt(0).toUpperCase()}`
        }}
      </div>
      <div :class="$style.title">{{ getNickname }}</div>
      <em :class="`${$style.iconAngleDown} ${['fa fa-angle-down']}`"></em>
    </div>
    <a-menu slot="overlay" style="width: 240px;" mode="inline">
      <a-menu-item class="px-3 py-1">
        <router-link to="/account/profile" @click="() => $emit('handleDrawer', false)">
          <div class="d-flex align-items-center" style="gap: 0px 5px;">
            <div class="icon-user">
              {{ account.detail_user?.user &&
    `${account.detail_user.user?.first_name?.charAt(0).toUpperCase()}${account.detail_user.user?.last_name?.charAt(0).toUpperCase()}`
              }}
            </div>
            <div>
              <strong class="text-name mr-2">{{ account.detail_user?.user && `${account.detail_user.user?.first_name}
                ${account.detail_user.user?.last_name}` || '-' }}</strong>
            </div>
          </div>
        </router-link>
      </a-menu-item>
      <a-divider class="my-2" />
      <a-menu-item class="px-3 py-2 menu-text">
        <router-link to="/account/profile" @click="() => $emit('handleDrawer', false)">
          Akun
        </router-link>
      </a-menu-item>
      <a-menu-item class="px-3 py-2 menu-text">
        <router-link to="/purchase" @click="() => $emit('handleDrawer', false)">
          Transaksi
        </router-link>
      </a-menu-item>
      <a-sub-menu v-if="$store.state.app.redeem_channel_id" class="px-1" key="sub1" title="Loyalty Redeem">
        <a-menu-item class="px-3 py-2 menu-text">
          <router-link to="/loyalty-redeem/catalogue" @click="() => $emit('handleDrawer', false)">
            Katalog Loyalty Redeem
          </router-link>
        </a-menu-item>
        <a-menu-item class="px-3 py-2 menu-text">
          <router-link to="/loyalty-redeem/cart" @click="() => $emit('handleDrawer', false)">
            <span>Keranjang Loyalty Redeem</span><span class="ml-3 cart-tag py-0 px-1 rounded">{{ totalCartLoyalty
              }}</span>
          </router-link>
        </a-menu-item>
        <a-menu-item class="px-3 py-2 menu-text">
          <router-link to="/loyalty-redeem/purchase" @click="() => $emit('handleDrawer', false)">
            Transaksi Loyalty Redeem
          </router-link>
        </a-menu-item>
      </a-sub-menu>
      <a-menu-item :key="key" class="px-3 py-2 menu-text"
        v-for="({ title, url }, key) in ($store.state.app.additional_menu_access || [])">
        <router-link :to="url" @click="() => $emit('handleDrawer', false)">
          {{ title }}
        </router-link>
      </a-menu-item>
      <a-menu-item class="logout px-3 py-2 menu-text">
        <a href="javascript: void(0);" @click="logout">
          {{ $t('topBar.logout') }}
        </a>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    visible: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapState(['user', 'settings', 'purchase']),
    loading() {
      return this.$store.state.user.loading
    },
    ...mapState(['account'], {
      detail_user: state => state.detail_user,
    }),
    getNickname() {
      const nickname = this.account.detail_user?.user ? `${this.account.detail_user.user?.first_name}
        ${this.account.detail_user.user?.last_name}` : '-'
      if (nickname.length > 25) {
        return `${nickname.substring(0, 25)}...`
      }
      return nickname
    },
  },
  watch: {
    'purchase.totalCartLoyalty': {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.totalCartLoyalty = value
      },
    },
  },
  data: function () {
    return {
      count: 0,
      totalCartLoyalty: 0,
    }
  },
  mounted() {
    this.fetchDetailUser()
    this.fetchDataApp()
    this.fetchDetailCustomer()
  },
  methods: {
    fetchDataApp() {
      this.$store.dispatch('GETDATAADMIN')
        .then(({ data }) => {
          // console.log(data)
        })
      // .catch(err => {
      //   console.log(err)
      // })
    },
    fetchDetailUser() {
      this.$store.dispatch('account/GETDETAILUSER', {
        buyer_id: this.$store.state.user.user_data.buyer_id,
      })
        .then((data) => {
        })
      // .catch(err => {
      //   console.log(err)
      // })
    },
    fetchDetailCustomer() {
      this.$store.dispatch('account/GETDETAILCUSTOMERBYID', {
        buyer_id: this.$store.state.user.user_data.buyer_id,
      })
        .then((data) => {
        })
      // .catch(err => {
      //   console.log(err)
      // })
    },
    addCount() {
      this.count++
    },
    logout() {
      this.$store.dispatch('user/LOGOUT')
    },
  },
}
</script>

<style lang="scss" module>
@import "../style.module.scss";
</style>
<style lang="scss">
.logout {
  background-color: none;
}

.logout :hover {
  background-color: #ED1C24 !important;
}

.icon-user {
  border-radius: 100%;
  width: 38px;
  height: 38px;
  background-color: #EC1C231A;
  color: #AD2E35;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
}

.menu-text {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #707070;
}

.text-title {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.text-name {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.cart-tag {
  background-color: var(--biz-brand-button);
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  color: #FFFFFF;
  line-height: 18px !important;
}
</style>
