import * as MutationTypes from './mutation-types'

export default {
  [MutationTypes.SET_CHANNELS]: (state, payload) => {
    state.list = payload
  },
  [MutationTypes.SET_PRICE_LEVEL_DEFAULT_VALUE]: (state, payload) => {
    state.priceLevels = payload
  },
}
