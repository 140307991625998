import { apiClientDistributorship, apiGuestDistributorship, apiMeta } from '@/services/axios'
import { types } from '@/store/purchase/mutations'
import { getSessionId } from '@/utils/general'

export const GETPAYMENTMETHOD = async ({ commit, rootGetters }, { channel_id, seller_id }) => {
  const isGuest = rootGetters['user/isGuest']
  const url = isGuest ? `/payment_provider/query/method/${channel_id}/${seller_id}/default` : `/payment_provider/query/method/${channel_id}/${seller_id}`
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: url,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const GETSHIPPINGMETHOD = async ({ commit }, { channel_id, warehouse_id, params }) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/shipping/cmd/courier/rates/list/${channel_id}/${warehouse_id}`,
      params,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const POSTSHIPPINGMETHOD = async ({ commit }, { payload }) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'post',
      url: '/shipping/cmd/shipping_method',
      data: {
        ...payload,
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const GETCART = async ({ commit, dispatch, rootState }, { channel_id }) => {
  try {
    commit(types.SET_LOADING, true)

    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/order/query/${channel_id}/cart/buyer`,
    })

    commit(types.SET_TOTAL_CART, response.data.cart_list.length)
    return Promise.resolve(response)
  } catch (error) {
    commit(types.SET_LOADING, false)
    commit(types.SET_TOTAL_CART, 0)
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const GETCARTGUEST = async ({ commit, dispatch, rootState }, { channel_id }) => {
  try {
    commit(types.SET_LOADING, true)

    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/order/query/guest/${channel_id}/cart/${getSessionId()}`,
    })

    commit(types.SET_TOTAL_CART, response.data.cart_list.length)
    return Promise.resolve(response)
  } catch (error) {
    commit(types.SET_LOADING, false)
    commit(types.SET_TOTAL_CART, 0)
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const GETCARTLOYALTY = async ({ commit, dispatch, rootState }, { channel_id }) => {
  try {
    commit(types.SET_LOADING, true)

    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/order/query/${channel_id}/cart/buyer`,
    })

    commit(types.SET_TOTAL_CART_LOYALTY, response.data.cart_list.length)
    return Promise.resolve(response)
  } catch (error) {
    commit(types.SET_LOADING, false)
    commit(types.SET_TOTAL_CART, 0)
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const ADDTOCART = async ({ commit, dispatch, rootState }, {
  channel_id,
  cart_id,
  item_id,
  product_id,
  catalog_sku,
  catalog_title,
  showimg_url,
  qty,
  uom,
  buyer_id,
  area_id,
  price_id,
  item_price,
  currency,
  // hardcode warehouse_id -> issue gk dapet warehouse_id dr list product di product unggulan (dashboard)
  warehouse_id = '58f96cf1-34e3-4bfe-bbef-286b4cfa5026',
  category_id,
}) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'post',
      url: `/order/cmd/${channel_id}/cart`,
      data: {
        cart_id,
        item_id,
        product_id,
        catalog_sku,
        catalog_title,
        showimg_url,
        qty,
        uom,
        buyer_id,
        area_id,
        price_id,
        item_price,
        currency,
        warehouse_id,
        category_id,
      },
    })

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const ADDTOCARTGUEST = async ({ commit, dispatch, rootState }, {
  channel_id,
  cart_id,
  item_id,
  product_id,
  catalog_sku,
  catalog_title,
  showimg_url,
  qty,
  uom,
  buyer_id,
  area_id,
  price_id,
  item_price,
  currency,
  // hardcode warehouse_id -> issue gk dapet warehouse_id dr list product di product unggulan (dashboard)
  warehouse_id = '58f96cf1-34e3-4bfe-bbef-286b4cfa5026',
  category_id,
}) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'post',
      url: `/order/cmd/guest/${channel_id}/cart`,
      data: {
        cart_id,
        item_id,
        product_id,
        catalog_sku,
        catalog_title,
        showimg_url,
        qty,
        uom,
        buyer_id,
        area_id,
        price_id,
        item_price,
        currency,
        warehouse_id,
        category_id,
        client_token: getSessionId(),
      },
    })

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const UPDATECART = async ({ commit, dispatch, rootState }, {
  cart_item_id,
  new_qty,
  channel_id,
  cart_id,
}) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'put',
      url: `/order/cmd/${channel_id}/cart/${cart_id}`,
      data: {
        cart_item_id,
        new_qty,
      },
    })

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const DELETECART = async ({ commit, dispatch, rootState }, {
  channel_id,
  cart_item_id,
  cart_id,
}) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'delete',
      url: `/order/cmd/${channel_id}/cart/${cart_id}`,
      data: {
        cart_item_id,
        cart_id,
      },
    })

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const GETSHIPPINGADDRESS = async ({ commit, dispatch, rootState }, { channel_id = 154, addr_key }) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/shipping/query/${channel_id}`,
      params: {
        addr_key,
      },
    })

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const GETSHIPPINGADDRESSBYID = async ({ commit, dispatch, rootState }, { channel_id = 154, addr_key }) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/shipping/query/contact/${channel_id}/${addr_key}`,
    })

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

// export const GETPAYMENTMETHOD = async ({ commit, dispatch, rootState }, { channel_id = 154, addr_key }) => {
//   try {
//     commit(types.SET_LOADING, true)
//     const { data: response } = await apiClientDistributorship({
//       method: 'get',
//       url: `/shipping/query/${channel_id}`,
//       params: {
//         addr_key,
//       },
//     })

//     return Promise.resolve(response)
//   } catch (error) {
//     return Promise.reject(error)
//   } finally {
//     commit(types.SET_LOADING, false)
//   }
// }

export const GETBILLINGADDRESS = async ({ commit, dispatch, rootState }, { channel_id = 154, addr_key }) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/billing/query/${channel_id}`,
      params: {
        addr_key,
      },
    })

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const CHECKOUT = async ({ commit, dispatch, rootState }, {
  customer_data,
  transaction_data,
  billing_data,
  destination_data,
  shipping_data,
  promotions,
  promotion_items,
  payment,
  cart_id,
  warehouse_id,
  seller_id,
  po_number,
  is_self_pickup,
  carts,
  channel_id,
  pg_channel_id,
  guest_buyer_id,
  dropshipper,
}) => {
  try {
    commit(types.SET_LOADING, true)
    if (guest_buyer_id) {
      const { data: response } = await apiGuestDistributorship({
        method: 'post',
        url: `/order/cmd/${channel_id}/checkout`,
        headers: {
          'Buyer-Id': guest_buyer_id,
        },
        data: {
          customer_data,
          transaction_data,
          billing_data,
          destination_data,
          shipping_data,
          promotions,
          promotion_items,
          payment,
          cart_id,
          warehouse_id,
          seller_id,
          po_number,
          is_self_pickup,
          carts,
          pg_channel_id,
          dropshipper,
        },
      })
      return Promise.resolve(response)
    } else {
      const { data: response } = await apiClientDistributorship({
        method: 'post',
        url: `/order/cmd/${channel_id}/checkout`,
        data: {
          customer_data,
          transaction_data,
          billing_data,
          destination_data,
          shipping_data,
          promotions,
          promotion_items,
          payment,
          cart_id,
          warehouse_id,
          seller_id,
          po_number,
          is_self_pickup,
          carts,
          pg_channel_id,
          dropshipper,
        },
      })
      return Promise.resolve(response)
    }
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const ADDSHIPPINGADDRESS = async ({ commit, dispatch, rootState }, {
  channel_id,
  first_name,
  last_name,
  phone,
  address_line1,
  address_line2,
  area_id,
  district,
  sub_district,
  city_id,
  city,
  province,
  postal_code,
  country_id,
  country,
  guest_buyer_id,
  email,
}) => {
  try {
    commit(types.SET_LOADING, true)
    if (guest_buyer_id) {
      const { data: response } = await apiGuestDistributorship({
        method: 'post',
        url: `/shipping/cmd/contact/${channel_id}`,
        headers: {
          'Buyer-Id': guest_buyer_id,
        },
        data: {
          first_name,
          last_name,
          email,
          phone,
          address_line1,
          address_line2,
          area_id,
          district,
          sub_district,
          city_id,
          city,
          province,
          postal_code,
          country_id,
          country,
        },
      })
      return Promise.resolve(response)
    } else {
      const { data: response } = await apiClientDistributorship({
        method: 'post',
        url: `/shipping/cmd/contact/${channel_id}`,
        data: {
          first_name,
          last_name,
          email,
          phone,
          address_line1,
          address_line2,
          area_id,
          district,
          sub_district,
          city_id,
          city,
          province,
          postal_code,
          country_id,
          country,
        },
      })
      return Promise.resolve(response)
    }
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const EDITSHIPPINGADDRESS = async ({ commit, dispatch, rootState }, {
  id,
  channel_id,
  first_name,
  last_name,
  phone,
  address_line1,
  address_line2,
  area_id,
  district,
  sub_district,
  city_id,
  city,
  province,
  postal_code,
  country_id,
  country,
}) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'post',
      url: `/shipping/cmd/contact/${channel_id}`,
      data: {
        id,
        first_name,
        last_name,
        phone,
        address_line1,
        address_line2,
        area_id,
        district,
        sub_district,
        city_id,
        city,
        province,
        postal_code,
        country_id,
        country,
      },
    })

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const ADDBILLINGADDRESS = async ({ commit, dispatch, rootState }, {
  channel_id,
  first_name,
  last_name,
  phone,
  email,
  address_line1,
  address_line2,
  area_id,
  district,
  sub_district,
  city_id,
  city,
  province,
  postal_code,
  country_id,
  country,
  guest_buyer_id,
}) => {
  try {
    commit(types.SET_LOADING, true)
    if (guest_buyer_id) {
      const { data: response } = await apiGuestDistributorship({
        method: 'post',
        url: `/billing/cmd/contact/${channel_id}`,
        headers: {
          'Buyer-Id': guest_buyer_id,
        },
        data: {
          first_name,
          last_name,
          phone,
          email,
          address_line1,
          address_line2,
          area_id,
          district,
          sub_district,
          city_id,
          city,
          province,
          postal_code,
          country_id,
          country,
        },
      })
      return Promise.resolve(response)
    } else {
      const { data: response } = await apiClientDistributorship({
        method: 'post',
        url: `/billing/cmd/contact/${channel_id}`,
        data: {
          first_name,
          last_name,
          phone,
          email,
          address_line1,
          address_line2,
          area_id,
          district,
          sub_district,
          city_id,
          city,
          province,
          postal_code,
          country_id,
          country,
        },
      })
      return Promise.resolve(response)
    }
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const DELSHIPPINGADDRESS = async ({ commit, dispatch, rootState }, {
  id,
  channel_id,
}) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'delete',
      url: `/shipping/cmd/contact/${channel_id}/${id}`,
    })

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const GETPURCHASEORDER = async ({ commit, dispatch, rootState }, {
  channel_id = 154,
  page,
  limit,
  q,
  from_date,
  to_date,
}) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/order/query/${channel_id}/list`,
      params: {
        page,
        limit,
        q,
        from_date,
        to_date,
      },
    })

    // console.log(response, 'ini respon GETPURCHASEORDER')
    return Promise.resolve(response)
  } catch (error) {
    return Promise.resolve(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const GETALLPURCHASEORDER = async ({ commit }, {
  page,
  limit,
  channel_id = 154,
  status,
  shipping_states,
}) => {
  try {
    commit(types.SET_LOADING, true)

    const query_shipping_states = shipping_states.length > 0 ? `&shipping_states=${shipping_states.toString()}` : ''
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/order/query/${channel_id}/list?status=${status}${query_shipping_states}&page=${page}&limit=${limit}`,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.resolve(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const GETWAITINGPAYMENT = async ({ commit, dispatch, rootState }, { channel_id, buyer_id, page }) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/payment/query/waiting/${channel_id}?page=${page}`,
      headers: {
        'Customer-Id': buyer_id,
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.resolve(error)
  }
}

export const GETDETAIL = async ({ commit, dispatch, rootState }, { order_id, channel_id }) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/order/query/${channel_id}/details/${order_id}`,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const GETINVOICEDETAIL = async ({ commit, dispatch, rootState }, { order_id }) => {
  try {
    commit(types.SET_LOADING, true)

    const { data: response } = await apiMeta({
      method: 'get',
      url: `/invoice/query/${order_id}`,
    })

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const GETINVOICEDETAILNOAUTH = async ({ commit, dispatch, rootState }, { token, order_id }) => {
  try {
    commit(types.SET_LOADING, true)

    const { data: response } = await apiMeta({
      method: 'get',
      url: `/invoice/query/${order_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const GETDETAILORDERNOAUTH = async ({ commit, dispatch, rootState }, { order_id, channel_id, token }) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/order/query/${channel_id}/details/${order_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const GETDETAILPAYMENTNOAUTH = async ({ commit, dispatch, rootState }, { order_id, channel_id, token }) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/payment_provider/query/order/${channel_id}/${order_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const GETDETAILPAYMENT = async ({ commit, dispatch, rootState }, { order_id, channel_id }) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/payment_provider/query/order/${channel_id}/${order_id}`,
    })

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const GETMIXPAYMENT = async ({ commit, dispatch, rootState }, { order_id, channel_id }) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/payment/query/bills/detail/order/${channel_id}/${order_id}`,
    })
    return Promise.resolve(response.data)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const GETDETAILBANKACCOUNT = async ({ commit, dispatch, rootState }, { channel_id, account_number }) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/payment_provider/query/bank/account/${channel_id}/${account_number}`,
    })

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const UPLOADPROOFPAYMENT = async ({ commit }, { image, channel_id, order_id, cad_settlement = false, account_id, account_number, seller_payment_method_id, bank_name, payment_id }) => {
  const url = `/payment_confirmation/cmd/proof/payment/${channel_id}/${payment_id}`
  const data = new FormData()
  data.append('image', image)
  data.append('account_id', account_id)
  data.append('account_number', account_number)
  data.append('seller_payment_method_id', seller_payment_method_id)
  data.append('bank_name', bank_name)
  data.append('cad_settlement', cad_settlement)
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const GETPROOFPAYMENT = async ({ commit }, { order_id, channel_id }) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/payment_confirmation/query/proof/order/${channel_id}/${order_id}`,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const GETPROOFBYPAYMENTID = async ({ commit }, { payment_id, channel_id }) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/payment_confirmation/query/proof/payment/${channel_id}/${payment_id}`,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const GETINTRUCTIONSPAYMENT = async ({ commit }, { order_id, channel_id }) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/payment_provider/query/order/${channel_id}/${order_id}`,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const ACCEPTEDORDERBYUSER = async ({ commit, dispatch, rootState }, { order_id, state }) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'post',
      url: `/shipping/cmd/${order_id}/buyer_accepted`,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const CANCELORDERBYUSER = async ({ commit, dispatch, rootState }, { order_id, reasonCode, reason }) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'put',
      url: '/order/cmd/cancel',
      params: {
        id: order_id,
      },
      data: {
        new_status: 'CANCELREQUESTED',
        reason_code: reasonCode,
        reason: reason,
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const GETPROMOTION = async ({ commit, dispatch, rootState }, {
  payload,
  channel_id,
}) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'post',
      url: `/promotion/query/internal/${channel_id}/gift`,
      data: {
        ...payload,
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const GETACTIVEVOUCHER = async ({ commit, dispatch, rootState }, {
  payload,
  channel_id,
}) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/promotion/query/${channel_id}/distributor/vouchers`,
      params: {
        ...payload,
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const GETCOURIERLIST = async ({ commit, dispatch, rootState }, {
  payload,
  channel_id,
}) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/promotion/query/${channel_id}/distributor/vouchers`,
      params: {
        ...payload,
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}

export const GETGUESTORDERDETAIL = async ({ commit, dispatch, rootState }, {
  email,
  order_number,
  channel_id,
}) => {
  try {
    commit(types.SET_LOADING, true)
    const { data: response } = await apiGuestDistributorship({
      method: 'get',
      url: `/order/query/${channel_id}/tracking-orders?order_number=${order_number}&email=${email}`,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(types.SET_LOADING, false)
  }
}
