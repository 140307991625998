<script setup>
import { defineProps, computed, ref } from 'vue'
import { RouterLink } from 'vue-router'

const props = defineProps([
  'category',
])

const isHasImage = computed(() => props.category.image)
const hasChild = computed(() => !!props.category?.children.length)
const openChild = ref(false)

const onRootMenuClick = (e) => {
  if (hasChild.value) {
    openChild.value = !openChild.value
    e.preventDefault()
  }
}

</script>

<template>
  <div
    class="category-menu__item"
    :class="{ 'has-image': isHasImage, 'open-children': openChild }"
  >
    <Component
      :is="!hasChild ? RouterLink : 'a'"
      :to="`/purchase/catalogue/${category.id}`"
      class="link"
      @click="onRootMenuClick"
    >
      <img
        v-if="isHasImage"
        src="https://ik.imagekit.io/powerbiz/img/BHS001/429c099e-99ea-4087-a2d2-97afd15030ca/image_file_0gPzbUYHa?cm-pad_resize,bg-FFFFFF"
      />
      <span class="title">
        {{ category.name }}
      </span>

      <span v-if="category.children.length">
        <i class="fe fe-chevron-down"></i>
      </span>
    </Component>
    <div v-if="category.children" class="children" :class="{ open: openChild }">
      <RouterLink
        v-for="child in category.children"
        :key="child.id"
        :to="`/purchase/catalogue/${child.id}`"
        class="link"
      >
        <span class="title">{{  child.name }}</span>
      </RouterLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
img {
  width: 60px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 1rem;
}

.link {
  display: flex;
  align-items: center;
  width: 100%;

  .title {
    padding: .5rem 0;
    flex-grow: 1;
  }
}

.children {
  display: none;
  overflow: hidden;

  .link {
    display: block;
    padding: 1rem 1rem 1rem 1rem;
    font-weight: 400;
    color: #777777;
  }
}

.open-children .children {
  display: block;
}

.has-image .children .link {
  padding-left: calc(60px + 1rem);
}
</style>
