import { apiClientDistributorship, apiMeta } from '@/services/axios'
import { types } from './mutations'

export default {
  async GETWAREHOUSELIST({ commit, dispacth, rootState }, { id = [] }) {
    try {
      commit(types.SET_WAREHOUSE_STORE, {
        loading: true,
      })
      const ids = Array.isArray(id) ? id.join(',') : id
      const { data: response } = await apiMeta({
        method: 'get',
        // url: '/warehouse/query',
        url: '/warehouse/query/internal',
        params: {
          warehouse_id: ids,
        },
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit(types.SET_WAREHOUSE_STORE, {
        loading: false,
      })
    }
  },
  async CHECKINVENTORY({ commit }, { channel_id, catalog_id, warehouse_id, units }) {
    try {
      commit(types.SET_WAREHOUSE_STORE, {
        loading: true,
      })
      const { data: response } = await apiClientDistributorship({
        method: 'get',
        url: `/inventory/query/${channel_id}`,
        params: {
          warehouse_id,
          catalog_id,
          units,
        },
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit(types.SET_WAREHOUSE_STORE, {
        loading: false,
      })
    }
  },
  async GETWAREHOUSE_COVERAGE(
    { commit, dispacth, rootState },
    { channel_id, area_id },
  ) {
    const { channel_id: channelId, area_id: areaId } = rootState.user.user_data || {}
    try {
      commit(types.SET_WAREHOUSE_STORE, {
        loading: true,
      })
      const { data: response } = await apiClientDistributorship({
        method: 'get',
        url: `/warehouse/query/${channelId}/coverage`,
        params: {
          area_id: areaId,
        },
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit(types.SET_WAREHOUSE_STORE, {
        loading: false,
      })
    }
  },
  async GETWAREHOUSE_SELLER(
    { commit, dispacth, rootState },
    { channel_id, seller_id },
  ) {
    try {
      commit(types.SET_WAREHOUSE_STORE, {
        loading: true,
      })
      const { data: response } = await apiClientDistributorship({
        method: 'get',
        url: `/warehouse/query/${channel_id}/seller?seller_id=${seller_id.join(
          '&seller_id=',
        )}`,
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit(types.SET_WAREHOUSE_STORE, {
        loading: false,
      })
    }
  },
}
