import { apiClientFastify, apiClientDistributorship } from '@/services/axios'
import * as MutationTypes from './mutation-types'
import { requestToken } from '@/services/image/token'
import { upload } from '@/services/image/imagekit'

export const GETUSERPROFILE = async ({ rootState, state, commit, dispatch }) => {
  try {
    const { data: response } = await apiClientFastify.get('/users/private/seller/profile')
    const { data } = response
    dispatch('SETPROFILEINITIALVALUE', data)
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

// GET DETAIL USER
export const GETDETAILUSER = async ({ commit, dispatch, rootState }, opts) => {
  const { buyer_id } = opts || {}

  if (!buyer_id) return

  try {
    commit(MutationTypes.SET_LOADING, true)
    const response = await apiClientDistributorship({
      method: 'get',
      url: `/user/v2/query/${buyer_id}`,
    })
    const { data } = response.data
    const detail_user = {
      buyer_id: data.id,
      store_name: data.store_name,
      phone: data.phone,
      email: data.email,
      store_type: data.store_type,
      area_id: data.area_id,
      level_model_id: data.level_model_id,
      code: data.code,
      ext_code: data.ext_code,
      user: data.user,
      address: data.address,
      store: data.store,
      image_data: data.image_data,
      user_type: data.user_type,
    }
    commit(MutationTypes.SET_STATE_DETAIL_USER, detail_user)
    dispatch('SETPROFILEINITIALVALUE', detail_user)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}
// EDIT DETAIL USER
export const EDITDETAILUSER = async ({ commit, dispatch, rootState }, {
  buyer_id,
  user_id,
  email,
  phone,
  values,
  mapDetail,
  latitude,
  longitude,
}) => {
  try {
    commit(MutationTypes.SET_LOADING, true)
    const body_req = {
      'is_needconfirm': false,
      'user': {
        'id': user_id,
        'email': email, // email dan phone saat ini sengaja dibuat tidak bisa edit
        'phone': phone,
        'first_name': values.first_name,
        'last_name': values.last_name,
        'identity_number': '0000000000000000',
        'address': {
          'line1': values.address_line1,
          'line2': values.address_line2,
          'country_id': Number(values.country),
          'city_id': Number(values.city),
          'area_id': Number(values.area),
          'postcode': values.code_pos,
        },
      },
      'store': {
        'name': values.store_name,
        'npwp': '000000000000000',
        'type': 'STORE',
        'url': [
          '#',
        ],
      },
      'address': {
        'line1': values.address_line1,
        'line2': values.address_line2,
        'country_id': Number(values.country),
        'city_id': Number(values.city),
        'area_id': Number(values.area),
        'postcode': values.code_pos,
        'map_detail': mapDetail,
        'latitude': latitude,
        'longitude': longitude,
      },
    }
    const response = await apiClientDistributorship({
      method: 'put',
      url: `/user/v2/cmd/private/${buyer_id}`,
      data: body_req,
    })
    const { data } = response.data
    commit(MutationTypes.SET_STATE_DETAIL_USER, data)
    dispatch('SETPROFILEINITIALVALUE', data)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const SETPROFILEINITIALVALUE = async ({ rootState, state, commit, dispatch }, payload) => {
  const { address } = payload
  const { area_id, city_id, country_id } = address
  const cities = await dispatch('data/GETCITYLIST', { value: country_id, type: 'city' }, { root: true })
  const areas = await dispatch('data/GETCITYLIST', { value: city_id, type: 'area' }, { root: true })
  const findCity = cities.find(item => Number(item.value) === city_id)
  const findArea = areas.find(item => Number(item.value) === area_id)
  commit(MutationTypes.SET_PROFILE, payload)
  commit(MutationTypes.SET_INITIALVALUE_CITY, findCity.label)
  commit(MutationTypes.SET_INITIALVALUE_AREA, findArea.label)
  commit(MutationTypes.SET_STATE_DETAIL_USER, payload)
}

export const EDITPROFILE = async ({ rootState, state, commit, dispatch }, payload) => {
  try {
    commit(MutationTypes.SET_LOADING, true)
    const { cityList, areaList } = rootState.data
    const { image, address, address_two = '', firstname, lastname, newarea, newcity, phone } = payload
    const valueCity = () => {
      const value = cityList.find(item => item.label === newcity)
      return value.value
    }
    const valueArea = () => {
      const value = areaList.find(item => item.label === newarea)
      return value.value
    }
    const image_data = []

    const new_image = []

    const image_to_upload = []

    image.forEach(item => item.is_edit && new_image.push(item))

    let tokens = null

    if (new_image.length) {
      tokens = await requestToken({ qty: new_image.length })
      tokens.forEach((item, index) => {
        image_to_upload.push(upload({
          file: new_image[index].file,
          expire: item.expire,
          publicKey: item.public_key,
          signature: item.signature,
          token: item.token,
          folder: item.image_path,
        }))
      })
    }

    if (image_to_upload.length) {
      const uploadImage = await Promise.all(image_to_upload)

      uploadImage.forEach((item, index) => {
        const setInput = {
          name: item.name,
          size: item.size,
          type: item.fileType,
          path: tokens[index].image_path,
          ref_id: item.fileId,
          user_type: 2,
          content_id: new_image[index].content_id,
        }

        image_data.push(setInput)
      })
    }

    const { data: response } = await apiClientFastify({
      method: 'put',
      url: '/users/private/seller/profile',
      data: {
        user_data: {
          firstname,
          name: lastname,
          email: state.profile.user_data.email,
          phone,
        },
        address_data: {
          name: '',
          line1: address,
          line2: address_two,
          area_id: Number(valueArea()),
          city_id: Number(valueCity()),
          country_id: 228,
        },
        image_data,
      },
    })
    const { data } = response
    dispatch('SETPROFILEINITIALVALUE', data)
    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const GETUSERBANK = async ({ rootState, state, commit, dispatch }, payload) => {
  try {
    const { data: response } = await apiClientFastify.get('/users/private/bank')
    const { data } = response
    commit(MutationTypes.SET_USER_BANK, data)
    return Promise.resolve(data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const SETDEFAULTBANK = async ({ commit }, payload) => {
  try {
    commit(MutationTypes.SET_LOADING, true)
    const { data: response } = await apiClientFastify({
      method: 'put',
      url: '/users/private/setdefaultbank',
      data: {
        ...payload,
        is_default: 1,
      },
    })
    const { data } = response
    commit(MutationTypes.SET_USER_BANK, data)
    return Promise.resolve(data)
  } catch (e) {
    return Promise.reject(e)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const EDITUSERBANK = async ({ rootState, state, commit, dispatch }, payload) => {
  try {
    commit(MutationTypes.SET_LOADING, true)
    const { id_bank_account, bank, bank_name, accountnumber, accountholdername, isFromWithdraw } = payload
    const sliceBank = (bank) => {
      const index = bank.indexOf('_')
      return bank.slice(0, index)
    }
    const { data: response } = await apiClientFastify({
      method: id_bank_account ? 'put' : 'post',
      url: '/users/private/bank',
      data: id_bank_account ? {
        id_bank_account,
        bank_name,
        bank_accountnr: accountnumber,
        bank_accountname: accountholdername,
        is_default: isFromWithdraw ? 1 : 0,
      } : {
        bank_name: sliceBank(bank),
        bank_accountnr: accountnumber,
        bank_accountname: accountholdername,
        is_default: isFromWithdraw ? 1 : 0,
      },
    })
    // const { data } = response
    // commit(MutationTypes.SET_USER_BANK, data)
    // return Promise.resolve(response)
    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const DELETEBANKACCOUNT = async ({ commit }, payload) => {
  try {
    commit(MutationTypes.SET_LOADING, true)
    const { id_bank_account } = payload
    const { data: response } = await apiClientFastify.delete(`/users/private/bank/${id_bank_account}`)
    const { data } = response
    commit(MutationTypes.SET_USER_BANK, data)
    return Promise.resolve()
  } catch (err) {
    return Promise.reject(err)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const GETCOMMISSIONLIST = async ({ commit, rootState }, payload) => {
  try {
    commit(MutationTypes.SET_LOADING, true)
    const { start_date = '', end_date = '', order_id = '', page = 1, limit = 20 } = payload
    const { data: response } = await apiClientFastify({
      method: 'get',
      url: '/data/private/reports/commission_statements',
      params: {
        start_date,
        end_date,
        order_id,
        page,
        limit,
      },
    })
    const { data } = response
    const pagination = { ...rootState.account.pagination }
    pagination.total = response.rows
    pagination.pageSize = limit
    pagination.current = page
    commit(MutationTypes.SET_PAGINATION, pagination)
    commit(MutationTypes.SET_COMMISSION_LIST, data)
    commit(MutationTypes.SET_TOTAL_COMMISSION, response.total_commission)
  } catch (err) {
    return Promise.reject(err)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const GETEWALLET = async ({ commit }) => {
  try {
    const { data: response } = await apiClientFastify.get('/data/private/dashboard/commissions')
    const { data } = response
    commit('dashboard/SET_COMMISSION', data, { root: true })
  } catch (err) {
    return Promise.reject(err)
  }
}

export const WITHDRAW = async ({ commit }, payload) => {
  try {
    commit(MutationTypes.SET_LOADING, true)
    const {
      bank_id,
      amount,
    } = payload
    const { data: response } = await apiClientFastify({
      method: 'post',
      url: '/users/private/seller/withdraws',
      data: {
        bank_id: Number(bank_id),
        amount: Number(amount),
      },
    })
    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const GETEWALLETHISTORY = async ({ commit, rootState }, payload) => {
  try {
    commit(MutationTypes.SET_LOADING, true)
    const { start_date, end_date, order_id, type = [1, 2, 3, 4], page = 1, limit = 10 } = payload
    const { data: response } = await apiClientFastify({
      method: 'get',
      url: '/data/private/reports/ewallet_statements',
      params: {
        start_date,
        end_date,
        order_id,
        type: JSON.stringify(type),
        page,
        limit,
      },
    })
    if (page !== 1) {
      const { ewalletHistory } = rootState.account
      const newData = ewalletHistory.concat(response.data)
      commit(MutationTypes.SET_EWALLET_HISTORY, newData)
    } else {
      commit(MutationTypes.SET_EWALLET_HISTORY, response.data)
    }

    commit(MutationTypes.SET_LAST_WITHDRAW, response.withdraw)

    return Promise.resolve({
      disableButton: !response.data.length,
    })
  } catch (err) {
    return Promise.reject(err)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const VERIFYBANK = async({ commit, rootState }, payload) => {
  try {
    commit(MutationTypes.SET_LOADING, true)
    const { id_bank_account, otpcode, otp_id } = payload
    const { data: response } = await apiClientFastify({
      method: 'put',
      url: '/users/private/verifybank',
      data: {
        id_bank_account,
        otpcode,
        otp_id,
      },
    })
    const { data } = response
    commit(MutationTypes.SET_USER_BANK, data)
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const RESENDOTP = async ({ commit }, payload) => {
  try {
    commit(MutationTypes.SET_LOADING, true)
    // const { prev_otp_id } = payload
    const { id_bank_account, bank, bank_name, accountnumber, accountholdername, isFromWithdraw, prev_otp_id } = payload
    const sliceBank = (bank) => {
      const index = bank.indexOf('_')
      return bank.slice(0, index)
    }
    const { data: response } = await apiClientFastify({
      method: 'post',
      url: '/users/private/recreate_bankotp',
      // data: {
      //   prev_otp_id,
      // },
      data: {
        prev_otp_id,
        data: id_bank_account ? {
          id_bank_account,
          bank_name,
          bank_accountnr: accountnumber,
          bank_accountname: accountholdername,
          is_default: isFromWithdraw ? 1 : 0,
        } : {
          bank_name: bank ? sliceBank(bank) : bank_name,
          bank_accountnr: accountnumber,
          bank_accountname: accountholdername,
          is_default: isFromWithdraw ? 1 : 0,
        },
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const VERIFYWITHDRAW = async ({ commit }, payload) => {
  try {
    commit(MutationTypes.SET_LOADING, true)
    const { id_bank_account, otpcode, otp_id, amount, withdraw_id } = payload
    const { data: response } = await apiClientFastify({
      method: 'post',
      url: '/users/private/seller/verifywithdraw',
      data: {
        id_bank_account,
        otpcode,
        otp_id,
        amount,
        withdraw_id,
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_LOADING, false)
  }
}

export const GETDETAILCUSTOMERBYID = async ({ commit, rootState, dispatch }, { buyer_id }) => {
  try {
    commit('SET_STATE', {
      loading: true,
    })
    const { data: response } = await apiClientDistributorship({
      method: 'get',
      url: `/user/v2/query/customers/${buyer_id}`,
    })
    commit(MutationTypes.SET_STATE_DETAIL_CUSTOMER, response)
    return Promise.resolve(response)
  } catch (err) {
    const { response: errResponse } = err
    return Promise.reject(errResponse)
  } finally {
    commit('SET_STATE', {
      loading: false,
    })
  }
}
