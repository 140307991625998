import Vue from 'vue'
import Vuex from 'vuex'
import { apiMeta } from '@/services/axios'

Vue.use(Vuex)

export default {
  async CONTACT({ commit, dispatch, rootState }, payload) {
    let responseData
    try {
      commit('SET_STATE', {
        loading: true,
      })

      const { data: response } = await apiMeta({
        method: 'get',
        url: '/business/query/internal/meta',
      })
      responseData = response
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit('SET_STATE', {
        loading: false,
        data: responseData,
      })
    }
  },
  async MINIMUM_TRANSACTION({ commit }) {
    try {
      commit('SET_STATE', {
        loading: true,
      })
      const { data: response } = await apiMeta({
        method: 'get',
        // url: '/business/query/meta/minimum_transaction',
        url: '/business/query/internal/meta/name/minimum_transaction',
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit('SET_STATE', {
        loading: false,
      })
    }
  },
  async MAXIMUM_VA_AMOUNT({ commit }) {
    try {
      commit('SET_STATE', {
        loading: true,
      })
      const { data: response } = await apiMeta({
        method: 'get',
        url: '/business/query/internal/meta/name/max_va',
      })
      return Promise.resolve(JSON.parse(response?.meta_value))
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit('SET_STATE', {
        loading: false,
      })
    }
  },
  async GENERATEPAGEABOUT({ commit }, { path }) {
    try {
      commit('SET_STATE', {
        loading: true,
      })
      const { data: response } = await apiMeta({
        method: 'get',
        url: `/logapi/pages/${path}/business`,
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit('SET_STATE', {
        loading: false,
      })
    }
  },
}
