import localeAntd from 'ant-design-vue/lib/locale-provider/id_ID'

const messages = {
  form: {
    add: 'Tambah',
    placeholder_search_product: 'Cari berdasarkan nama produk',
    placeholder_order_number: 'Nomor Order',
    placeholder_email_phone: 'Nama Pengguna / Alamat Email / No. Handphone',
    placeholder_password: 'Masukan kata sandi',
    first_name: 'Nama Depan',
    last_name: 'Nama Belakang',
    phone: 'No Handphone (WhatsApp)',
    email: 'Email',
    OldEmail: 'Email Lama',
    OldPhone: 'No Handphone (WhatsApp) Lama',
    emailPhone: 'Email atau Nomor Handphone',
    address: 'Alamat',
    country: 'Negara',
    owner: 'Nama Pemilik',
    city: 'Kabupaten/Kota - Provinsi',
    placeholderCity: 'Silakan pilih kabupaten/kota - Provinsi',
    area: 'Desa/Kelurahan - Kecamatan',
    placeholderArea: 'Silakan pilih Desa/Kelurahan - Kecamatan',
    postal_code: 'Kode POS',
    login_caption: 'Masuk ke akun anda',
    password: 'Kata Sandi',
    current_password: 'Kata Sandi Saat Ini',
    new_password: 'Kata Sandi Baru',
    confirm_new_password: 'Konfirmasi Kata Sandi Baru',
    successConfirm: 'Kata Sandi berhasil diubah',
    passwordRequired: 'Kata Sandi harus diisi',
    newPasswordRequired: 'Kata Sandi Baru harus diisi',
    confirmPasswordRequired: 'Konfirmasi Kata Sandi Baru',
    confirmPasswordNotMatch: 'Konfirmasi Kata Sandi tidak sesuai',
    passwordNotMatch: 'Kata sandi lama Anda tidak sesuai',
    passwordValidate:
      'Kata sandi harus mengandung setidaknya 8 karakter, satu huruf besar, satu huruf kecil dan satu angka',
    password8char: 'Kata Sandi harus lebih dari 8 karakter',
    storeName: 'Nama Toko',
    placeholderStoreName: 'Masukan nama toko anda',
    birthPlace: 'Tempat Lahir',
    placeholderBirthPlace: 'Masukan daerah tempat lahir anda',
    birthDate: 'Tanggal Lahir',
    addressPlace: 'Alamat',
    streetName: 'Nama Jalan',
    placeholderStreetName: 'Masukan nama jalan',
    addressStore: 'Alamat Toko',
    placeholderRTRW: 'Masukan RT/RW',
    placeholderNoKTP: 'Masukan no KTP',
    clickOrDrag: 'klik atau tarik file pada area upload',
    pickPhoto: 'Pilih Foto',
    onUpload: 'Mengunggah Foto',
    infoUpload:
      'Unggah foto toko anda dengan format JPG, JPEG, PNG. Max size 4 MB. Dengan dimensi foto disarankan minimal 500x317px.',
    infoUploadKTP:
      'Unggah foto KTP anda dengan format JPG, JPEG, PNG. Max size 4 MB. Dengan dimensi foto disarankan minimal 500x317px.',
    infoUploadNPWP:
      'Unggah foto NPWP anda dengan format JPG, JPEG, PNG. Max size 4 MB. Dengan dimensi foto disarankan minimal 500x317px.',
    registerAccount: 'DAFTAR AKUN',
    placeholderUrlStore: 'Masukan url toko anda',
    yes: 'Ya',
    no: 'Tidak',
    changeImage: 'Ubah Gambar',
    NPWP: 'No NPWP',
    placeholderNPWP: 'Masukan no NPWP',
    search: 'Cari produk...',
    pinmap: 'Pin Alamat',
    setLocation: 'Tandai Lokasi',
    cancel: 'Batal',
    selectLocation: 'Pilih Lokasi',
    selectedLocation: 'Lokasi yang dipilih',
    searchLocation: 'Tulis Jalan / Perumahan / Gang',
  },
  dashboard: {
    weeklySales: 'Penjualan Mingguan',
    onProcess: 'Pesanan di Proses',
    completedOrders: 'Pesanan Selesai',
    refundRequest: 'Permintaan Pembatalan Pesanan',
    ewallet: 'E-wallet',
    totalCommission: 'Total Komisi',
    onComingCommission: 'Komisi pada Transaksi Selanjutnya',
    bestSeller: 'Produk Terlaris',
    productNotFound: 'Produk Tidak Ditemukan',
    lastSoldItems: 'Produk Terakhir Dijual',
    totalPoint: 'Total coin yang anda miliki',
  },
  profile: {
    billing: {
      billingTitle: 'Tagihan',
      paymentsNeedCompletion: 'Pilih Pembayaran Yang Ingin Dilunasi',
      viewDetails: 'Lihat Detail',
    },
  },
  purchase: {
    catalogue: {
      title: 'Katalog Produk',
      new: 'Produk Baru',
      related: 'Produk Terkait',
      all_product: 'Semua Produk',
      caption1: 'Lebih mudah mencari produk di kategori',
      cart: 'Keranjang',
      addCart: 'Tambah ke Keranjang',
      select_variant: 'Pilih Varian',
      select_qty_unit: 'Atur Jumlah dan Unit',
      qty_req: 'Jumlah harus diisi',
      product_not_found: 'Tidak ada produk yang di temukan untuk pecarian Anda.',
      product_detail: 'Rincian Produk',
      product_description: 'Deskripsi Produk',
      select_unit: 'Pilih Unit',
      outOfStock: 'Barang Habis',
      inStock: 'Barang Tersedia',
      login_disclaimer: 'Harap login terlebih dahulu, sebelum menambahkan barang ke keranjang',
      disclaimer_customer_not_active: 'Mohon Maaf<br>Anda tidak dapat melakukan transaksi karena akun anda sudah tidak aktif.<br><br>Hubungi customer service kami di 021-5524999 untuk info lebih lanjut.',
      go_to_login_page: 'Menuju ke Halaman Login',
      view_only: 'Produk ini hanya untuk dilihat, silakan hubungi',
    },
    cart: {
      title: 'Keranjang',
      detail_product: 'Detail Produk',
      quantity: 'Jumlah',
      total: 'Jumlah',
      unit: 'Unit',
      price: 'Harga',
      delete: 'Hapus',
      not_found_cart: 'Anda belum memiliki produk di keranjang',
      caption_delete: 'Apakah anda yakin ingin hapus produk dari keranjang?',
      yes: 'Ya',
      successDeleteCart: 'Berhasil dihapus dari Cart',
      failedDeleteCart: 'Gagal dihapus dari Cart',
      cancel: 'Batal',
      checkout: 'Beli',
      notif_select_unit: 'Silakan pilih unit terlebih dahulu.',
      failed: 'Gagal menambahkan barang ke Keranjang',
      success: 'Barang berhasil ditambahkan ke Keranjang',
      minimum_price1: 'Transaksi Anda belum mencapai',
      minimum_price2: 'Silahkan tambah produk ke keranjang belanja',
    },
    checkout: {
      title: 'Pembelian',
      shipping_detail: 'Alamat Pengiriman',
      billing_detail: 'Alamat Penagihan',
      please_fill_shipping_address: 'Harap isi alamat pengiriman terlebih dahulu',
      no_available_courier: 'Tidak ada kurir yang tersedia untuk alamat pengiriman Anda',
      save_address: 'Simpan Alamat',
      cancel: 'Batal',
      shipping_payment: 'Biaya Pengiriman',
      select_another_address: 'Pilih Alamat Lain',
      add_new_address: 'Tambah Alamat Baru',
      item: 'Produk',
      select_payment_method: 'Pilih Metode Pembayaran',
      use_point: 'Pakai Coin',
      use_point_have: 'Gunakan Coin Yang Anda Miliki',
      order_summary: 'Ringkasan Belanja',
      total_product: 'Total Produk',
      return_balance: 'Deposit Retur',
      point_used: 'Coin Digunakan',
      grand_total: 'Total Belanja',
      confirm_order: 'Konfirmasi Pesanan',
      see_detail: 'Lihat Rincian',
      cart_empty: 'Anda belum memiliki produk di keranjang',
      here: 'disini',
      select_product: 'Silahkan pilih produk terlebih dahulu',
      create_order: 'Buat Pembelian',
      confirmShipping: 'Harap konfirmasikan alamat pengiriman',
      confirmBilling: 'Harap konfirmasikan alamat penagihan',
      confirmPaymentMethods: 'Harap konfirmasikan Metode Pembayaran',
      successCheckout: 'Checkout Berhasil !',
      failCheckout: 'Checkout Gagal !',
      yourPotensialPoints: 'Bonus yang anda dapatkan',
      potensialModalTitle: 'Potensi bonus anda',
      kinoBonusTitle: 'Bonus Koin',
      extraGoodsTitle: 'Promo Ekstra Barang',
      discountPercentTitle: 'Promo Diskon',
      kinoPotensiBonusDesc: 'Anda berpotensi dapat coin sebanyak ',
      kinoBonusDesc: 'Anda dapat coin sebanyak ',
      kinoExtraGoodsDesc: 'Anda berpotensi dapat barang tambahan',
      discountPercentDesc: 'Anda dapat bonus diskon',
      discountCutDesc: 'Anda dapat bonus potongan harga',
      discountVoucher: 'Voucher Diskon ',
      discountVoucherDesc: 'Anda dapat voucher diskon sebesar ',
      discountProductDesc: 'Anda dapat bonus barang tambahan ',
      discountVoucherFixed: 'Potongan Harga ',
      use_shipping_address: 'Gunakan Alamat Pengiriman',
      yourRemainingPoint: 'Sisa Coin Anda',
    },
    list: {
      all_order: 'Semua Pembelian',
      price: 'Harga',
      total: 'Total',
      po_number: 'PO Number',
      order_number: 'Nomor Order',
      created_at: 'Tanggal Order',
      note: 'Catatan',
    },
    detail: {
      title: 'Detail Transaksi',
      title_detail: 'Detail Transaksi',
      order_information: 'Informasi Pembelian',
      return_information: 'Informasi Retur',
      total_payment: 'Total Pembayaran',
      payment_status: 'Status Pembayaran',
      payment_number: 'Nomor Transaksi',
      payment_date: 'Tanggal Transaksi',
      payment_method: 'Metode Pembayaran',
      shipping_method: 'Metode pengiriman',
      shipping_payment: 'Biaya Pengiriman',
      payment_term: 'Payment Term',
      payment_type: 'Tipe Pembayaran',
      account_number: 'Account Number',
      estimate: 'Estimasi tiba',
      bank: 'Bank',
      receipt_number: 'Nomor Resi',
      wallet: 'Wallet',
      shipping_information: 'Informasi Pengiriman',
      billing_information: 'Informasi Penagihan',
      recipient_name: 'Nama Penerima',
      billed_name: 'Nama Tertagih',
      address: 'Alamat',
      phone_number: 'Nomor Telepon',
      sent_as_dropshipper: 'Dikirim Sebagai Dropshiper',
      toko_name: 'Nama Toko',
      courier: 'Kurir',
      pick_up_admin: 'Pick Up Admin',
      product_detail: 'Produk',
      product: 'Produk',
      grand_total: 'Grand Total',
      detail_transaction_qr:
        'Pindai menggunakan aplikasi pembayaran QR pilihan Anda.',
      pay_via_web_link: 'Bayar via web link',
      bill_credit_limit: 'Tagihan Credit Limit',
      unpaid: 'Belum Lunas',
      paid: 'Sudah Lunas',
      bill_status: 'Status Tagihan',
      bill_total: 'Total Tagihan',
      remaining_payment: 'Sisa Pembayaran',
      remaining_payment_status: 'Status Sisa Pembayaran',
      waiting_payment: 'Menunggu Pembayaran',
      main_payment_method: 'Metode Pembayaran Utama',
      credit: 'Tempo',
      credit_limit_used: 'Credit Limit Digunakan',
      remaining_payment_method: 'Metode Sisa Pembayaran',
    },
  },
  payment: {
    unpaid: 'Menunggu Pembayaran',
    expired: 'Kadaluarsa',
    paid: 'Sudah dibayar',
    title: 'Pembayaran',
  },
  sideBar: {
    dashboard: 'Beranda',
    sales: 'Penjualan',
    orderList: 'Daftar Pesanan',
    orderListDistributor: 'Daftar Penjualan',
    commissionList: 'Daftar Komisi',
    store: 'Toko',
    availableProducts: 'Produk',
    myProducts: 'Produk Saya',
    setting: 'Pengaturan Toko',
    viewStore: 'Lihat Toko',
    account: 'Akun',
    profile: 'Profil',
    editProfile: 'Pengaturan Akun',
    ewalletHistory: 'Mutasi E-wallet',
    withdrawRequest: 'Penarikan E-wallet',
    purchase: 'Pembelian',
    purchaseList: 'Daftar Pembelian',
    createPurchase: 'Pembelian',
    purchaseCatalogue: 'Katalog',
    purchaseCart: 'Keranjang',
    redeem_point: 'Redeem Coin',
    catalogue_redeem: 'Katalog Redeem',
    cart_redeem: 'Keranjang',
    purchase_redeem: 'Pembelian',
    transaction_redeem: 'Transaksi Redeem',
  },
  topBar: {
    hello: 'Hai',
    email: 'Email',
    phone: 'Telpon',
    change_password: 'Konfirmasi',
    editProfile: 'Edit Profil',
    logout: 'Keluar',
    issuesHistory: 'Issues History',
    projectManagement: 'Project Management',
    typeToSearch: 'Search...',
    findPages: 'Find pages...',
    actions: 'Actions',
    status: 'Status',
    profileMenu: {
      hello: 'Hello',
      billingPlan: 'Billing Plan',
      role: 'Role',
      email: 'Email',
      phone: 'Phone',
      // editProfile: 'Edit Profile',
      logout: 'Logout',
    },
    myStore: 'Toko Saya',
  },
  authForm: {
    loginFailed: 'Login Gagal',
    registerFailed: 'Pendaftaran Tidak Berhasil',
    invalidPassword: 'Kata sandi Invalid',
    emailNotFound: 'Email tidak ditemukan',
    systemBusy:
      'Sistem sibuk, cobalah beberapa saat lagi atau kontak administrator!',
    thankYou: 'Terima Kasih',
    forgotpassSucces:
      'Cek email anda untuk melengkapi proses perubahan kata sandi',
    forgotpassFailed: 'Setel ulang kata sandi gagal',
    passwrdNotmatch: 'Kata sandi berbeda!',
    resetpassFailed: 'Setel ulang kata sandi gagal',
    resetpassSuccess: 'Setel ulang kata sandi sukses',
    signInTitle: 'Masuk',
    signInDesc: 'Silahkan masuk dengan akun Anda',
    requiredEmailPhone: 'Mohon masukan alamat email atau no telepon anda!',
    requiredEmail: 'Mohon masukan alamat email anda!',
    formatEmail: 'Format alamat email salah!',
    requiredPassword: 'Mohon masukan kata sandi anda!',
    minRequiredPassword: 'Kata sandi harus mengandung setidaknya 8 karakter, satu huruf besar, satu huruf kecil, satu angka dan satu spesial karakter.',
    signIn: 'Masuk',
    //
    dontHaveAccount: 'Anda belum mempunyai akun?',
    register: 'Daftar',
    registerNow: 'Daftar Sekarang',
    forgotPassword: 'Lupa kata sandi?',
    resetPass: 'Atur Ulang Kata Sandi',
    resetMyPass: 'Kirim',
    emailAddress: 'Alamat Email',
    placeholderEmailAddress: 'Masukan alamat email',
    goToSignIn: 'Kembali ke halaman login',
    newPass: 'Kata Sandi Baru',
    confirmNewPass: 'Konfirmasi Kata Sandi Baru',
    createAccount: 'Daftar',
    firstName: 'Nama Depan',
    requiredFirstName: 'Mohon masukan nama depan anda!',
    placeholderFirstName: 'Masukan nama depan',
    lastName: 'Nama Belakang',
    requiredLastName: 'Mohon masukan nama belakang anda',
    placeholderLastName: 'Masukan nama belakang',
    mobileNumberOrEmail:
      'Mohon diisi salah satu (no handphone atau email) untuk verifikasi akun',
    disclaimer:
      'Data pribadi Anda akan digunakan untuk mendukung pengalaman Anda di seluruh situs web ini, untuk mengelola akses ke akun Anda, dan untuk tujuan lain yang dijelaskan dalam kebijakan privasi kami',
    phone_number: 'No Handphone',
    email: 'Email',
    placeholderEmail: 'Masukan email',
    password: 'Kata Sandi',
    placeholderPassword: 'Masukan kata sandi',
    confirmPassword: 'Konfirmasi Kata Sandi',
    placeholderConfirmPassword: 'Masukan Konfirmasi kata sandi',
    toc: 'Dengan melakukan pendaftaran, anda setuju dengan',
    and: 'dan',
    alreadyHaveAccount: 'Sudah mempunyai akun?',
    registerSuccess:
      'Silahkan cek {type} anda: {payload}, untuk melengkapi proses pendaftaran',
    regConfirmation: 'Konfirmasi Pendaftaran',
    storeName: 'Nama Toko',
    requiredStoreName: 'Mohon masukan nama toko anda',
    storeUrl: 'Url Toko',
    helpStoreUrl:
      'Mohon pastikan url toko anda sudah benar. Anda tidak bisa menggantinya setelah melakukan pendaftaran',
    requiredStoreUrl: 'Mohon masukan url toko anda',
    phoneNumber: 'No Handphone (WhatsApp)',
    requiredPhoneNumber: 'Mohon masukan no handphone (WhatsApp) anda',
    placeholderPhoneNumber: 'Masukan no handphone',
    address: 'Alamat',
    requiredAddress: 'Mohon masukan alamat anda',
    address2: 'Alamat 2',
    requiredAddress2: 'Mohon masukan alamat anda (opsional)',
    country: 'Negara',
    city: 'Kota',
    placeCity: 'Pilih Kota',
    area: 'Kelurahan',
    placeArea: 'Pilih Kelurahan',
    zip: 'Kode Pos',
    requiredZip: 'Kode Pos harus berupa angka',
    bankInformation: 'Informasi Bank',
    accountNo: 'Nomer Rekening.',
    requiredAccountNo: 'Nomer Rekening harus berupa angka',
    accountHolder: 'Nama Pemilik Akun Bank',
    placeAccountHolder: 'Mohon masukan nama pemilik Akun Bank',
    bankAccount: 'Bank Akun',
    placeBankAcc: 'Pilih Bank',
    idCard: 'Kartu Indentitas (KTP/SIM)',
    popUpRemoveImage: 'Kamu yakin untuk menghapus foto ini?',
    removeImage: 'Hapus foto',
    toc2: 'Saya setuju dengan syarat dan ketentuan yang berlaku',
    submitConRegister: 'Submit',
    registerConfirm: 'Konfirmasi Pendaftaran',
    registerCompleted: 'Silakan login untuk melanjutkan',
    requiredCity: 'Mohon pilih kota anda',
    requiredArea: 'Mohon pilih kelurahan anda',
    requiredBankAcc: 'Mohon pilih akun bank',
    requiredBankAccName: 'Mohon masukan nama pemilik aku bank',
    requiredToc2: 'Silakan cek Term and Condition',
    successUploadImage: 'Foto berhasil diunggah',
    failUploadImage: 'Gagal mengunggah gambar !',
    insertYourUrlStore: 'Silakan masukan URL toko Anda terlebih dahulu',
    extraNpwp:
      'Jika kamu mempunyai NPWP, komisi akan dipotong pajak 5%, jika tidak dipotong 6%. Kamu bisa menambahkan NPWP dilain waktu pada pengaturan akun, jika belum mempunyai sekarang.',
    titleSuccessRegister: 'Selamat Anda berhasil mendaftar!',
    subTitleSuccessRegister: 'Silahkan masuk ke akun Anda dan mulai belanja',
    addressStoreTitle: 'Gunakan alamat tinggal sama dengan alamat toko',
    haveStore: 'Apakah anda memiliki toko ?',
    haveOnlineStore: 'Apakah anda memiliki online toko ?',
    personalData: 'Data Pribadi',
    titleConfRegis: 'Lengkapi Data',
    subTitleConfRegis: 'Silahkan melengkapi data Anda sebelum mulai belanja',
    resetPasswordRequestSuccess:
      'Silahkan cek {type} anda: {payload}, untuk melengkapi proses perubahan kata sandi',
    customerService: 'Kontak Layanan Pelanggan',
    registerForm:
      'Mohon diisi salah satu (no handphone atau email) untuk verifikasi akun',
  },
  upload: {
    uploadProofPayment: 'Upload Bukti Pembayaran',
    changeImage: 'Ubah gambar',
  },
  account: {
    account: 'Akun',
    edit: 'Ubah',
    add: 'Tambah',
    view: 'Lihat',
    verifier: 'Verifikator',
    addPhone: 'Tambah No Handphone',
    addEmail: 'Tambah Email',
    editPhone: 'Ubah No Handphone',
    editEmail: 'Ubah Email',
    editAccount: 'Ubah Profil',
    successEditProfileModalTitle: 'Berhasil mengubah profil Anda',
    successEditProfileModalBody: 'Silahkan masuk kembali untuk memperbarui profil Anda',
    successEditProfileModalButton: 'Masuk Kembali',
    setAsVerifier: 'Atur sebagai default verifikasi',
    updateBio: 'Ubah Biodata',
    profile: 'Profil',
    address: 'Alamat',
    storeName: 'Nama Toko',
    telephone: 'No. Telpon',
    owner: 'Nama Pemilik',
    billing: 'Tagihan',
    retur: 'Retur',
    firstName: 'Nama Pemilik (Nama Depan)',
    lastName: 'Nama Pemilik (Nama Belakang)',
    ktp: 'Nomor KTP',
    npwp: 'Nomor NPWP',
    streetName: 'Nama Jalan / Komplek / Lingkungan',
    rtrw: 'RT-RW',
    requiredStoreName: 'Isi dengan Nama Toko Anda',
    requiredFirstName: 'Masukkan nama Anda',
    requiredEmail: 'Isi dengan alamat email anda yang aktif',
    requiredPhone: 'Masukkan nomor telpon/handphone yang aktif',
    howToChangeEmail:
      'Jika Anda ingin mengubah alamat Email, silakan Hubungi Kami',
    howToChangePhone:
      'Silakan hubungi kami jika Anda ingin mengubah Nomor Telpon',
    requiredAddress: 'Masukkan alamat lengkap Anda',
    requiredKTP: 'Masukkan nomor e-KTP Anda',
    requiredNPWP: 'Masukkan nomor NPWP Anda',
    submit: 'Simpan Perubahan',
    unverified: 'Belum Terverifikasi',
    verified: 'Terverifikasi',
    successUpdateData: 'SUKSES ! Data berhasil terupdate',
    failedUpdateData: 'MAAF ! Data gagal terupdate',
    shippingAddress: 'Daftar Alamat Pengiriman',
    addressList: 'Daftar Alamat',
    changePassword: 'Ubah Kata Sandi',
    aboutBhs: 'Tentang Sarungindonesia',
    privacyPolicy: 'Kebijakan & Privasi',
    termCondition: 'Syarat & Ketentuan',
    customerService: 'Kontak Layanan Pelanggan',
    coinTerms: 'Ketentuan Koin',
  },
  store: {
    allProduct: 'Semua Produk',
    selectAll: 'Pilih Semua',
    addToStore: 'Tambahkan ke Toko',
    removeFromStore: 'Keluarkan dari Toko',
    searchProduct: 'Cari Berdasarkan Nama Produk',
    productInStore: 'Produk ada di Toko',
    productOutStore: 'Produk tidak ada di Toko',
    productNotFound: 'Produk Tidak Ditemukan',
    loadMore: 'Muat Lebih Banyak',
    alreadyInStore: 'Sudah Ada di toko',
    price: 'Harga untuk {value} variant',
    commission: 'Komisi',
    fotoProfile: 'Logo Toko',
    changeImage: 'Ganti Logo',
    addImage: 'Tambah Logo',
    storeName: 'Nama Toko',
    requiredStoreName: 'Mohon masukan nama toko anda',
    notifStoreUrl: 'Anda tidak bisa merubah url toko',
    save: 'Simpan',
    selectedBanner: 'Banner yang Dipilih',
    availableBanner: 'Banner yang Tersedia:',
    profileSetting: 'Pengaturan Toko',
    bannerSetting: 'Pengaturan Banner',
    storeUrl: 'Url Toko',
    successAddProduct: 'Menambahkan produk ke toko sukses',
    failedAddProduct: 'Menambahkan produk ke toko gagal',
    successRemoveProduct: 'Mengeluarkan produk dari toko sukses',
    failedRemoveProduct: 'Mengeluarkan produk dari toko sukses',
    successEditStore: 'Edit profile toko sukses',
    failedEditStore: 'Edit porfile toko gagal',
    requiredStoreFoto: 'Mohon masukan logo toko',
    outOfStock: 'Stok Kosong',
    shareThisProduct: 'Bagikan produk ini',
    shareProduct: 'Bagikan Produk {value}',
    extraDefaultMessage:
      'Jika kamu tidak menulis pesan. Sistem akan membuat default pesan secara otomatis',
    placeholderDefaultMessage: 'Tuliskan pesan anda (optional)',
    shareWith: 'Bagikan dengan',
    changeDefaultMessage:
      '*Kamu bisa mengganti default message diatas, dengan mengisi text dibawah ini.',
    stock: 'Stok',
    updateStock: 'Ubah stok',
    color: 'Warna',
    size: 'Ukuran',
    flavour: 'Rasa',
    saveChanges: 'Simpan perubahan',
    changeStockSuccess: 'Perubahan stok produk berhasil disimpan',
  },
  verify: {
    titleVerify: 'Silahkan pilih metode verifiksi',
    withEmail: 'Via Email',
    withPhone: 'No. Ponsel (WhatsApp)',
    emailSentTitle: 'Terima Kasih',
    emailSentSubTitle:
      'Silahkan cek email anda untuk verifikasi dan melengkapi proses pendaftaran',
    emailSentButton: 'Kembali ke beranda',
    otpTitle: 'Masukkan kode OTP',
    otpSubTitlePhone:
      'Masukkan kode verifikasi yang telah dikirim ke nomor handphone Anda via Whatsapp',
    otpSubTitleEmail:
      'Masukkan kode verifikasi yang telah dikirim ke Email Anda',
    otpButton: 'Verifikasi',
    otpWaitingText: 'Mohon menunggu sebelum mengirim ulang',
    otpResend: 'Kirim ulang kode dalam',
  },
  comingSoon: {
    comingSoon: 'SEGERA HADIR !',
    featureIsNotYet: 'Saat ini fitur penukaran Coin belum tersedia',
    backToHome: 'Kembali Ke Beranda',
  },
  footer: {
    contact: 'Contact',
    about: 'About',
    tentang_kino: 'About ',
    product_category: 'Product Category',
    social_media: 'Social Media',
    download_app: 'Download App',
  },
  customerService: {
    copyEmailMessage: 'Email berhasil di salin!',
    copyPhoneMessage: 'No handphone berhasil di salin!',
    copyEmail: 'Salin Alamat Email',
    copyPhone: 'Salin No Handphone',
    customerServiceInfo:
      'Apabila Anda memerlukan bantuan silahkan hubungi CS  di nomor handphone atau alamat email yang tertera.',
    back: 'Kembali',
  },
  expandButtonCaption: 'Lihat lebih banyak',
  lessButtonCaption: 'Lihat lebih sedikit',
  menu: {
    loyalty: {
      title: 'Coin',
      catalog: 'Katalog Coin',
      cart: 'Keranjang Coin',
      purchase: 'Pembelian Coin',
      transaction: 'Transaksi Coin',
    },
    purchase: {
      title: 'Pembelian',
      catalog: 'Katalog Produk',
      transaction: 'Transaksi',
      noTransactionsYet: 'Anda belum memiliki transaksi. Yuk, mulai belanja',
      shopNow: 'Belanja Sekarang',
      tabs: {
        all: 'Semua',
        waiting_confirmation: 'Menunggu Konfirmasi',
        waiting_confirmation_canceled: 'Menunggu Konfirmasi Pembatalan',
        on_process: 'Dalam Proses',
        shipping: 'Pengiriman',
        completed: 'Selesai',
        canceled: 'Dibatalkan',
      },
      cart: {
        delete_product: 'Hapus Produk',
        cannot_be_process: 'Tidak Bisa Diproses',
        update_stock: 'Ubah Stock',
        stock_empty: 'Stock Kosong',
        product_left: 'Tersisa',
        total_price: 'Total Harga',
        total_coin: 'Total Coin',
      },
      cash_delivery: {
        shopping_now: 'Belanja Sekarang',
        empty_transaction: 'Anda Belum Memiliki Transaksi',
        order_number: 'Nomor Order',
        pay_before: 'Bayar Sebelum',
        total_payment: 'Total Pembayaran',
        how_to_pay: 'Lihat Cara bayar',
        back: 'Kembali',
      },
      checkout: {
        order_failed: 'Gagal Membuat Pesanan',
        back_to_cart: 'Kembali ke keranjang',
        auto_delete: '*Produk akan otomatis terhapus pada keranjang',
      },
      detail: {
        make_sure_you_received_your_order:
          'Pastikan anda telah menerima pesanan anda',
        already_received: 'Sudah Diterima',
        not_received_yet: 'Belum Diterima',
        cancellation_reason: 'Alasan Pembatalan',
        confirmation: 'Konfirmasi',
        bill_with_credit:
          'Tagihan dengan pembayaran tempo pada pesanan ini sebesar',
        pay_before_date: 'Silahkan melunasi sebelum tanggal',
        see_invoice: 'Lihat Invoice',
        see_detail: 'Lihat Detail',
        cancelled: 'Dibatalkan',
        max_file_size: 'Ukuran maksimal file 4MB dengan format jpg, png',
        order_received: 'Pesanan Diterima',
        cancel_order: 'Batalkan Pesanan',
        back: 'Kembali',
      },
      how_to_pay: {
        transaction_list: 'List Daftar Transaksi',
        status_delivery: 'Lihat Status Pesanan',
      },
      payment: {
        payment_method: 'Cara Pembayaran',
        end_of_payment: 'Pembayaran anda akan berakhir pada',
        va_no: 'Nomor Virtual Account',
        copy: 'salin',
        total_payment: 'Total Pembayaran',
        view_payment_detail: 'Lihat Rincian Pembayaran',
        payment_instruction: 'Instruksi Pembayaran',
      },
      waiting_payment: {
        title: 'Menunggu Pembayaran',
        dont_have_transaction: 'Anda Belum Memiliki Transaksi',
        shopping_now: 'Belanja Sekarang',
        detail_transaction: 'Detail Transaksi',
      },
    },
    profile: {
      retur: {
        title: 'Retur',
        deposit: {
          title: 'Deposit Retur',
          history: 'Riwayat',
          date_range: 'Jangka Waktu',
          history_empty_info:
            'Tidak ada data untuk ditampilkan, silahkan atur filter tanggal',
          deposit_return: 'Saldo Masuk',
          deposit_bill: 'Saldo Keluar',
          close: 'Tutup',
        },
      },
    },
    about: {
      title: 'Tentang Sarung Indonesia',
      paragraphs: {
        one: 'adalah web dan mobile commerce berbasis marketplace milik Indonesia, yang memberikan beragam kemudahan kepada pelanggan setia produk untuk bertransaksi kapanpun dan dimanapun.',
        two: 'Seiring pertumbuhan Indonesia dalam dunia e-commerce ritel global, hadir dengan pengalaman berbelanja yang aman, mudah, dan praktis. membantu Anda untuk dengan mudah memesan seluruh produk tanpa harus menunggu kunjungan Salesman, serta mendapatkan berbagai informasi seputar program promo yang sedang berjalan.',
        three: 'Nikmati keuntungan bertransaksi',
        four: 'Loyalty Point',
        five: 'Loyalty point adalah reward khusus yang bisa Anda dapatkan di setiap total transaksi Anda, yang perhitungannya bersifat transparan.',
        six: 'Redeem Point',
        seven:
          'Tukarkan poin Anda dan nikmati hadiah yang telah kami sediakan.',
        eight: 'Retun',
        nine: 'Apabila memiliki keluhan seputar produk yang dipesan, Anda bisa dengan mudah melakukan retur, yang prosesnya dapat dengan mudah Anda tracking melalui aplikasi ini (prosedur & kebijakan berlaku).',
        ten: 'Pengiriman',
        eleven:
          ' memberikan layanan premium dengan prioritas pengiriman cepat sampai, yaitu maks. H+3 setelah order di-submit. Anda juga dapat melakukan tracking order.',
        twelve:
          'Kepuasan Anda dalam berbelanja adalah prioritas layanan . Dengan mendaftar dan bertransaksi melalui aplikasi ini, Anda akan mendapatkan pelayanan seperti langsung dari Salesman, keuntungan/margin yang cukup tinggi, serta menjadi prioritas utama dalam proses pemesanan dan pengiriman.',
        thirteen:
          'Layanan tidak memungut biaya apapun dalam setiap proses transaksi, termasuk dalam program loyalty maupun program promo lainnya. Hati-hati terhadap segala bentuk penipuan yang mengatasnamakan .',
        fourteen:
          'Untuk informasi lebih lanjut mengenai penggunaan aplikasi ini, Anda dapat menghubungi Salesman dan Distributor Anda, atau silahkan menghubungi Customer Care di alamat email',
        fifteen:
          'Segera berbelanja, nikmati kemudahannya dan berlah untuk mendapatkan banyak keuntungan!',
      },
    },
    privacy_policy: {
      title: 'Kebijakan Privasi',
      title2: 'Akun',
      paragraphs: {
        one: 'PT Indonesia Tbk membangun dan menjaga kepercayaan Anda, data pribadi Anda adalah perhatian kami. Kami memastikan dan sangat berhati-hati terhadap setiap informasi tentang Anda yang diperoleh PT Indonesia Tbk, termasuk kebijakan mengenai informasi apa yang kami kumpulkan, bagaimana kami menggunakan dan melindungi informasi tersebut dan bagaimana Anda dapat menentukan apa yang dapat kami lakukan dengan informasi Anda. Kami ingin membantu Anda memahami cara kami menggunakan informasi Anda untuk meningkatkan konten, produk dan layanan kami. Oleh karena itu, kami telah menyusun Kebijakan Privasi Pelanggan ini untuk menangani kepedulian tersebut.',
        two: 'Dengan menggunakan situs atau memberikan informasi Anda kepada Group (perusahaan), Anda menyetujui pengumpulan, penggunaan dan pembagian informasi Anda berdasarkan kebijakan ini.',
        three:
          'Silakan luangkan waktu sejenak untuk membaca Kebijakan Privasi ini dengan cermat dan hubungi kami jika Anda mempunyai pertanyaan. Saat Anda membaca Kebijakan Privasi kami, harap diingat bahwa ini berlaku untuk seluruh perusahaan di bawah naungan Group. Kami dapat sewaktu-waktu memperbarui kebijakan ini, jadi selalu periksalah terlebih dahulu kebijakan privasi kami.',
        four: 'Informasi pribadi adalah informasi, atau gabungan beberapa informasi, yang dapat digunakan untuk mengidentifikasi Anda secara individu.',
        five: 'Informasi Pribadi yang Dikumpulkan',
        six: 'Informasi yang Anda berikan kepada kami',
        seven:
          'Informasi yang kami terima ketika Anda menghubungi kami, mengunjungi situs kami, produk atau melihat iklan kami.',
        eight:
          'Informasi yang kami terima dari perusahaan lain yang telah mendapatkan persetujuan Anda.',
        nine: 'Informasi yang kami terima dari perusahaan lain ketika Anda mengunjungi situs web mereka.',
        ten: 'Kami dapat setiap saat mengumpulkan dan mengelola informasi apapun yang anda berikan termasuk data pribadi anda. Kami dapat mengombinasikan sebagian atau seluruh informasi tersebut untuk menciptakan produk dan layanan yang lebih baik, meningkatkan keakuratan informasi Anda secara keseluruhan agar interaksi kami dengan Anda lebih baik dan mengelola penggunaan Anda terhadap situs web kami melalui penggunaan teknologi. Kami juga dapat mengumpulkan informasi-informasi terkait lain seperti halaman mana yang diakses, jumlah orang yang mengakses situs kami, jenis web browser yang digunakan, nama penyedia jasa layanan internet Anda, dan sebagainya. Dengan menyimpan informasi ini, kami dapat mempelajari bagaimana menyesuaikan situs kami untuk pada pengunjung situs kami.',
        eleven:
          'Anda diwajibkan untuk memastikan keaslian dan keakuratan informasi yang dicantumkan sebagai syarat untuk mengakses halaman situs kami. Untuk itu anda setuju untuk tidak memberikan informasi palsu dan bahwa kami berhak untuk melakukan verifikasi lebih lanjut terkait informasi yang anda berikan.',
        twelve: 'Penggunaan / Pembagian Informasi Pribadi',
        thirteen:
          'Kami tidak menjual atau mengungkapkan informasi pribadi Anda kepada para pemasar di luar Group. Kami membagikan informasi yang diberikan oleh Anda pada situs web dengan para perusahaan terpilih yang membantu kami dalam menjalankan bisnis, termasuk yang menyediakan layanan hosting situs web kami. Mereka bertanggung jawab untuk melindungi informasi Anda dengan cara yang sama seperti yang kami lakukan dan tidak akan membagikan atau menggunakannya untuk tujuan lain dengan alasan apa pun. Kami tetap dapat menyimpan, menggunakan, dan mengelola data pribadi anda meskipun izin penggunaan situs anda sudah ditarik atau diakhiri.',
        fourteen:
          'Para perusahaan terpilih ini dibatasi oleh kontrak untuk menggunakan atau mengungkapkan informasi kecuali diperlukan untuk melakukan layanan atas nama kami atau untuk mematuhi persyaratan hukum. Selain itu dalam pengungkapan hukum, kami dapat memberikan informasi tentang Anda dalam hal, namun tidak terbatas pada;',
        fifteen: '(i)     Untuk mematuhi kewajiban hukum;',
        sixteen:
          '(ii)    Apabila kami diwajibkan secara hukum atau oleh proses hukum;',
        seventeen:
          '(iii)   Atas permintaan penegak hukum atau pemerintan berwenang yang melakukan penyelidikan atas dugaan atau kegiatan ilegal yang terjadi;',
        eighteen:
          '(iv)   Untuk identifikasi, verifikasi, uji tunas, atau ketahui tujuan pelanggan anda;',
        nineteen:
          '(v)    Untuk mengevaluasi dan membuat keputusan terkait kredit anda;',
        twenty: '(vi)   Untuk menanggapi keadaan darurat;',
        twenty_one:
          '(vii) Untuk melindungi hak, properti, keselamatan, atau keamanan pihak ketiga, pengunjung situs web, atau publik luas.',
        twenty_two: 'Keamanan Anak-Anak',
        twenty_three:
          'Situs web ini tidak ditujukan bagi individual yang berusia di bawah 18 tahun (atau usia legal yang berlaku untuk mengonsumsi/menggunakan produk yang ditanyakan). Jika Anda berusia di bawah 18 tahun, kami menghimbau Anda melibatkan orang tua atau wali Anda untuk mengirimkan data pribadi Anda kepada kami. Dengan memberikan data pribadi Anda kepada kami, Anda telah menegaskan bahwa Anda setidaknya berusia 18 tahun ke atas dan menyetujui Kebijakan Privasi yang telah ditetapkan.',
        twenty_four: 'Perubahan atas Kebijakan Privasi kami',
        twenty_five:
          'Kami dapat sewaktu-waktu memperbarui kebijakan ini, jadi selalu periksalah terlebih dahulu kebijakan privasi kami karena sifatnya sangat mengikat.',
        twenty_six: 'Keamanan Informasi',
        twenty_seven:
          'Kami menerapkan langkah-langkah untuk menjamin keamanan informasi yang kami kumpulkan dan kelola, dan data pribadi anda hanya dapat diakses sebagian kecil karyawan yang memerlukan. Data anda akan disimpan sesuai dengan peraturan perlindungan data yang berlaku, dan kami akan menganonimkan informasi anda apabila dianggap tidak diperlukan untuk tujuan penggunaan data tersebut.  Namun kami tidak memberikan jaminan keamanan yang bersifat mutlak atau absolut.',
        twenty_eight: 'Pihak Ketiga Yang Memberikan Layanan Dukungan',
        twenty_nine:
          'Selama berlangsungnya kegiatan usaha, kami mungkin melibatkan perusahaan dan perseorangan lain untuk melakukan beragam tugas atas nama kami. Misalnya, kami melibatkan agen perwakilan untuk membantu mengelola promosi pelanggan, menganalisis data dan memberikan layanan pelanggan. Wakil-wakil ini memiliki akses ke informasi untuk akses pribadi jika diperlukan untuk melakukan tugas mereka untuk Group; namun, mereka dilarang untuk menggunakan informasi untuk akses pribadi tersebut untuk tujuan lain dan mereka diminta untuk menjaga kerahasiaan informasi tersebut.',
        thirty: 'Hubungan (Link) dengan Situs Lain',
        thirty_one:
          'Group berupaya semaksimal mungkin untuk memastikan agar setiap penghubungan di website kami akan mengirim Anda ke tempat yang berguna di Internet. Namun, website dan alamat berubah begitu cepat, dan Group tidak dapat menjamin muatan dari setiap situs yang penghubungannya disediakan oleh kami. Selain itu, website lain memiliki kebijakan privasi sendiri. Anda harus selalu melihat kebijakan privasi dari situs tertentu yang saudara kunjungi untuk mengetahui hak Anda.',
        thirty_two: 'Daftar Isian dan Survei',
        thirty_three:
          'Pada setiap saat kami dapat meminta para pengunjung untuk melengkapi survei dan jajak pendapat tentang sikap dan keinginan mereka. Survei membantu kami untuk memahami keperluan Anda. Jika jawaban contoh untuk survei dimasukkan ke dalam situs website tersebut, maka tidak ada informasi untuk akses pribadi yang akan dimasukkan. Jika Anda turut serta dalam salah satu dari survei kami, kami dapat memastikan Anda bahwa kami tidak menyimpan setiap informasi untuk akses pribadi tentang Anda dari survei tersebut di database kami.',
        thirty_four: 'Kontak Kami',
        thirty_five:
          'Silakan langsung hubungi kami apabila Anda memiliki pertanyaan atau persoalan mengenai keamanan Anda',
      },
    },
    terms_and_conditions: {
      title: 'Syarat & Ketentuan',
      paragraphs: {
        one: 'Syarat Pemakaian',
        two: 'Situs ini disediakan dengan ketentuan Anda menyetujui seluruh Syarat dan Ketentuan ini beserta dengan seluruh perubahan-perubahan dan/atau tambahan-tambahan yang mungkin dibuat di kemudian hari. Dengan menggunakan dan mengakses situs ini, maka Anda dianggap telah membaca, mengerti, dan menyetujui seluruh Syarat dan Ketentuan ini. Kami dapat sewaktu-waktu mengubah dan/atau menambahkan Syarat dan Ketentuan ini tanpa kewajiban untuk memberitahukan Anda mengenai perubahan yang kami lakukan. Oleh karena itu, Anda diharapkan selalu membaca Syarat dan Ketentuan ini setiap Anda mengakses situs ini.',
        three: 'Hak Cipta dan Kekayaan Intelektual',
        four: 'Hak cipta © PT Indonesia Tbk. Seluruh hak cipta dan/atau kekayaan intelektual yang ada di dalam situs ini, termasuk namun tidak terbatas dalam bentuk kalimat, gambar, suara, logo, design, grafik, ilustrasi, video, simbol, perangkat lunak dan material lain yang terdapat pada seluruh halaman layar situs web ini adalah milik PT Indonesia Tbk dan atau perusahaan afiliasinya atau termasuk yang dilisensikan dari pemilik yang bersangkutan. Seluruh hak cipta dan kekayaan intelektual yang terdapat di dalam situs ini dilindungi berdasarkan peraturan perundang-undangan yang berlaku.',
        five: 'Batasan Penggunaan Situs Web dan Materi',
        six: 'Anda diperbolehkan mengakses, mencetak, mengunduh, menyalin konten, dan atau mendistribusikannya ke orang lain hanya untuk kepentingan non komersial, terbatas pada pemberian informasi dan hanya keperluan pribadi saja. Penggunaan lainnya dari materi di situs web ini, termasuk namun tidak terbatas pada kegiatan modifikasi, reproduksi, distribusi, publikasi ulang, mempertunjukkan, atau transmisi isi situs ini, tanpa izin tertulis dari PT Indonesia Tbk secara tegas dilarang.',
        seven:
          'Kami tidak bertanggung jawab atas setiap kerugian, kerusakan atau biaya yang timbul dari akses atau penggunaan situs web ini ataupun tautan yang terdapat di dalamnya, tidak terbatas pada setiap hilangnya keuntungan, secara tidak langsung, kerugian insidental maupun konsekuensial.',
        eight: 'Konten dan Tautan',
        nine: 'Informasi yang tersedia di dalam situs web ini hanya bertujuan sebagai informasi umum saja. Kami tidak menghimbau agar dijadikan referensi untuk tujuan tertentu dan tidak ada pernyataan atau jaminan yang diberikan dalam hal keakuratan atau kelengkapan konten. Anda menyetujui dengan mengakses dan menggunakan situs web dan segala kontennya adalah risiko Anda sendiri.',
        ten: 'Situs web ini mungkin memuat tautan ke situs lain yang disediakan oleh pihak ketiga (bukan afiliasi dari PT Indonesia, Tbk). Meskipun PT Indonesia, Tbk merujuk Anda untuk mengunjungi situs pihak ketiga, PT Indonesia, Tbk tidak memiliki tanggung jawab ataupun hubungan dengan informasi atau kebijakan yang terdapat di dalam situs pihak ketiga tersebut maupun pemiliknya. Situs-situs tautan ini hanya untuk memudahkan Anda dan karena itu Anda akan dapat mengaksesnya di bawah risiko sendiri.',
        eleven: 'Data Personal',
        twelve:
          'Kami mungkin mengumpulkan informasi data pribadi Anda, apabila Anda: (I) menaruh alamat e-mail Anda pada kolom yang tersedia; dan atau (II) menghubungi kami melalui layanan Call Center.',
        thirteen:
          'Untuk informasi lebih lanjut mengenai pengumpulan dan pengelolaan data pribadi Anda, Anda dapat membacanya di halaman “Kebijakan Privasi”.',
        fourteen: 'Pengiriman Barang',
        fifteen:
          'Untuk pembelian online melalui , pesanan dan pengiriman akan diproses setelah dilakukan proses pembayaran oleh Pengguna, baik melalui Bank Transfer atau Virtual Account. Lama waktu pengiriman disesuaikan dengan area layanan daerah tujuan.',
        sixteen:
          'Pengguna akan menerima notifikasi dalam aplikasi mengenai status pengiriman sesaat setelah produk pesanan Pengguna dikirim.',
        seventeen: 'Tarif pengiriman sepenuhnya ditanggung oleh Distributor.',
        eighteen:
          'Jika barang yang Anda pesan mengalami kerusakan atau kekeliruan pada saat penerimaan barang, maka silahkan melakukan langkah selanjutnya pada bagian “Retur dan Pengembalian Dana”.',
        nineteen: 'Retur dan Pengembalian Dana',
        twenty:
          'Pengguna dapat melakukan retur, dengan ketentuan sebagai berikut:',
        twenty_one: 'Produk mendekati expired',
        twenty_two:
          'Produk yang diterima tidak sesuai dengan pemesanan yang dilakukan oleh Pengguna;',
        twenty_three:
          'Adanya kerusakan pada produk yang diterima oleh Pengguna, termasuk namun tidak terbatas kepada:',
        twenty_four:
          'Jumlah produk yang diterima oleh Pengguna kurang dari jumlah produk yang dipesan melalui aplikasi;',
        twenty_five: 'Produk pecah atau rusak saat diterima;',
        twenty_six:
          'Isi volume produk tidak sesuai dengan pesanan yang dilakukan oleh Pengguna;',
        twenty_seven:
          'Pengguna dapat melakukan proses retur dengan cara mengirimkan keluhan melalui alamat. Adapun informasi yang harus diberikan oleh Pengguna terkait keluhan adalah Pengguna harus (i) melampirkan foto produk; (ii) melampirkan foto masa kadaluarsa, (iii) nomor pesanan dan nama akun , termasuk kepada informasi alamat untuk mengirimkan barang yang baru; (iv) dan hal terkait lainnya.',
        twenty_eight:
          'Distributor akan melakukan verifikasi pengajuan retur, dan apabila disetujui maka Distributor akan melakukan pengambilan barang bersamaan dengan jadwal pengiriman Distributor.',
        twenty_nine:
          'Pengembalian dana dapat dilakukan dengan estimasi 5-7 hari kerja.',
        thirty:
          'Kami berhak untuk tidak melanjutkan proses pengembalian barang jika Pengguna tidak memenuhi Syarat dan Ketentuan ini.',
        thirty_one: 'Hukum yang berlaku',
        thirty_two:
          'Seluruh Syarat & Ketentuan ini dibuat dan karenanya tunduk pada hukum yang berlaku di wilayah Negara Republik Indonesia.',
        thirty_three: 'Pertanyaan dan Umpan Balik',
        thirty_four:
          'Kami menerima seluruh pendapat, pertanyaan, komentar, kritik, maupun saran dari Anda sehubungan dengan informasi di dalam situs ini. Silakan mengirimkan kepada kami umpan balik dan/atau pertanyaan Anda kepada kami.',
        thirty_five: 'Referensi',
        thirty_six:
          'Referensi pada afiliasi atau perusahaan afiliasi atau terafiliasi adalah termasuk seluruh perusahaan dalam naungan Sarung Indonesia.',
        thirty_seven: '',
        thirty_eight: 'PT Indonesia, Tbk',
      },
    },
    point: {
      cart: {
        title: 'KERANJANG',
      },
      purchase: {
        title: 'PEMBELIAN',
      },
      purchase_transaction: {
        title: 'TRANSAKSI PEMBELIAN',
      },
    },
    order: {
      detail: {
        change_status: 'Ubah Status',
      },
      send: 'Kirim',
      print_label: 'Cetak Label',
    },
    retur: {
      title: 'Return',
      list: {
        return_balance: 'Saldo Retur',
        close: 'Tutup',
        return_request: 'Buat Permohonan Retur',
        return_empty: 'Anda belum memiliki daftar pengajuan permohonan retur.',
        return_empty_with_filter: 'Tidak ada transaksi yang sesuai filter',
        estimated_refund: 'Estimasi Pengembalian Dana',
        order_receive: 'Pesanan Diterima',
        waiting_verification: 'Menunggu verifikasi',
        return_request_detail: 'Detail Permohonan Retur',
        detail_request_retur: 'Detail Return Request',
        status_retur: 'Status Retur',
        number_retur: 'Nomor Retur',
        request_date: 'Tanggal Pengajuan',
        delete_success_info: 'Draft permohonan retur berhasil dihapus.',
      },
    },
  },
  common: {
    copy: 'Salin',
    day: 'Hari',
    month: 'Bulan',
    year: 'Tahun',
    'Month(s)': 'Bulan',
    'Year(s)': 'Tahun',
    'Day(s)': 'Hari',
  },
  components: {
    countdown: {
      time_remaining: 'Waktu yang tersisa',
      hour: 'Jam',
      minutes: 'Menit',
      seconds: 'Detik',
    },
  },
  error: {
    DOR: {
      CCT: {
        OR_ITEM_IS_NOT_FOUND_ON_CART:
          'Tidak ada item yang ditemukan di keranjang',
        OR_PO_NUMBER_ALREADY_EXISTS: 'Nomor PO sudah ada',
      },
      ASO: {
        OR_CANNOT_REJECT_CANCELLATION_REQUEST:
          'Tidak dapat menolak permintaan pembatalan',
        OR_SUCCESSFULLY_REJECT_CANCEL_REQUESTED:
          'Permintaan pembatalan berhasil ditolak',
        OR_ORDER_CANNOT_BE_CANCELED: 'Order tidak dapat dibatalkan',
        OR_SUCCESSFULLY_CANCEL_ORDER: 'Order berhasil dibatalkan',
        OR_PLEASE_FILL_ORDER_ID_OR_ORDER_NUMBER:
          'Silahkan isi ID order atau nomor order',
        OR_UNREGISTERED_STATE: 'State tidak terdaftar',
        OR_SUCCESSFULLY_UPDATED_STATE: 'State berhasil diubah',
        OR_MISSING_REQUIRED_PARAMETER: 'Parameter yang diperlukan tidak ada',
        OR_INVALID_ORDER_STATE: 'State order tidak valid',
        OR_ORDER_ID_NOT_FOUND: 'ID order tidak ditemukan',
        OR_ALREADY_CANCELLED: 'Order sudah dibatalkan',
        OR_INVALID_CHANNEL_ID: 'ID channel tidak valid',
      },
    },
    DPC: {},
    DPY: {},
    DLY: {},
    DIV: {
      BIV: {
        IV_ITEM_IS_OUT_OF_STOCK:
          'Stok sudah habis, silahkan hapus produk pada halaman keranjang',
      },
    },
  },
}

export default {
  locale: 'id-ID',
  localeAntd,
  messages,
}
