import moment from 'moment'
import { apiClientFastify } from '@/services/axios'
import * as MutationTypes from './mutation-types'

const actions = {
  async getDashboardData({ commit, rootState }, payload) {
    try {
      commit(MutationTypes.SET_LOADING, true)
      const { data: commission } = await apiClientFastify.get(
        '/data/private/dashboard/commissions',
      )
      const { data: lastSoldItems } = await apiClientFastify.get(
        '/data/private/dashboard/latest_sold_items',
      )
      const { data: orderSummary } = await apiClientFastify.get(
        '/data/private/dashboard/order_summary',
      )
      const { data: graphSummary } = await apiClientFastify.get(
        '/data/private/dashboard/graph_summary',
      )
      const { data: bestSeller } = await apiClientFastify.get(
        '/data/private/dashboard/best_seller_items',
      )
      // Get 7 days ago from today
      const timeFrom = (X) => {
        const dates = []
        for (let I = 0; I < Math.abs(X); I++) {
          const date = new Date(
            new Date().getTime() -
              (X >= 0 ? I : I - I - I) * 24 * 60 * 60 * 1000,
          )
          dates.push(moment(date).format('dddd'))
        }
        return dates
      }
      const days = timeFrom(7)
      // data for statistik
      const dataCart = {
        labels: [],
        series: [
          {
            data: [],
            className: 'ct-series-a',
          },
        ],
      }

      /**
       * Loop terbalik dari index terakhir. agar posisi sebelah kanan data hari yang terbaru
       * Loop juga digunakan untuk cek apakah di hari tersebut ada data penjualan, dimana didapatkan dari endpint graphSummary
       */
      // console.log(rootState.settings.locale)
      const changeDay = (day) => {
        switch (day) {
          case 'Senin':
            return 'Monday'
          case 'Selasa':
            return 'Tuesday'
          case 'Rabu':
            return 'Wednesday'
          case 'Kamis':
            return 'Thursday'
          case 'Jumat':
            return 'Friday'
          case 'Sabtu':
            return 'Saturday'
          case 'Minggu':
            return 'Sunday'
          default:
            break
        }
      }

      for (let i = days.length - 1; i >= 0; i--) {
        dataCart.labels.push(days[i])
        const findDataSales = graphSummary.data.find((value) =>
          rootState.settings.locale === 'id-ID'
            ? value.sales_day === changeDay(days[i])
            : value.sales_day === days[i],
        )
        if (findDataSales) {
          dataCart.series[0].data.push(findDataSales.total_order)
        } else {
          dataCart.series[0].data.push(0)
        }
      }

      // Cookies.set('ewallet', commission.data.ewallet)
      commit(MutationTypes.SET_EWALLET, commission.data.ewallet)
      commit(MutationTypes.SET_BEST_SELLER_ITEMS, bestSeller.data)
      commit(MutationTypes.SET_COMMISSION, commission.data)
      commit(MutationTypes.SET_LAST_SOLD_ITEMS, lastSoldItems.data)
      commit(MutationTypes.SET_ORDER_SUMMARY, orderSummary.data)
      commit(MutationTypes.SET_GRAPH_SUMMARY, dataCart)
    } catch (err) {
      console.log(err)
    } finally {
      commit(MutationTypes.SET_LOADING, false)
    }
  },
  async getCommissionData({ commit, rootState }) {
    try {
      commit(MutationTypes.SET_LOADING, true)
      const { data: commission } = await apiClientFastify.get(
        '/data/private/dashboard/commissions',
      )
      commit(MutationTypes.SET_COMMISSION, commission.data)
      commit(MutationTypes.SET_EWALLET, commission.data.ewallet)
    } catch (err) {
      console.log(err)
    } finally {
      commit(MutationTypes.SET_LOADING, false)
    }
  },
  async getMediaKit({ commit }) {
    try {
      commit(MutationTypes.SET_LOADING, true)
      const { data: response } = await apiClientFastify.get(
        '/data/private/mediakit',
      )
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit(MutationTypes.SET_LOADING, false)
    }
  },
}

export default actions
